import {
  BlockchainType,
  ScopeNotification,
  Staking as StakingOptions,
} from "models/Enums";
import { LiquidStakingFlags, UserNotification } from "models/Interfaces";
import { isValidRadixWallet, isValidTerraWallet } from "models/utils/terra";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { countUsersWallets, showShortURL } from "../utils";

import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { useContextUser } from "contexts/user";
import { ConfigurationParams } from "models/Notification";
import { hasChainWallets } from "models/utils";
import { useTranslation } from "react-i18next";

const Staking = ({
  setValues,
  disabledBtn,
  notification,
}: {
  setValues: Dispatch<SetStateAction<any>>;
  disabledBtn?: Dispatch<SetStateAction<any>>;
  notification?: UserNotification;
}) => {
  const startFlags: LiquidStakingFlags = {
    flag: false,
    value: 99999999,
    type: "",
  };
  const { t } = useTranslation(["common", "enumerations"]);
  const { userData } = useContextUser();
  const { priceTypes } = useContextBlockchainData();
  const { selectedNotificationBlockchain } = useNewNotificationFlow();
  const [scope, setScope] = useState<string>();
  const [walletAddressInput, setWalletAddressInput] = useState<string>(
    notification!?.walletAddress || ""
  );
  const [configs, setConfigs] = useState<ConfigurationParams>();
  const [error, setError] = useState<boolean>(false);

  const resetFields = (): void => {
    setError(false);
    setWalletAddressInput("");
    disabledBtn && disabledBtn(true);

    let loadData: ConfigurationParams = {
      contract: t(`blockchains_types.${selectedNotificationBlockchain}`, {
        ns: "enumerations",
      }).toLowerCase(),
      delegate: { ...startFlags, value: 0, type: ">=" },
      undelegate: { ...startFlags, value: 0, type: ">=" },
      redelegate: { ...startFlags, value: 0, type: ">=" },
      withdrawRewards: { ...startFlags, value: 0, type: ">=" },
      to: "",
      from: "",
    };

    if (notification) {
      loadData = {
        contract: notification?.contract,
        delegate: { ...notification?.delegate },
        undelegate: { ...notification?.undelegate },
        redelegate: { ...notification?.redelegate },
        withdrawRewards: { ...notification?.withdrawRewards },
        walletAddress: notification?.walletAddress,
        to: notification?.to,
        from: notification?.from,
      };
      setScope(notification.scope);
    }
    setConfigs({ ...loadData });
  };

  /**
   * Verify is the address given is a valid wallet address
   * @param text wallet address
   * @returns boolean true or false
   */
  const checkWallet = (text: string) => {
    if (
      text !== "" &&
      ((selectedNotificationBlockchain === BlockchainType.Radix &&
        !isValidRadixWallet(text)) ||
        (selectedNotificationBlockchain !== BlockchainType.Radix &&
          !isValidTerraWallet(text)))
    ) {
      disabledBtn && disabledBtn(true);
      return false;
    } else {
      setWalletAddressInput(text);
      setError(false);
      return true;
    }
  };

  const hasOptionSelected = () => {
    return (
      configs?.delegate?.flag ||
      configs?.undelegate?.flag ||
      configs?.withdrawRewards?.flag ||
      configs?.redelegate?.flag
    );
  };

  const checkData = (configs: ConfigurationParams) => {
    if (
      (walletAddressInput !== "" && !checkWallet(walletAddressInput)) ||
      !hasOptionSelected()
    ) {
      disabledBtn && disabledBtn(true);
    } else {
      if (hasOptionSelected()) {
        setValues({
          ...configs,
          scope: scope,
          walletAddress:
            (notification !== undefined && configs.walletAddress) ||
            walletAddressInput,
        });
        disabledBtn && disabledBtn(false);
      }
    }
  };
  /**
   * Handle scope selected by user
   *
   * @param e
   */
  const handleScope = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    resetFields();
    setScope(e.target.value);
    if (e?.target?.value === ScopeNotification.myWallet) {
      countUsersWallets(userData) === 1 &&
        setWalletAddressInput(userData?.wallets[0]?.walletAddress || "");
    }
  };

  /**
   * Handle wallet by user
   * @param event onChange event
   */
  const handleWalletChanged = (event: React.ChangeEvent<any>) => {
    setWalletAddressInput(event?.target.value);
    event?.target.value !== ""
      ? !checkWallet(event?.target.value) && setError(true)
      : setError(false);
  };

  /**
   * Handle Alert Configurations checked
   * @param event onChange event
   */
  const handleLiquidStakingOptions = (event: React.ChangeEvent<any>) => {
    const { name, checked } = {
      name: event?.target.name,
      checked: event?.target.checked,
    };

    const currentConfigs =
      (name === StakingOptions.delegate && configs?.delegate) ||
      (name === StakingOptions.undelegate && configs?.undelegate) ||
      (name === StakingOptions.redelegate && configs?.redelegate) ||
      configs?.withdrawRewards;

    setConfigs({
      ...configs,
      [name]: {
        ...currentConfigs,
        flag: checked,
      },
    });
  };

  /**
   * Handle Alert Type and price Changed
   * @param e onChange event
   */
  const handleTypeAndPriceStaking = (
    e: React.ChangeEvent<HTMLSelectElement | any>
  ) => {
    const { name, value } = { name: e?.target.name, value: e?.target.value };

    const currentConfigs =
      (name === StakingOptions.delegate && configs?.delegate) ||
      (name === StakingOptions.redelegate && configs?.redelegate) ||
      (name === StakingOptions.undelegate && configs?.undelegate) ||
      configs?.withdrawRewards;

    const result =
      e.target.localName === "input" ? { value: value } : { type: value };

    setConfigs({
      ...configs,
      [name]: {
        ...currentConfigs,
        ...result,
      },
    });
  };

  /**
   * Search the value in object by key and return the result
   * @param object
   * @param key
   * @returns
   */
  const returnObjectByKey = (object: ConfigurationParams, key: string) => {
    return Object.entries(object).filter(
      ([prop, value]) => prop === key && value
    )[0];
  };

  const filterOptions = (optStaking: string) => {
    if (
      selectedNotificationBlockchain === BlockchainType.Radix ||
      notification?.chainId === BlockchainType.Radix
    ) {
      return optStaking !== "redelegate" && optStaking !== "withdrawRewards";
    } else {
      return true;
    }
  };

  useEffect(() => {
    resetFields();
  }, []);

  useEffect(() => {
    checkData({ ...configs });
  }, [configs]);

  useEffect(() => {
    checkWallet(walletAddressInput) && checkData({ ...configs });
  }, [walletAddressInput]);

  return (
    <>
      <div className="w-full">
        <div
          className={` pb-4 border-b border-black-line w-full ${
            notification?.notificationId !== undefined && "hidden"
          }`}
        >
          <h3>{t("notifications.staking", { ns: "common" })}</h3>
        </div>

        <select
          multiple={false}
          value={scope}
          onChange={handleScope}
          disabled={notification?.notificationId !== undefined}
          className=" focus:ring-0 focus:outline-none  bg-white mt-4"
        >
          <option value="">
            {t(`notifications.select_scope`, { ns: "common" })}
          </option>
          {Object.values(ScopeNotification)
            .filter(
              (value) =>
                value === ScopeNotification.myWallet ||
                value === ScopeNotification.otherWallet
            )
            .map((scope: string) => {
              return (
                <option
                  className="bg-white text-gray-darksm"
                  value={scope}
                  key={Math.random() * 0.5}
                >
                  {t(`scope_notifications.liquid_staking.${scope}`, {
                    ns: "enumerations",
                  })}
                </option>
              );
            })}
        </select>
      </div>
      {scope === ScopeNotification.myWallet && (
        <div className="mt-6">
          <>
            <p className="">
              {t("notifications.choose_wallet", {
                ns: "common",
              })}
            </p>
            <select
              multiple={false}
              disabled={
                countUsersWallets(userData) === 1 ||
                notification?.notificationId !== undefined
              }
              value={
                notification?.notificationId !== undefined
                  ? notification.walletAddress
                  : walletAddressInput
              }
              className={`focus:border-0 myWallet focus:ring-0 focus:outline-none ${
                countUsersWallets(userData) === 1 && " bg-gray-extralight20"
              }`}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                checkWallet(e.target.value);
              }}
            >
              <option value="">
                {t(`notifications.select_wallet`, { ns: "common" })}
              </option>
              {userData &&
                selectedNotificationBlockchain &&
                hasChainWallets(userData, selectedNotificationBlockchain)!
                  .length === 0 && (
                  <option value="" disabled={true}>
                    {t(`notifications.no_wallet`, { ns: "common" })}
                  </option>
                )}
              {userData?.wallets
                .filter((w) =>
                  selectedNotificationBlockchain === BlockchainType.Terrav2 ||
                  selectedNotificationBlockchain === BlockchainType.TerraClassic
                    ? w.chainId === BlockchainType.Terrav2 ||
                      w.chainId === BlockchainType.TerraClassic
                    : w.chainId === selectedNotificationBlockchain
                )
                .map((w) => (
                  <option
                    value={w.walletAddress}
                    disabled={notification?.notificationId !== undefined}
                  >
                    {w.walletAlias
                      ? `${w.walletAlias} (${showShortURL(w.walletAddress)})`
                      : w.walletAddress}
                  </option>
                ))}
            </select>
            <div
              className={`my-8 ${
                notification?.notificationId !== undefined && "hidden"
              }`}
            ></div>
          </>
        </div>
      )}

      {((notification?.notificationId === undefined &&
        notification?.scope === ScopeNotification.otherWallet) ||
        scope === ScopeNotification.otherWallet) && (
        <div className="mt-6">
          <p className="">
            {t(`notifications.walletAddress`, { ns: "common" })}
          </p>
          <input
            value={
              notification?.notificationId !== undefined
                ? notification.walletAddress
                : walletAddressInput
            }
            onBlur={() => checkWallet(walletAddressInput)}
            name="wallet"
            onChange={handleWalletChanged}
            type="text"
            placeholder={t(`notifications.address`, { ns: "common" })}
            className={`focus:ring-0  focus:border-gray-extralight90medium focus:outline-none rounded w-full text-base h-12 p-3 border-gray-extralight90medium text-black-transparent50`}
          />
          {notification?.notificationId === undefined && error && (
            <small className="px-1 text-red">
              {t("notifications.invalid_wallet", { ns: "common" })}
            </small>
          )}
        </div>
      )}
      <div className="mt-6">
        <p className="text-gray-dark font-semibold">
          {t("notifications.domain_options")}
        </p>

        <div className="flex items-start px-4">
          <div className="sm:w-5/12 ">
            {Object.keys(StakingOptions)
              .filter((optStaking: string) => filterOptions(optStaking))
              .map((optStaking: string) => (
                <label
                  htmlFor={optStaking}
                  className="cursor-pointer flex items-center my-7"
                >
                  <input
                    className={`mx-2 focus:outline-none focus:ring-0 text-gold border-gold checked:border-0 focus:ring-transparent cursor-pointer transition-colors ${
                      !scope && "bg-gray-extralight20"
                    } `}
                    type="checkbox"
                    name={optStaking}
                    id={optStaking}
                    disabled={
                      !scope ||
                      (walletAddressInput === "" && notification === undefined)
                    }
                    checked={
                      notification !== undefined && configs
                        ? returnObjectByKey(configs, optStaking)?.[1]?.flag
                        : undefined
                    }
                    onChange={handleLiquidStakingOptions}
                  />

                  <span className="text-sm">
                    {t(`staking_options.${optStaking}`, {
                      ns: "enumerations",
                    })}
                  </span>
                </label>
              ))}
          </div>
          <div className="sm:w-5/12 mx-1 ">
            {Object.values(StakingOptions)
              .filter((optStaking: string) => filterOptions(optStaking))
              .map((typePrice: string, index: number) => (
                <select
                  multiple={false}
                  key={index}
                  className={`focus:ring-0 selectTypes py-1 focus:outline-none customScrollbar my-3 ${
                    !scope && "bg-gray-extralight20"
                  } `}
                  value={
                    notification !== undefined && configs
                      ? returnObjectByKey(configs, typePrice)?.[1]?.type
                      : undefined
                  }
                  name={typePrice}
                  onChange={(e) => handleTypeAndPriceStaking(e)}
                  disabled={
                    !scope ||
                    (configs && !returnObjectByKey(configs, typePrice)[1]?.flag)
                  }
                >
                  {priceTypes?.map((tp, idx: number) => {
                    return (
                      <option value={tp.type} key={index + idx + 1}>
                        {t(`alert_configuration_settings.${tp.type}`, {
                          ns: "common",
                        })}
                      </option>
                    );
                  })}
                </select>
              ))}
          </div>
          <div className="sm:w-2/12 sm:mt-0 mx-1 ">
            <div className="justify-end block items-center">
              {Object.values(StakingOptions)
                .filter((optStaking: string) => filterOptions(optStaking))
                .map((price: string, index: number) => (
                  <input
                    key={index}
                    className={` my-3 focus:outline-none text-center height42 inline-block w-14 sm:w-20 focus:ring-0 checked:border-0 focus:ring-transparent cursor-pointer border-gray-semidark transition-colors ${
                      (!scope || walletAddressInput === "") && "bg-gray-600"
                    }`}
                    type="number"
                    name={price}
                    placeholder={" 0 "}
                    id={price}
                    min={0}
                    disabled={
                      !scope ||
                      !(configs && returnObjectByKey(configs, price)[1]?.flag)
                    }
                    value={
                      configs && returnObjectByKey(configs, price)[1]?.value
                    }
                    onChange={handleTypeAndPriceStaking}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Staking;
