import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useWallet } from "@terra-money/use-wallet";
import { UserData } from "models/Interfaces";
import { api } from "shared";

/* Interface creation */
export interface UserDataContext {
  userData: UserData | null;
  isLoggedIn: boolean | null;
  setIsLoggedIn: Dispatch<SetStateAction<boolean | null>>;
  setUser(user?: UserData | null, isLoggedInStatus?: boolean): void;
  getUser(): void;
  logout(): void;
}

/* Context */
const UserContext = createContext<UserDataContext>({
  userData: null,
  isLoggedIn: null,
  setUser: () => {
    throw "Requesting component not nested inside UserContextProvider";
  },
  getUser: () => {
    throw "Requesting component not nested inside UserContextProvider";
  },
  setIsLoggedIn: () => {
    throw "Requesting component not nested inside UserContextProvider";
  },
  logout: () => {
    throw "Requesting component not nested inside UserContextProvider";
  },
});

/* Using Context*/
export const useContextUser = () => useContext(UserContext);

/* Provider fn */
export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const { status } = useWallet();

  const setUser = (user: UserData | null, isLoggedInStatus?: boolean) => {
    setUserData(user);
    setIsLoggedIn(isLoggedInStatus || isLoggedIn);
  };

  const getUser = async () => {
    if (userData) return;

    api.auth
      .isLoggedIn()
      .then((response) => {
        if (response?.error) {
          setIsLoggedIn(false);
          return;
        }

        setUserData(response);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        return;
      });
  };

  const logout = async () => {
    const response = await api.auth.logout();
    if (response) {
      setIsLoggedIn(false);
      setUserData(null);
    } else {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const dataUser = {
    userData: userData,
    isLoggedIn: isLoggedIn,
    setIsLoggedIn,
    setUser,
    getUser,
    logout,
  };

  return (
    <UserContext.Provider value={dataUser}>{children}</UserContext.Provider>
  );
};
