import React, { useEffect, useState } from "react";
import PopUp, { PopupSize } from "./Popup";

import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWallet } from "@terra-money/wallet-provider";
import classNames from "classnames";
import { useContextUser } from "contexts/user";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useXidarWallet } from "utils/hooks/use-xidar-wallet";
import { useZ3usWallet } from "utils/hooks/use-z3us-wallet";
import { DeleteModal } from "./modals";

const Account: React.FC<{
  condensed: boolean;
}> = ({ condensed }) => {
  const { logout } = useContextUser();

  const { disconnect } = useWallet();
  const { disconnectZ3us } = useZ3usWallet();
  const { disconnectXidar } = useXidarWallet();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const { t } = useTranslation(["common", "enumerations"]);
  const { userData, setUser, setIsLoggedIn } = useContextUser();

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const updateWidth = () => setWidth(window.innerWidth);

  return (
    <>
      <div
        className={classNames(
          `w-full flex justify-between my-2 ${width < 1024 ? "mx-2" : "mx-5"}
           lg:items-center lg:mx-6 lg:mb-4`,
          { "flex-row": !condensed },
          { "lg:flex-col lg:mt-10": condensed }
        )}
      >
        {width >= 1024 && (
          <>
            <NavLink
              className="flex items-center flex-grow"
              to="/settings/user"
            >
              <img
                src="/images/account.svg"
                alt="Hermes Protocol account"
                className="w-10"
              />
              {!condensed && (
                <div className="px-0 account-user-name text-sm text-gray-dark font-semibold flex-grow mx-4">
                  {userData?.clientAlias
                    ? userData?.clientAlias
                    : "Hermes User"}
                </div>
              )}
            </NavLink>
            <div>
              <button
                onClick={logout}
                className={"z-10 flex flex-col items-center"}
              >
                <FontAwesomeIcon
                  className={classNames(
                    `text-gold float-right lg:text-gray-light40 lg:text-2xl text-xl lg:float-none mb-2 sm:mb-0`,
                    {
                      "lg:mt-0": condensed && !(width < 1024),
                    },
                    {
                      "sm:mt-1": width < 1024,
                    }
                  )}
                  icon={faSignOutAlt}
                />
                <span className="text-sm text-gray-light40 font-extrabold	">
                  Log Out
                </span>
              </button>
            </div>
          </>
        )}
        {width < 1024 && (
          <button onClick={logout} className={"z-10 flex-grow"}>
            {width < 1024 && (
              <div
                className={
                  "underline text-black-transparent50 text-base absolute sm:left-16 left-10"
                }
              >
                Log Out
              </div>
            )}

            <FontAwesomeIcon
              className={classNames(
                `text-gold float-right lg:text-gray-light40 lg:text-2xl text-xl lg:float-none mb-2 sm:mb-0`,
                {
                  "sm:mt-1": width < 1024,
                }
              )}
              icon={faSignOutAlt}
            />
          </button>
        )}
        {isOpenModal && (
          <>
            {!isLoggedOut && (
              <PopUp
                closeModal={() => setIsOpenModal(false)}
                size={PopupSize.smallxs}
              >
                <DeleteModal
                  fn={logout}
                  textBtnSubmit={t("logoutBtn", { ns: "common" })}
                  title={t("logout", { ns: "common" })}
                  setShowModal={setIsOpenModal}
                />
              </PopUp>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Account;
