export const NotificationsSvg = () => {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0638 9.77223C20.0638 11.0354 20.4125 11.7799 21.1798 12.6378C21.7613 13.2699 21.9472 14.0812 21.9472 14.9615C21.9472 15.8407 21.6454 16.6754 21.041 17.353C20.2496 18.1654 19.1335 18.684 17.9945 18.7742C16.344 18.9089 14.6923 19.0223 13.0188 19.0223C11.3442 19.0223 9.6936 18.9545 8.04302 18.7742C6.90296 18.684 5.78692 18.1654 4.9966 17.353C4.39212 16.6754 4.08936 15.8407 4.08936 14.9615C4.08936 14.0812 4.27623 13.2699 4.8567 12.6378C5.64806 11.7799 5.97379 11.0354 5.97379 9.77223V9.34376C5.97379 7.65216 6.41437 6.54603 7.32161 5.46321C8.67047 3.88404 10.8326 2.93695 12.9718 2.93695H13.0658C15.2509 2.93695 17.483 3.92963 18.8089 5.57666C19.6691 6.6372 20.0638 7.69673 20.0638 9.34376V9.77223ZM9.94427 21.1013C9.94427 20.5948 10.4297 20.3629 10.8787 20.2636C11.4038 20.1572 14.6037 20.1572 15.1288 20.2636C15.5778 20.3629 16.0632 20.5948 16.0632 21.1013C16.0371 21.5834 15.7417 22.0109 15.3335 22.2824C14.8041 22.6774 14.183 22.9276 13.5336 23.0178C13.1744 23.0623 12.8216 23.0633 12.475 23.0178C11.8245 22.9276 11.2033 22.6774 10.6751 22.2814C10.2658 22.0109 9.97037 21.5834 9.94427 21.1013Z"
            />
        </svg>
    );
}