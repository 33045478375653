import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import {
  library,
  IconName,
  IconPrefix,
} from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  fab,
  faTelegram as telegram,
  faTwitter as twitter,
  faDiscord as discord,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";

interface Props {
  icon: string;
  iconStyle: string;
  bgContainerTransparent?: boolean;
  containerWidth?: number;
  containerHeight?: number;
  fontSize?: string;
  textColor?: string;
  shadowIcons?: boolean;
  fontMobile?: string;
  className?: string;
}

export const IconSettings = ({
  icon,
  iconStyle,
  fontMobile,
  textColor,
  fontSize,
  shadowIcons,
  className,
  containerWidth,
  containerHeight,
  bgContainerTransparent,
}: Props & FontAwesomeIconProps) => {
  library.add(fas, fab);

  return (
    <div
      className={`${shadowIcons && "shadow-icons"} ${
        bgContainerTransparent ?? "bg-white "
      }${
        className ? className : " w-8 h-8"
      } rounded-full flex items-center justify-center`}
    >
      <FontAwesomeIcon
        icon={[iconStyle as IconPrefix, icon as IconName]}
        className={`${textColor} ${fontMobile ?? "text-sm"}  md:${fontSize}`}
      ></FontAwesomeIcon>
    </div>
  );
};
