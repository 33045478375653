import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserNotification } from "models/Interfaces";
import {
  isValidRadixWallet,
  isValidTerraWallet,
} from "../../../models/utils/terra";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { BlockchainType } from "models/Enums";

const WalletWatcher = ({
  setWallet,
  disabledBtn,
  notification,
}: {
  disabledBtn?: Dispatch<SetStateAction<any>>;
  setWallet: Dispatch<SetStateAction<any>>;
  notification?: UserNotification;
}) => {
  const { t } = useTranslation(["common", "enumerations"]);
  const { selectedNotificationBlockchain } = useNewNotificationFlow();

  const [walletInput, setWalletInput] = useState(
    notification ? notification.walletAddress : ""
  );

  /**
   * Verify is the address given is a valid wallet address
   * @param text wallet address
   * @returns boolean true or false
   */
  const checkWallet = (text: string) => {
    if (
      (selectedNotificationBlockchain === BlockchainType.Radix &&
        !isValidRadixWallet(text)) ||
      (selectedNotificationBlockchain !== BlockchainType.Radix &&
        !isValidTerraWallet(text))
    ) {
      text !== "" &&
        document.querySelector(".inputError")?.classList.remove("hidden");
      disabledBtn && disabledBtn(true);
      return false;
    } else {
      setWallet({ walletAddress: walletInput });
      disabledBtn && disabledBtn(false);
      return true;
    }
  };

  /**
   * Handle Wallet address input changed
   * @param e onChange event
   */
  const handleWalletChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWalletInput(e?.target.value);
    document.querySelector(".inputError")?.classList.add("hidden"); // reset input field on writing
    checkWallet(e?.target.value);
  };
  
  return (
    <div className="w-full">
      {!notification && (
        <div className="pb-4 border-b border-black-line w-full ">
          <h3 className="mt-6 font-semibold">
            {t(`notifications.wallet_watch`, { ns: "common" })}
          </h3>
        </div>
      )}
      <div className="w-full mt-8">
        <p className="px-1">
          {t(`notifications.wallet_watch_label`, { ns: "common" })}
        </p>
        <input
          value={walletInput}
          onBlur={() => checkWallet(walletInput)}
          name="wallet"
          onChange={handleWalletChanged}
          type="text"
          className={`focus:ring-0 focus:border-gray-extralight90medium focus:outline-none rounded w-full py-2 text-base 2xl:h-10 h-9 px-3 border-gray-extralight90medium text-black-transparent50`}
        />
        <small className="px-1 inputError hidden text-red">
          {t("notifications.invalid_wallet", { ns: "common" })}
        </small>
      </div>
    </div>
  );
};

export default WalletWatcher;