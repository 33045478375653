import { AuthService } from "services/auth.service";
import { ContactListService } from "services/contactList.service";
import { HistoryService } from "services/history.service";
import { NotificationsService } from "services/notifications.service";
import { SocialService } from "services/social.service";
import { SubscriptionService } from "services/subscription.service";
import { UserService } from "services/user.service";
import { WalletService } from "services/wallet.service";

export { terra } from "./Terra";
export { terraClassic } from "./terraClassic";

export namespace api {
  export const auth = new AuthService();
  export const contactList = new ContactListService();
  export const history = new HistoryService();
  export const notifications = new NotificationsService();
  export const social = new SocialService();
  export const subscription = new SubscriptionService();
  export const user = new UserService();
  export const wallet = new WalletService();
}
