import "../i18n.js";
import "./../style.css";

import Button, { ButtonSize, ButtonType } from "components/Button";
import { ContactListData, UserSubscription } from "models/Interfaces";
import Loader, { LoaderType } from "components/Loader";
import PopUp, { PopupSize } from "components/Popup";
import { useEffect, useState } from "react";

import BottomMenu from "components/BottomMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar } from "components/Navbar";
import RightSection from "../components/RightSection";
import { SocialChannel } from "models/Enums";
import SubscriptionCard from "components/SubscriptionCard";
import { api } from "shared";
import classNames from "classnames";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { getNotificationIcon } from "components/notifications/notificationSteps/SocialChannels";
import { instanceOfUserData } from "utils/instanceOf";
import { useContextUser } from "contexts/user";
import { useTranslation } from "react-i18next";

export const SubscriptionsPage: React.FC = () => {
  const { t } = useTranslation(["common", "enumerations"]);
  const { userData, setUser } = useContextUser();

  const [isCondensed, setIsCondensed] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(0);
  const [showPopEdit, setShowPopEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<boolean>(false);
  const [releaseButton, setReleaseButton] = useState<boolean>(false);
  //for future social channels edit
  const [socialChannels, setSocialChannels] = useState<SocialChannel[]>([]);
  const [contactLists, setContactLists] = useState<ContactListData[]>([]);
  //for edit popup purposes
  const [editedSubscription, setEditedSubscription] = useState<
    UserSubscription | undefined
  >();
  const [contactListEditedSubscription, setContactListEditedSubscription] =
    useState<ContactListData | undefined>();

  const checkContactListData = async () => {
    if (userData?.subscriptions && userData?.subscriptions.length > 0) {
      getContactListData().finally(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  };
  const getContactListData = async () => {
    return await Promise.all(
      userData!.subscriptions!.map(async (subscription) => {
        const response = await api.contactList.get(subscription.listId);

        if (response.status === 200) {
          const contactListData = contactLists;
          const thisList = response;
          thisList.id = subscription.listId;
          contactListData.push(thisList);
          setContactLists(contactListData);
        }
        return response;
      })
    );
  };

  const handleEditSubscription = async (
    subscription: UserSubscription,
    contactList: ContactListData
  ) => {
    setEditedSubscription(subscription);
    setContactListEditedSubscription(contactList);
    setStatus(subscription.status === 1 ? true : false);

    //for future social channels edit
    subscription.channels?.some((social) => social.channel === "discord") &&
      setSocialChannels([...socialChannels, SocialChannel.Discord]);
    subscription.channels?.some((social) => social.channel === "telegram") &&
      setSocialChannels([...socialChannels, SocialChannel.Telegram]);

    setShowPopEdit(true);
  };
  const hasSocialAccount = (channel?: string): boolean => {
    if (!userData?.socials?.length) {
      return false;
    }

    if (channel) {
      return userData?.socials.filter(
        (c) => c.type.toUpperCase() === channel && c.status !== 0
      ).length !== 0
        ? true
        : false;
    }
    return true;
  };

  //for future edit social channels
  const handleSelectedSubscriptionTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const socialChannelType = event.target.name as SocialChannel;
    const checked = event.target.checked;

    if (!checked) {
      const filteredChannels = socialChannels.filter(
        (social) => social !== socialChannelType
      );
      setSocialChannels(filteredChannels);
    } else {
      setSocialChannels([...socialChannels, socialChannelType]);
    }
  };

  const handleClickChangeStatus = () => {
    setStatus(!status);
    showStatus();
    setReleaseButton(true);
  };

  const editSubscription = async (): Promise<void> => {
    setIsLoading(true);
    if (editedSubscription) {
      const response = await api.subscription.update({
        status: status ? 1 : 0,
        listId: editedSubscription.listId,
        social: editedSubscription.channels?.[0].channel,
        socialId: editedSubscription.channels?.[0].channel_id,
      });

      if (typeof response === "object" && instanceOfUserData(response)) {
        setUser(response);
        setIsLoading(false);
        setShowPopEdit(false);
      } else {
        console.log("Error: ", response.error);
      }
      return response;
    } else {
      console.log("Error: ", t("subscriptions_error", { ns: "common" }));
    }
    setIsLoading(false);
  };
  const showStatus = () => {
    const element = document.querySelector(".status");
    element?.classList.remove("opacity-0");
    setTimeout(() => element?.classList.add("opacity-0"), 1500);
  };

  useEffect(() => {
    setNavbarWidth(document.getElementById("sideMenu")?.clientWidth || 0);
  }, [isCondensed]);

  useEffect(() => {
    checkContactListData();
  }, []);

  return (
    <>
      <Navbar setIsCondensed={setIsCondensed} isCondensed={isCondensed} />
      <RightSection isCondensed={isCondensed}>
        <div className="relative md:w-full flex items-center md:px-2 lg:px-6 pb-8 max-w-6xl">
          <div className="px-2 md:px-0 center flex-col w-full">
            <section className="flex flex-col w-full">
              <h1 className="mt-2 w-full justify-between mb-3 ml-4">
                {t("menu.subscriptions", { ns: "common" })}
              </h1>
              {isLoading && <Loader type={LoaderType.fullScreen} />}
              {!isLoading && !userData?.subscriptions && (
                <div className="mt-2 w-full mb-3 ml-4">
                  {t("subscriptions.no_subscriptions", { ns: "common" })}
                </div>
              )}
              {!isLoading && userData?.subscriptions && (
                <div className="flex w-full flex-wrap">
                  {userData.subscriptions.map((subscription) => {
                    const contactList = contactLists?.find(
                      (listLine: ContactListData) =>
                        listLine.id === subscription.listId
                    );
                    return contactList &&
                      Object.keys(contactList.list).length > 0 ? (
                      <SubscriptionCard
                        key={subscription.subscriptionId}
                        subscription={subscription}
                        contactListData={contactList}
                        handleEditButton={() =>
                          handleEditSubscription(subscription, contactList)
                        }
                      />
                    ) : null;
                  })}
                </div>
              )}
            </section>
          </div>
        </div>
      </RightSection>
      <BottomMenu />
      {showPopEdit && (
        <PopUp
          closeModal={() => setShowPopEdit(false)}
          size={PopupSize.largeXL}
        >
          <div className="p-3 rounded-sm relative w-full max-h-screen overflow-y-auto grayCustomScrollbar">
            <div className="mb-10">
              <div className={`flex justify-between`}>
                <h2 className="mb-6 flex-grow">
                  {t("subscriptions.edit_subscription", { ns: "common" })}
                </h2>
                <div className={`block ${!hasSocialAccount() && "opacity-50"}`}>
                  <label className="switch block">
                    <input
                      type="checkbox"
                      onChange={handleClickChangeStatus}
                      disabled={!hasSocialAccount()}
                      checked={status}
                    />
                    <span
                      className={` ${
                        !hasSocialAccount() && "opacity-70"
                      } slider round checked:bg-black`}
                    ></span>
                  </label>
                  <div className="block">
                    <small className="status ml-1 opacity-0 ease delay-75 transition-opacity in-out">
                      {status ? "Active" : "Inactive"}
                    </small>
                  </div>
                </div>
              </div>
              <h4 className="mb-2 h-1/6">
                {contactListEditedSubscription?.client.brandName || "---"}
              </h4>
              <div className="flex items-center">
                <span className="text-gray-semibold">
                  <FontAwesomeIcon
                    icon={faTags}
                    className="bg-transparent"
                    size="sm"
                    style={{ marginRight: "10px" }}
                  />
                </span>
                <h6 className="font-medium text-xsm">
                  {contactListEditedSubscription?.list.namePublic || "---"}
                </h6>
              </div>
            </div>
            <div className="pb-3 w-full">
              <div className="w-full">
                <p className="sm:mt-6 mt-3 px-2 font-semibold mb-3">
                  {t(`social_channels`, { ns: "common" })}
                </p>
                <div className="flex justify-center items-center mb-2">
                  <div className="w-5/6">
                    {Object.values(SocialChannel).map((channelKey) => {
                      const channel = channelKey as SocialChannel;
                      return (
                        <div
                          key={channel}
                          className="flex items-center justify-between my-2"
                        >
                          <div>
                            {getNotificationIcon(channel)}
                            {t(`social_channels.${channel}`, {
                              ns: "enumerations",
                            })}
                          </div>

                          <label className="switch">
                            <input
                              type="checkbox"
                              name={channel}
                              //onChange={handleSelectedSubscriptionTypeChange}
                              disabled={!hasSocialAccount(channel)}
                              checked={socialChannels.includes(channel)}
                            />

                            <span
                              className={classNames(
                                "slider round checked:bg-black",
                                { "opacity-70": !hasSocialAccount(channel) }
                              )}
                            ></span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {!hasSocialAccount() && (
                  <div className="text-black text-xs mb-6">
                    {t("notifications.no_social", { ns: "common" })}
                  </div>
                )}
              </div>
            </div>
            <div className="px-3">
              <div className="flex justify-end">
                <div className="flex">
                  <Button
                    type={ButtonType.cancel}
                    size={ButtonSize.square}
                    className={"mx-2"}
                    onClick={() => setShowPopEdit(false)}
                  >
                    {t("action_cancel", { ns: "common" })}
                  </Button>

                  <Button
                    disabled={!releaseButton}
                    type={ButtonType.primary}
                    size={ButtonSize.square}
                    onClick={() => editSubscription()}
                  >
                    {t("action_save", { ns: "common" })}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </PopUp>
      )}
    </>
  );
};
