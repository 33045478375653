import {
  BlockchainType,
  CategoryType,
  NotificationType,
  PlatformType,
  SocialChannel,
} from "models/Enums";
import Notification, {
  AlertConfiguration,
  ConfigurationParams,
} from "models/Notification";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

import { api } from "shared";
import { instanceOfUserData } from "utils/instanceOf";
import { useContextUser } from "../../contexts/user/index";
import { UserData } from "../../models/Interfaces";

export interface INotificationsState {
  activeNotifications: Notification[];
  isLoadingNotifications: boolean;
  isCreatingNotification: boolean;
  isEditingNotification: boolean;
  isRemovingNotification: boolean;
  createNotification(
    userId: string,
    status: string,
    chainId: BlockchainType,
    type: NotificationType,
    category: CategoryType,
    platform: PlatformType,
    socialChannels: SocialChannel[],
    alertConfiguration: AlertConfiguration
  ): Promise<UserData | undefined>;
  editNotification(
    notificationId: string,
    userId: string,
    status: string,
    socialChannels: SocialChannel[],
    alertConfiguration: ConfigurationParams
  ): Promise<void>;
  removeNotification(id: string): Promise<void>;
  error: string | null;
  setError: Dispatch<SetStateAction<string | null>>;
}

const NotificationsContext = createContext<INotificationsState>({
  activeNotifications: [],
  isLoadingNotifications: false,
  isCreatingNotification: false,
  isEditingNotification: false,
  isRemovingNotification: false,
  createNotification: () => {
    throw "Requesting component not nested inside NotificationsProvider";
  },
  editNotification: () => {
    throw "Requesting component not nested inside NotificationsProvider";
  },
  removeNotification: () => {
    throw "Requesting component not nested inside NotificationsProvider";
  },
  error: null,
  setError: () => {
    throw "Requesting component not nested inside UserContextProvider";
  },
});

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeNotifications, setActiveNotifications] = useState<
    Notification[]
  >([]);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [isCreatingNotification, setIsCreatingNotification] = useState(false);
  const [isEditingNotification, setIsEditingNotification] = useState(false);
  const [isRemovingNotification, setIsRemovingNotification] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const { userData, setUser } = useContextUser();

  const createNotification = async (
    userId: string,
    status: string,
    chainId: BlockchainType,
    type: NotificationType,
    category: CategoryType,
    platform: PlatformType,
    socialChannels: SocialChannel[],
    alertConfiguration: AlertConfiguration
  ): Promise<UserData | undefined> => {
    setIsCreatingNotification(true);

    let discordId = "",
      telegramId = "",
      twitterId = "";

    socialChannels?.map((s) => {
      if (s === SocialChannel.Discord) {
        discordId =
          userData?.socials?.filter(
            (s) => s.type === SocialChannel.Discord.toLowerCase()
          )[0].id || "";
      } /* else if (s === SocialChannel.Telegram) {
        telegramId =
          userData?.socials?.filter(
            (s) => s.type === SocialChannel.Telegram.toLowerCase()
          )[0].id || "";
      } */
    });

    const response = await api.notifications.create({
      status: socialChannels.length > 0 ? "1" : "0",
      chainId,
      categoryId: category,
      projectId: platform,
      notificationTypeId: type,
      publicId: {
        groupId: "",
        channelId: "",
        type: "",
      },
      discordId,
      twitterId,
      telegramId,
      ...alertConfiguration.params,
    });

    if (typeof response === "object" && instanceOfUserData(response)) {
      setUser(response);
    } else {
      setError(response?.error);
    }
    setIsCreatingNotification(false);
    return response;
  };

  const editNotification = async (
    notificationId: string,
    userId: string,
    status: string,
    socialChannels: SocialChannel[],
    alertConfiguration: ConfigurationParams
  ): Promise<void> => {
    setIsEditingNotification(true);

    let discordId = "",
      telegramId = "",
      twitterId = "";

    socialChannels?.map((s) => {
      if (s === SocialChannel.Discord) {
        discordId =
          userData?.socials?.filter(
            (s) => s.type === SocialChannel.Discord.toLowerCase()
          )[0].id || "";
      } /* else if (s === SocialChannel.Telegram) {
        telegramId =
          userData?.socials?.filter(
            (s) => s.type === SocialChannel.Telegram.toLowerCase()
          )[0].id || "";
      } */
    });

    const response = await api.notifications.update(
      {
        status,
        discordId,
        twitterId,
        telegramId,
        ...alertConfiguration,
      },
      notificationId
    );

    if (typeof response === "object" && instanceOfUserData(response)) {
      setUser(response);
    } else {
      setError(response?.error);
    }

    setIsEditingNotification(false);
    return response;
  };

  const removeNotification = async (id: string): Promise<void> => {
    setIsRemovingNotification(true);

    const response = await api.notifications.delete(id);

    if (typeof response === "object" && instanceOfUserData(response)) {
      setUser(response);
    } else {
      setError(response?.error);
    }

    setIsRemovingNotification(false);
    return response;
  };

  return (
    <NotificationsContext.Provider
      value={{
        activeNotifications: activeNotifications,
        isLoadingNotifications: isLoadingNotifications,
        isCreatingNotification: isCreatingNotification,
        isEditingNotification: isEditingNotification,
        isRemovingNotification: isRemovingNotification,
        createNotification: createNotification,
        editNotification: editNotification,
        removeNotification: removeNotification,
        error: error,
        setError,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
