import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import Z3us from "../assets/images/z3us.png";
import RightSectionLogin from "../components/RightSectionLogin";
import { useCallback, useEffect, useState } from "react";
import PopUp, { PopupSize } from "components/Popup";
import ModalAuth from "../components/auth/ModalAuth";
import successIcon from "../assets/images/success_icon.svg";
import {
  SignBytesFailed,
  SignBytesResult,
  Timeout,
  useConnectedWallet,
  UserDenied,
  useWallet,
  WalletStatus,
} from "@terra-money/wallet-provider";
import { useTranslation } from "react-i18next";
import SideMenu, { ModeType } from "../components/SideMenu";
import LoginSectionButtons from "../components/auth/LoginSectionButtons";
import {
  AuthFlow,
  ConnectWallet,
  WalletTypes,
} from "../components/auth/ConnectWallet";
import { ModalState } from "./SignIn";
import classNames from "classnames";
import { instanceOfUserData } from "utils/instanceOf";
import { useContextUser } from "contexts/user";
import { Warning } from "../components/modals";
import Loader, { LoaderType } from "components/Loader";
import Button from "components/Button";
import { useZ3usWallet } from "../utils/hooks/use-z3us-wallet";
import { useXidarWallet } from "utils/hooks/use-xidar-wallet";
import { BlockchainType } from "models/Enums";

export enum LoginStep {
  connectWallet = "connectWallet",
  verifyWallet = "verifyWallet",
}

export const LoginPage: React.FC = () => {
  const { status, disconnect } = useWallet();
  const { t } = useTranslation(["common", "enumerations"]);
  const connectedWallet = useConnectedWallet();
  const { setUser } = useContextUser();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  let dataFromHandleSocials = state;
  const { disconnectZ3us, signZ3us, isConnectedZ3us } = useZ3usWallet();
  const { disconnectXidar, signXidar, isConnectedXidar } = useXidarWallet();

  const [width, setWidth] = useState(window.innerWidth);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState<null | ModalState>(null);
  const [currentStep, setCurrentStep] = useState(
    status === WalletStatus.WALLET_CONNECTED ||
      isConnectedZ3us ||
      isConnectedXidar
      ? LoginStep.verifyWallet
      : LoginStep.connectWallet
  );
  const [randomBytes, setRandomBytes] = useState("");
  const [txError, setTxError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tempUserData, setTempUserData] = useState(null);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [z3usWallet, setZ3usWallet] = useState<string | null>(null);
  const [xidarWallet, setXidarWallet] = useState<string | null>(null);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const handleModal = (content: any) => {
    setIsOpenModal && setIsOpenModal(true);
    setModalContent(content);
  };

  const handleLogin = async (
    walletAddress: string,
    signBytesResult: any,
    originalBytes: any,
    chainId: BlockchainType
  ) => {
    setIsLoading(true);
    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/auth/login/webapp`,
        {
          method: "POST",
          body: JSON.stringify({
            walletAddress,
            signBytesResult,
            originalBytes,
            chainId,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      if (typeof dataFromApi.data === "string") {
        handleModal(ModalState.noUser);
      } else if (dataFromApi.data.error) {
        setTxError(dataFromApi.data.error);
        handleModal(ModalState.failure);
      } else if (dataFromApi.data.mfaEnabled) {
        /* For users that have already enabled MFA, just ask for token */
        setTempUserData(dataFromApi.data.userId);
        handleModal(ModalState.mfaValidate);
      } else if (
        /* For users that do not have MFA enabled, login */
        typeof dataFromApi.data === "object" &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setTempUserData(dataFromApi.data);
        handleModal(ModalState.success);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleLoggedInUser = async () => {
    setIsOpenModal(false);
    setIsLoading(true);
    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/auth/${qrCode ? "mfa-verify" : "mfa-validate"
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            userId: tempUserData,
            token,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      if (connection.status === 400) {
        setTxError(dataFromApi.data.error);
        handleModal(ModalState.mfaFailure);
      } else if (
        typeof dataFromApi.data === "object" &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setTempUserData(dataFromApi.data);
        handleModal(ModalState.success);
      } else {
        handleModal(ModalState.failure);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const signBytes = useCallback(
    async (originalBytes) => {
      if (!connectedWallet) {
        return;
      }
      try {
        const bufferedBytes = Buffer.from(originalBytes);
        /* Sign bytes with bytes fetched from API to this specific wallet */
        const signedBytes: SignBytesResult = await connectedWallet.signBytes(
          bufferedBytes
        );
        /* Send information from signing to API to verify the transaction */
        handleLogin(
          connectedWallet.terraAddress,
          signedBytes.result,
          originalBytes,
          BlockchainType.Terrav2
        );
      } catch (error) {
        if (error instanceof UserDenied) {
          setTxError("User Denied");
        } else if (error instanceof Timeout) {
          setTxError("Timeout");
        } else if (error instanceof SignBytesFailed) {
          setTxError("Sign Bytes Failed");
        } else {
          setTxError(
            "Unknown Error: " +
            (error instanceof Error ? error.message : String(error))
          );
        }
        handleModal(ModalState.failure);
      }
    },
    [connectedWallet]
  );

  const handleWarningText = () => {
    if (txError) {
      return txError;
    } else {
      return t("modal_content.failure.modal_title", {
        ns: "enumerations",
      });
    }
  };

  const handleTryAgain = () => {
    setTimeout(() => {
      disconnect();
      disconnectZ3us();
      disconnectXidar();
      setCurrentStep(LoginStep.connectWallet);
      setIsOpenModal(false);
      setModalContent(null);
    }, 3000);
  };

  const handleSetUser = () => {
    setTimeout(() => {
      setIsOpenModal(false);
      setModalContent(null);
      setUser(tempUserData);
    }, 3000);
  };

  const handleDisconnect = () => {
    disconnect();
    disconnectZ3us();
    disconnectXidar();
    setIsOpenModal(false);
    setModalContent(null);
  };

  const handleCloseModal = (hasButton: boolean) => {
    if (hasButton) {
      setIsOpenModal(false);
      dataFromHandleSocials = null;
      setModalContent(null);
    } else {
      setTimeout(() => {
        setIsOpenModal(false);
        dataFromHandleSocials = null;
        setModalContent(null);
      }, 3000);
    }
  };

  const handleTryMfaAgain = () => {
    setTimeout(() => {
      qrCode && handleModal(ModalState.mfa);
      !qrCode && handleModal(ModalState.mfaValidate);
    }, 2000);
  };

  useEffect(() => {
    if (dataFromHandleSocials !== null) {
      if (dataFromHandleSocials.userId) {
        setTempUserData(dataFromHandleSocials.userId);
      }
      setIsOpenModal(true);
      setModalContent(dataFromHandleSocials.modalContent);
      dataFromHandleSocials = null;
    }
  }, [dataFromHandleSocials]);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    const handleVerify = async () => {
      if (currentStep === LoginStep.verifyWallet) {
        let wallet;
        if (connectedWallet) {
          wallet = connectedWallet?.terraAddress;
        } else if (xidarWallet) {
          wallet = xidarWallet;
        } else if (z3usWallet) {
          wallet = z3usWallet;
        }
        if (wallet) {
          /* Upon intention to verify wallet, ask the API for bytes for that wallet */
          try {
            const connection = await fetch(
              `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/auth/bytes`,
              {
                method: "POST",
                body: JSON.stringify({
                  walletAddress: wallet,
                }),
                credentials: "include",
              }
            );
            const dataFromApi = await connection.json();
            setRandomBytes(dataFromApi.data.bytes);
            if (
              connectedWallet?.connection.identifier === WalletTypes.station
            ) {
              await signBytes(dataFromApi.data.bytes);
            } else if (z3usWallet) {
              try {
                const originalBytes = dataFromApi.data.bytes;
                const signedMessage = await signZ3us(originalBytes);
                wallet &&
                  handleLogin(
                    wallet,
                    signedMessage,
                    originalBytes,
                    BlockchainType.Radix
                  );
              } catch (error) {
                setTxError("Sign failed: " + error);
                handleModal(ModalState.failure);
              }
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          setTxError("No wallet");
          handleModal(ModalState.failure);
        }
      }
    };
    handleVerify();
  }, [currentStep]);

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <div className="flex flex-grow">
        <SideMenu authPage={true} mode={ModeType.wide}>
          <div className="flex flex-col">
            <h2 className="self-center text-md md:text-lg">
              {t("login", {
                ns: "common",
              })}
            </h2>
            <LoginSectionButtons
              walletStatus={status}
              onConnectWalletClick={() => setIsOpenModal(true)}
            />
          </div>
        </SideMenu>
        <RightSectionLogin />
        {isOpenModal && (
          <>
            {modalContent === null && (
              <PopUp
                size={PopupSize.large}
                closeModal={() => setIsOpenModal(false)}
              >
                <ConnectWallet
                  setIsOpenModal={setIsOpenModal}
                  setModalContent={setModalContent}
                  modalContent={modalContent}
                  authFlow={AuthFlow.login}
                  setCurrentStep={setCurrentStep}
                  setZ3usWallet={setZ3usWallet}
                  setXidarWallet={setXidarWallet}
                />
              </PopUp>
            )}
            {modalContent === ModalState.success && (
              <PopUp size={PopupSize.large}>
                <ModalAuth
                  modalTitle={t(
                    "modal_content.connect_wallet_success.modal_title",
                    { ns: "enumerations" }
                  )}
                  modalImage={successIcon}
                >
                  {handleSetUser()}
                </ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.failure && (
              <PopUp size={PopupSize.large}>
                <Warning
                  title={"Warning"}
                  text={handleWarningText()}
                  additionalText={t("modal_content.failure.additional_title", {
                    ns: "enumerations",
                  })}
                  iconWarning={true}
                />
                {handleTryAgain()}
              </PopUp>
            )}
            {modalContent === ModalState.noUser && (
              <PopUp size={PopupSize.large}>
                <ModalAuth
                  modalTitle={t(
                    "modal_content.user_does_not_exists.modal_title",
                    {
                      ns: "enumerations",
                    }
                  )}
                  additionalTitle={t(
                    "modal_content.user_does_not_exists.additional_title",
                    {
                      ns: "enumerations",
                    }
                  )}
                  logo={true}
                  primaryButton={t(
                    "modal_content.user_does_not_exists.primary_button",
                    { ns: "enumerations" }
                  )}
                  secondaryButton={t(
                    "modal_content.user_does_not_exists.secondary_button",
                    { ns: "enumerations" }
                  )}
                  primaryButtonOnClick={handleDisconnect}
                  secondaryButtonOnClick={() => navigate("/")}
                />
              </PopUp>
            )}
            {modalContent === ModalState.noSocial && (
              <PopUp size={PopupSize.large}>
                <ModalAuth
                  modalTitle={t("ups", { ns: "common" })}
                  additionalTitle={t(
                    "enumerations:modal_content.user_does_not_have_social"
                  )}
                  logo={true}
                >
                  {handleCloseModal(false)}
                </ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.guestUser && (
              <PopUp size={PopupSize.largeXL}>
                <ModalAuth
                  modalTitle={t("modal_content.user_is_guest.modal_title", {
                    ns: "enumerations",
                  })}
                  name={dataFromHandleSocials.username}
                  additionalTitle={t(
                    "modal_content.user_is_guest.additional_title",
                    {
                      ns: "enumerations",
                    }
                  )}
                  logo={true}
                  primaryButton={t(
                    "modal_content.user_is_guest.primary_button",
                    { ns: "enumerations" }
                  )}
                  secondaryButton={t(
                    "modal_content.user_is_guest.secondary_button",
                    { ns: "enumerations" }
                  )}
                  primaryButtonOnClick={() => handleCloseModal(true)}
                  secondaryButtonOnClick={() => navigate("/")}
                />
              </PopUp>
            )}
            {modalContent === ModalState.mfaValidate && (
              <PopUp size={PopupSize.large}>
                <ModalAuth
                  modalTitle={t("modal_content.mfaValidate.modal_title", {
                    ns: "enumerations",
                  })}
                  additionalTitle={t(
                    "modal_content.mfaValidate.additional_title",
                    {
                      ns: "enumerations",
                    }
                  )}
                  logo={true}
                >
                  <input
                    onChange={(e) => setToken(e.target.value)}
                    className="appearance-none outline-none focus:outline-none border text-lg rounded py-2 2xl:h-10 h-9 px-3 border-gray-extralight90medium text-black-transparent50 w-32 text-center"
                    maxLength={6}
                  />
                  <div className="text-right pt-5">
                    <Button
                      onClick={() => handleLoggedInUser()}
                      disabled={!token || token?.length !== 6}
                      className="text-white bg-gold active:bg-yellow-700 text-base px-6 py-2 rounded shadow lg:hover:shadow-lg outline-none focus:outline-none "
                    >
                      {t("login", {
                        ns: "common",
                      })}
                    </Button>
                  </div>
                </ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.mfaFailure && (
              <PopUp size={PopupSize.large}>
                <Warning
                  title={"Warning"}
                  text={handleWarningText()}
                  iconWarning={true}
                />
                {handleTryMfaAgain()}
              </PopUp>
            )}
          </>
        )}
      </div>
    </>
  );
};
