import React from "react";
import classNames from "classnames";
import { Link, useMatch } from "react-router-dom";

const BottomMenuItem: React.FC<{ icon: React.ReactElement; url: string }> = ({
  icon,
  url,
}) => {
  const isActive = useMatch(url) != null;

  return (
    <div className={classNames("bottom-menu-item py-5")}>
      <Link to={url} className="z-10 flex">
        <div className="z-10 flex">
          <div
            className={classNames(
              "icon",
              { "text-gold": isActive },
              { "text-gray-light40": !isActive }
            )}
          >
            {icon}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BottomMenuItem;
