import { BlockchainInfo, NotificationTypes } from "models/Interfaces";

export const getBlockchainForAssets = (chain: string) => {
  switch (chain) {
    case "terra":
    case "Terrav2":
    case "zb1_002":
      return "terra";
    case "terraClassic":
    case "TerraClassic":
    case "zb1_001":
      return "terraClassic";
    default:
      return "radix";
  }
};

/* Reducing existing elements (blockchains, categories, projects):
- from notification types collection, to get all active elements on DB (available on FE), or
- from it own collection, to get all existing elements on DB
*/
export const getElementsFromCollections = (
  collection: any,
  element: string,
  chain?: string
) => {
  let types = collection;
  if (collection && "only_BE" in collection[0]) {
    types = collection?.filter((type: NotificationTypes) => type.only_BE === 0);
  }
  if (chain) {
    types = types.filter(
      (type: NotificationTypes) => type.chain_id === chain
    );
  }
  const elementsFromTypes: BlockchainInfo[] = Object.values(
    types?.reduce((acc: any, curr: any) => {
      const elementDesc: string = `${element}_desc`;
      const elementId: string = `${element}_id`;
      const key: string = curr[elementDesc];
      const id: string = curr[elementId];
      if (!acc[key]) {
        acc[key] = {
          key,
          id,
        };
      }
      return acc;
    }, {})
  );
  return elementsFromTypes;
};