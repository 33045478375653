import React from "react";
import classNames from "classnames";
import BottomMenuItem from "./BottomMenuItem";
import Logo from "./Logo";
import { NotificationsSvg } from "./svgs/DashboardSvg";
import { SettingsSvg } from "./svgs/SettingsSvg";
import { historyUrl, notificationsUrl, settingsUrl, subscriptionsUrl } from "utils/menuUtils";
import { SubscriptionsSvg } from "./svgs/SubscriptionsSvg";
import { HistorySvg } from "./svgs/HistorySvg";

export enum ActiveMenu {
  dash = "dashboard",
  notifications = "notifications",
  feed = "feed",
  settings = "settings"
}

const BottomMenu: React.FC = () => {
  return (
    <div
      className={classNames("z-20 fixed bottom-0 left-0 right-0 h-26 bg-white shadow-bottom-menu lg:hidden")}
    >
      <div
        className={classNames(
          "bottom-menu px-9 flex flex-row justify-between items-center",
        )}
      >
        <BottomMenuItem icon={<NotificationsSvg />} url={notificationsUrl} />
        <BottomMenuItem icon={<SubscriptionsSvg />} url={subscriptionsUrl} />
        <Logo condensed={true} className={"p-0 m-0 w-10 h-10"} />
        <BottomMenuItem icon={<HistorySvg />} url={historyUrl} />
        <BottomMenuItem icon={<SettingsSvg />} url={settingsUrl} />
      </div>
    </div>
  );
};

export default BottomMenu;
