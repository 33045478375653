import Loader, { LoaderType } from "components/Loader";
import PopUp, { PopupSize } from "components/Popup";
import { DeleteModal, Warning } from "components/modals";

import Button from "components/Button";
import { MfaEnabling } from "components/auth/MfaEnabling";
import { useContextUser } from "contexts/user";
import QRCode from "qrcode";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "shared";
import { instanceOfUserData } from "utils/instanceOf";
import { SettingsTypes } from "views/Settings";

export enum ModalState {
  failure = "connectWallet",
  mfa = "mfa",
  success = "success",
  disable = "disable",
}

export const SecuritySettingsEdit: React.FC<{}> = () => {
  const { t } = useTranslation(["common"]);
  const { userData, setUser } = useContextUser();

  const [isLoading, setIsLoading] = useState(false);
  const [enableMfa, setEnableMfa] = useState<boolean>(
    userData?.mfaEnabled ?? false
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState<null | ModalState>(null);
  const [error, setError] = useState<null | string>(null);
  const [tempUserData, setTempUserData] = useState<any>(null);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const handleMfaSetup = async (e: any) => {
    setEnableMfa(!enableMfa);
    if (e.target.checked) {
      const response = await api.auth.enable2FA();

      if (response.error) {
        setError(response.error);
        handleModal(ModalState.failure);
      } else {
        setTempUserData(response);
        QRCode.toDataURL(
          response.temp_secret.otpauth_url,
          function (err, data_url) {
            setQrCode(data_url);
            handleModal(ModalState.mfa);
          }
        );
      }
    } else {
      handleModal(ModalState.disable);
    }
  };

  const handleDisable = async () => {
    const response = await api.auth.disable2FA();
    if (response?.error) {
      setError(response.error);
      handleModal(ModalState.failure);
    } else if (typeof response === "object" && instanceOfUserData(response)) {
      setUser(response);
      setEnableMfa((response as any).enableMFA);
      setIsOpenModal(false);
    } else {
      handleModal(ModalState.failure);
    }
  };

  const handleModal = (content: any) => {
    setIsOpenModal && setIsOpenModal(true);
    setModalContent(content);
  };

  const handleWarningText = (type: string) => {
    if (type === "text") {
      if (error) {
        return error;
      } else {
        return t("modal_content.mfa_failure.modal_title", {
          ns: "enumerations",
        });
      }
    } else {
      return t("modal_content.mfa_failure.additional_title", {
        ns: "enumerations",
      });
    }
  };

  const handleCloseModal = () => {
    setTimeout(() => {
      setIsOpenModal(false);
      setModalContent(null);
    }, 2000);
  };

  const handleExitProcess = () => {
    setIsOpenModal(false);
    setEnableMfa(!enableMfa);
  };

  const handleLoggedInUser = async () => {
    setIsOpenModal(false);
    setIsLoading(true);
    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/auth/mfa-verify`,
        {
          method: "POST",
          body: JSON.stringify({
            userId: tempUserData.userId,
            token,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      if (connection.status === 400) {
        setError(dataFromApi.data.error);
        handleModal(ModalState.failure);
        setEnableMfa(!enableMfa);
      } else if (
        typeof dataFromApi.data === "object" &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setUser(dataFromApi.data);
        handleModal(ModalState.success);
      } else {
        handleModal(ModalState.failure);
        setEnableMfa(!enableMfa);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <div className="relative userSettings md:w-full flex items-center md:px-6 pb-8 py-5 max-w-6xl">
        <section className="px-2 md:px-0 flex w-full justify-between">
          <h5 className="text-sxm font-medium">
            {t("settings.enableMFA", { ns: "common" })}
          </h5>
          <label className="switch mb-5">
            <input
              id="mfa-checkbox"
              type="checkbox"
              checked={enableMfa || false}
              onChange={(e) => handleMfaSetup(e)}
            />
            <span className={"slider round checked:bg-black"}></span>
          </label>
        </section>
      </div>
      {isOpenModal && (
        <>
          {modalContent === ModalState.failure && (
            <PopUp size={PopupSize.large}>
              <Warning
                title={t("modal_content.warning.title", { ns: "enumerations" })}
                text={handleWarningText("text")}
                additionalText={handleWarningText("additional")}
                iconWarning={true}
              />
              {handleCloseModal()}
            </PopUp>
          )}
          {modalContent === ModalState.mfa && qrCode && (
            <PopUp size={PopupSize.largeXL} closeModal={handleExitProcess}>
              <MfaEnabling
                qrCode={qrCode}
                tempUserData={tempUserData}
                setToken={setToken}
              />
              <div className="text-right pt-5">
                <Button
                  onClick={() => handleLoggedInUser()}
                  disabled={!token || token?.length !== 6}
                  className="text-white bg-gold active:bg-yellow-700 text-base px-6 py-2 rounded shadow lg:hover:shadow-lg outline-none focus:outline-none "
                >
                  Enable
                </Button>
              </div>
            </PopUp>
          )}
          {modalContent === ModalState.success && (
            <PopUp size={PopupSize.smallxs}>
              <Warning
                title={"Congratulations"}
                text={t("modal_content.mfa_success", {
                  ns: "enumerations",
                })}
                colorIcon={"bg-gold"}
                iconSuccess={true}
              />
              {handleCloseModal()}
            </PopUp>
          )}
          {modalContent === ModalState.disable && (
            <PopUp size={PopupSize.smallxs} closeModal={handleExitProcess}>
              <DeleteModal
                id={ModalState.disable}
                title={t("settings.mfaDisable", { ns: "common" })}
                description={t("settings.mfaMoreInfo", { ns: "common" })}
                textBtnCancel={t("action_cancel", { ns: "common" })}
                textBtnSubmit={t("action_disable", { ns: "common" })}
                setShowModal={handleExitProcess}
                type={SettingsTypes.otherSettings}
                fn={handleDisable}
              />
            </PopUp>
          )}
        </>
      )}
    </>
  );
};
