export const SettingsSvg = () => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8445 13.8929C22.2202 14.0839 22.5101 14.3857 22.714 14.6874C23.1113 15.3109 23.079 16.0753 22.6926 16.7491L21.9411 17.956C21.5439 18.5997 20.8032 19.002 20.041 19.002C19.6652 19.002 19.2466 18.9014 18.903 18.7002C18.6239 18.5293 18.3019 18.4689 17.9583 18.4689C16.8955 18.4689 16.0045 19.3037 15.9723 20.2994C15.9723 21.4559 14.9847 22.3611 13.7501 22.3611H12.2901C11.0448 22.3611 10.0572 21.4559 10.0572 20.2994C10.0357 19.3037 9.1447 18.4689 8.08192 18.4689C7.72765 18.4689 7.4056 18.5293 7.13721 18.7002C6.79369 18.9014 6.36428 19.002 5.99928 19.002C5.22634 19.002 4.48561 18.5997 4.08841 17.956L3.34767 16.7491C2.95047 16.0954 2.929 15.3109 3.3262 14.6874C3.49797 14.3857 3.82002 14.0839 4.18502 13.8929C4.48561 13.752 4.67884 13.5207 4.86134 13.2492C5.39811 12.3843 5.07605 11.2478 4.16355 10.7349C3.10076 10.1616 2.75724 8.88431 3.36914 7.88864L4.08841 6.70188C4.71105 5.7062 6.04222 5.3542 7.11574 5.93752C8.04971 6.42027 9.26279 6.09844 9.81029 5.24357C9.98205 4.96196 10.0787 4.66024 10.0572 4.35852C10.0357 3.96629 10.1538 3.59417 10.3578 3.29245C10.755 2.6689 11.4743 2.26661 12.2579 2.24649H13.7716C14.566 2.24649 15.2853 2.6689 15.6825 3.29245C15.8757 3.59417 16.0045 3.96629 15.9723 4.35852C15.9508 4.66024 16.0475 4.96196 16.2192 5.24357C16.7667 6.09844 17.9798 6.42027 18.9245 5.93752C19.9873 5.3542 21.3292 5.7062 21.9411 6.70188L22.6604 7.88864C23.283 8.88431 22.9395 10.1616 21.866 10.7349C20.9535 11.2478 20.6314 12.3843 21.1789 13.2492C21.3507 13.5207 21.5439 13.752 21.8445 13.8929ZM9.98205 12.3139C9.98205 13.8929 11.3454 15.15 13.0309 15.15C14.7163 15.15 16.0475 13.8929 16.0475 12.3139C16.0475 10.7349 14.7163 9.46764 13.0309 9.46764C11.3454 9.46764 9.98205 10.7349 9.98205 12.3139Z"
      />
    </svg>
  );
}