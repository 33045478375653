import React from "react";
import classNames from "classnames";

export enum ModeType {
  normal = "normal",
  condensed = "condensed",
  wide = "wide",
}

const SideMenu: React.FC<{
  mode: ModeType;
  children: React.ReactNode;
  authPage?: boolean;
}> = ({ mode, children, authPage = false }) => {
  return (
    <div
      id="sideMenu"
      className={classNames(
        "min-h-screen relative",
        {
          "hidden lg:block min-w-10  px-5 py-5 pr-2": mode == ModeType.normal,
        },
        {
          "block w-full lg:w-4/6 xl:w-1/2 2xl:w-35%": mode == ModeType.wide,
        }
      )}
    >
      <div
        className={classNames(
          "min-h-full sidemenu bg-white flex flex-col w-full",
          { condensed: mode == ModeType.condensed },
          { "justify-start": ModeType.normal },
          { "justify-center": ModeType.wide }
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default SideMenu;
