import React, { useEffect, useState } from "react";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Token, UserNotification } from "models/Interfaces";
import { showShortURL } from "../utils";
import { BlockchainType, ScopeNotification } from "models/Enums";
import { useTranslation } from "react-i18next";
import { getBlockchainForAssets } from "models/utils/blockchainTranslations";
import { getNftByCollection } from "models/utils/terra";
import ReactCardFlip from "react-card-flip";
import { CopyButton } from "components/CopyButton";

interface Nft {
  name: string | null;
  image: string | null;
}

const NftMonitorCard: React.FC<{
  scope: string;
  copyToClipboard: Function;
  notification: UserNotification;
  getTypeDesc: Function;
  copy: boolean;
  isFlipped: boolean;
}> = ({
  scope,
  copyToClipboard,
  notification,
  getTypeDesc,
  copy,
  isFlipped,
}) => {
    const { t } = useTranslation(["common", "enumerations", ""]);

    const [collection, setcollection] = useState<null | string>(null);
    const [nft, setNft] = useState<Nft>({ name: null, image: null });
    const [isLoading, setIsLoading] = useState(false);

    const { name, image } = nft;

    let blockchain: string | null;
    let blockchainIcon;

    //TODO we need to make this piece of code dynamic and blockchain agnostic
    switch (notification.chainId) {
      case BlockchainType.Terrav2:
        blockchainIcon =
          "https://assets.hermesprotocol.io/img/projects/Terra.png";
        break;
      case BlockchainType.TerraClassic:
        blockchainIcon =
          "https://assets.hermesprotocol.io/img/projects/TerraClassic.png";
        break;
    }

    useEffect(() => {
      setIsLoading(true);
      blockchain = getBlockchainForAssets(notification.chainId);
      const getCollectionAndNft = async () => {
        try {
          const url = `${process.env.REACT_APP_ASSETS_REPO}${process.env.REACT_APP_ASSETS_CHAINS}${blockchain}/${process.env.REACT_APP_ASSETS_NFT_CONTRACTS}`;
          const connection = await fetch(url, {
            method: "GET",
          });
          const contractsData = await connection.json();
          const contract = contractsData[notification.nft[0]?.collection];
          setcollection(contract?.name);

          let nft;
          switch (blockchain) {
            case "terra":
            case "terraClassic":
              const nfts = await getNftByCollection(
                contract?.contract,
                blockchain === "terra" ? "mainnet" : "classic"
              );
              nft = nfts.find((nft: Token) => {
                return (
                  nft.name === notification.nft[0].name ||
                  nft.tokenId === notification.nft[0].tokenId
                );
              });
              break;
            //TODO need to be addapted for other blockchains
          }
          setNft({
            name:
              nft?.name ||
              notification.nft[0]?.name ||
              notification.nft[0]?.tokenId,
            image:
              nft?.image ||
              contractsData[notification.nft[0]?.collection].icon ||
              null,
          });
        } catch (error) {
          console.log(error);
        }
        setIsLoading(false);
      };
      if (notification.scope === ScopeNotification.unique) {
        getCollectionAndNft();
      } else {
        setIsLoading(false);
      }
    }, [notification]);

    return (
      <div className="flex flex-col justify-between z-10 h-5/8">
        <h4 className="mb-2 h-1/6">
          {getTypeDesc(notification.notificationTypeId.slice(-4))}
        </h4>
        <div className="flex items-center h-1/6">
          {isLoading && <div className="loading-ring gray-dark"></div>}
          {!isLoading && (
            <>
              <span className="text-gray-semibold">
                <FontAwesomeIcon
                  icon={faTags}
                  className="bg-transparent"
                  size="sm"
                  style={{ marginRight: "10px" }}
                />
              </span>
              <h6 className="font-medium">
                {notification.scope === ScopeNotification.unique &&
                  t("notifications.unique_nft", { ns: "common" })}
                {notification.scope === ScopeNotification.myWallet &&
                  t("notifications.my_wallet_nft", { ns: "common" })}
              </h6>
            </>
          )}
        </div>
        <ReactCardFlip
          isFlipped={isFlipped}
          flipDirection="vertical"
          cardStyles={{
            front: { height: "94.35px" },
            back: { height: "94.35px" },
          }}
        >
          <div className="flex items-center justify-between transition h-full">
            <div className="flex items-center w-3/4">
              {isLoading && <div className="loading-ring gray-dark"></div>}
              {!isLoading &&
                notification.scope === ScopeNotification.myWallet && (
                  <div className="text-sm">
                    {showShortURL(notification.walletAddress)}
                  </div>
                )}
              {!isLoading && notification.scope === ScopeNotification.unique && (
                <div>
                  <p className="text-sm">{collection}</p>
                  <ul className="list-disc ml-7">
                    <li className="text-sm">{name}</li>
                  </ul>
                </div>
              )}
              <CopyButton
                hoverText={
                  notification.scope === ScopeNotification.myWallet
                    ? t("notifications.copy_address", "common")
                    : t("notifications.copy_contract", "common")
                }
                clickText={t("notifications.copied", "common")}
                isCopying={copy}
                onCopy={copyToClipboard}
                copiedElement={
                  notification.scope === ScopeNotification.myWallet
                    ? notification?.walletAddress
                    : notification.nft[0]?.collection
                }
              />
            </div>
            {scope === ScopeNotification.unique && isLoading && (
              <div className="loading-ring gray-dark"></div>
            )}
            {scope === ScopeNotification.unique && !isLoading && (
              <img
                src={image ? image : blockchainIcon}
                alt=""
                width={70}
                className="w-2/8 rounded"
              />
            )}
          </div>
          <div className="flex items-center justify-between transition h-full">
            <ul className="list-disc ml-7 text-sm">
              {notification.monitorOptions.map((option) => {
                return (
                  <li key={option}>
                    {t(`nft_monitor_options.${option}`, {
                      ns: "enumerations",
                    })}
                  </li>
                );
              })}
            </ul>
          </div>
        </ReactCardFlip>
      </div>
    );
  };
export default NftMonitorCard;