import "dotenv/config";

import { APIService } from "./api.service";

export class NotificationsService extends APIService {
  constructor() {
    super();
  }

  public async getInfo(): Promise<any> {
    return await this._get(`/fauna/notifications`);
  }

  public async create(data: any): Promise<any> {
    return await this._post(`/fauna/notifications`, data);
  }

  public async update(data: any, id: string): Promise<any> {
    return await this._patch(`/fauna/notifications/${id}`, data);
  }

  public async delete(id: string): Promise<any> {
    return await this._put(`/fauna/notifications/${id}`, undefined);
  }
}
