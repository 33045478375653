import "dotenv/config";

import { APIService } from "./api.service";

export class WalletService extends APIService {
  constructor() {
    super();
  }

  public async delete(wallet: string): Promise<any> {
    return await this._put(
      `/fauna/wallets/${wallet}/${this.origin}`,
      undefined
    );
  }

  public async edit(data: any, wallet: string): Promise<any> {
    return await this._patch(`/fauna/wallets/${wallet}/${this.origin}`, data);
  }
}
