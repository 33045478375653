import React, { createContext, useContext, useState } from "react";
import {
  PlatformType,
  SocialChannel,
  NotificationType,
  CategoryType,
  BlockchainType,
} from "../../models/Enums";
import { useNotifications } from "../notifications";
import Notification, { AlertConfiguration } from "models/Notification";
import { useContextUser } from "contexts/user";

/**
 * IEditNotificationFlowState defines the Edit Notification flow state values and methods to interact with this state. It's responsible to activate the popup flow using isActive prop.
 */
export interface IEditNotificationFlowState {
  isActive: boolean;
  status: string;
  notificationId: string;
  selectedCategoryType: CategoryType | undefined;
  selectedPlatformType: PlatformType | undefined;
  selectedNotificationType: NotificationType | undefined;
  selectedNotificationBlockchain: BlockchainType | undefined;
  selectedNotificationConfiguration: AlertConfiguration | undefined;
  selectedNotificationSocial: SocialChannel[] | undefined;
  editingNotification: boolean;
  startRemoveNotification: boolean;
  removingNotification: boolean;
  /**
   * Start Notification Edit Flow loading the notification values.
   */
  beginEditNotification(notification: Notification): void;
  /**
   * Cancels Edit Notification Flow and close the popup
   */
  cancelEditNotification(): void;
  /**
   * Removes/Delete Notification
   */
  startRemovingNotification(): void;
  removeNotification(): void;
  /**
   * Saves Notification Edit
   */
  saveEditNotification(
    channels: SocialChannel[],
    notificationConfiguration: AlertConfiguration
  ): Promise<void>;
}

/**
 * Edit Notification Context
 */
const EditNotificationFlowContext = createContext<IEditNotificationFlowState>({
  isActive: false,
  status: "1",
  notificationId: "",
  selectedCategoryType: undefined,
  selectedPlatformType: undefined,
  selectedNotificationType: undefined,
  selectedNotificationBlockchain: undefined,
  selectedNotificationConfiguration: undefined,
  selectedNotificationSocial: undefined,
  editingNotification: false,
  startRemoveNotification: false,
  removingNotification: false,
  beginEditNotification: () => {
    throw "Requesting component not nested inside EditNotificationFlowContext.";
  },
  cancelEditNotification: () => {
    throw "Requesting component not nested inside EditNotificationFlowContext.";
  },
  startRemovingNotification: () => {
    throw "Requesting component not nested inside EditNotificationFlowContext.";
  },
  removeNotification: () => {
    throw "Requesting component not nested inside EditNotificationFlowContext.";
  },
  saveEditNotification: () => {
    throw "Requesting component not nested inside EditNotificationFlowContext.";
  },
});

export const useEditNotificationFlow = () =>
  useContext(EditNotificationFlowContext);

export const EditNotificationFlowContextProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { userData } = useContextUser();
  const [flowState, setFlowState] = useState<{
    isActive: boolean;
    status: string;
    notificationId: string;
    selectedCategoryType: CategoryType | undefined;
    selectedPlatformType: PlatformType | undefined;
    selectedNotificationType: NotificationType | undefined;
    selectedNotificationConfiguration: AlertConfiguration | undefined;
    selectedNotificationBlockchain: BlockchainType | undefined;
    selectedNotificationSocial: SocialChannel[] | undefined;
    editingNotification: boolean;
    startRemoveNotification: boolean;
    removingNotification: boolean;
  }>({
    isActive: false,
    status: "1",
    notificationId: "",
    selectedCategoryType: undefined,
    selectedPlatformType: undefined,
    selectedNotificationType: undefined,
    selectedNotificationBlockchain: undefined,
    selectedNotificationConfiguration: undefined,
    selectedNotificationSocial: undefined,
    editingNotification: false,
    startRemoveNotification: false,
    removingNotification: false,
  });
  const notifications = useNotifications();

  const beginEditNotification = async (notification: Notification) => {
    setFlowState({
      isActive: true,
      status: "1",
      notificationId: notification.notificationId,
      selectedCategoryType: notification.categoryId,
      selectedPlatformType: notification.projectId,
      selectedNotificationType: notification.notificationTypeId,
      selectedNotificationConfiguration: notification.alertConfiguration,
      selectedNotificationSocial: notification.socialChannels,
      selectedNotificationBlockchain: notification.chainId,
      editingNotification: false,
      startRemoveNotification: false,
      removingNotification: false,
    });
  };
  const startRemovingNotification = () => {
    setFlowState({
      ...flowState,
      startRemoveNotification: true,
      removingNotification: false,
    });
  };
  const cancelEditNotification = () => {
    setFlowState({
      isActive: false,
      status: "1",
      notificationId: "",
      selectedCategoryType: undefined,
      selectedPlatformType: undefined,
      selectedNotificationType: undefined,
      selectedNotificationConfiguration: undefined,
      selectedNotificationBlockchain: undefined,
      selectedNotificationSocial: undefined,
      editingNotification: false,
      startRemoveNotification: false,
      removingNotification: false,
    });
  };
  const removeNotification = async () => {
    setFlowState({
      ...flowState,
      removingNotification: true,
    });
    await notifications.removeNotification(flowState.notificationId);
    setFlowState({
      isActive: false,
      status: "0",
      notificationId: "",
      selectedNotificationBlockchain: undefined,
      selectedCategoryType: undefined,
      selectedPlatformType: undefined,
      selectedNotificationType: undefined,
      selectedNotificationConfiguration: undefined,
      selectedNotificationSocial: undefined,
      editingNotification: false,
      startRemoveNotification: false,
      removingNotification: false,
    });
  };
  const saveEditNotification = async (
    channels: SocialChannel[],
    notificationConfiguration: AlertConfiguration
  ) => {
    if (
      flowState.selectedCategoryType === undefined ||
      flowState.selectedNotificationType === undefined ||
      flowState.selectedPlatformType === undefined ||
      flowState.selectedNotificationBlockchain === undefined
    ) {
      throw "Platform and notification type not selected.";
    }
    setFlowState({
      ...flowState,
      editingNotification: true,
    });

    setFlowState({
      isActive: false,
      status: "1",
      notificationId: "",
      selectedCategoryType: undefined,
      selectedPlatformType: undefined,
      selectedNotificationType: undefined,
      selectedNotificationConfiguration: undefined,
      selectedNotificationSocial: undefined,
      selectedNotificationBlockchain: undefined,
      editingNotification: false,
      startRemoveNotification: false,
      removingNotification: false,
    });
  };

  return (
    <EditNotificationFlowContext.Provider
      value={{
        isActive: flowState.isActive,
        status: flowState.status,
        notificationId: flowState.notificationId,
        selectedCategoryType: flowState.selectedCategoryType,
        selectedPlatformType: flowState.selectedPlatformType,
        selectedNotificationType: flowState.selectedNotificationType,
        selectedNotificationBlockchain:
          flowState.selectedNotificationBlockchain,
        selectedNotificationConfiguration:
          flowState.selectedNotificationConfiguration,
        selectedNotificationSocial: flowState.selectedNotificationSocial,
        editingNotification: flowState.editingNotification,
        startRemoveNotification: flowState.startRemoveNotification,
        removingNotification: flowState.removingNotification,
        beginEditNotification: beginEditNotification,
        cancelEditNotification: cancelEditNotification,
        startRemovingNotification: startRemovingNotification,
        removeNotification: removeNotification,
        saveEditNotification: saveEditNotification,
      }}
    >
      {children}
    </EditNotificationFlowContext.Provider>
  );
};
