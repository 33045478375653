import "dotenv/config";

import { APIService } from "./api.service";

export class HistoryService extends APIService {
  constructor() {
    super();
  }

  public async get(): Promise<any> {
    return await this._get(`/fauna/history`);
  }
}
