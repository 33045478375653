export const HistorySvg = () => {
    return (
      <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0C16.2996 0 21 4.70039 21 10.5C21 16.2996 16.2996 21 10.5 21C8.27285 21 6.20156 20.3027 4.49941 19.1174C3.90469 18.6662 3.75867 17.8828 4.17539 17.2881C4.58965 16.6934 5.40586 16.5498 6.00059 16.9641C7.27617 17.8541 8.82656 18.375 10.5 18.375C14.8477 18.375 18.375 14.8477 18.375 10.5C18.375 6.11543 14.8477 2.625 10.5 2.625C8.28926 2.625 6.35742 3.5052 4.93008 4.93008L6.19336 6.19336C6.8127 6.8127 6.37383 7.875 5.5002 7.875H0.984375C0.440918 7.875 0 7.43613 0 6.89062V2.37645C0 1.49953 1.06025 1.06025 1.68041 1.68041L3.07535 3.07535C4.9752 1.17674 7.6002 0 10.4631 0H10.5ZM10.5 5.25C11.0455 5.25 11.4844 5.68887 11.4844 6.23438V10.0939L14.1135 12.7559C14.5318 13.1414 14.5318 13.7648 14.1135 14.1135C13.7648 14.5318 13.1414 14.5318 12.7559 14.1135L9.80273 11.1604C9.61816 11.0127 9.51562 10.7625 9.51562 10.5V6.23438C9.51562 5.68887 9.95449 5.25 10.5 5.25Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
    );
}