import { Connection, Installation } from "@terra-dev/wallet-types";
import { useWallet } from "@terra-money/wallet-provider";
import classNames from "classnames";
import Button, { ButtonSize, ButtonType } from "components/Button";
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

export const ConnectReadonlyButton: React.FC<{
  type: Installation["type"] | Connection["type"];
  icon: Installation["icon"] | Connection["icon"];
  name: Installation["name"] | Connection["name"];
  walletFlow?: boolean;
  identifier: Installation["identifier"] | Connection["identifier"];
  disabled?: boolean;
  setNewWallet?: Dispatch<SetStateAction<any>>;
}> = ({
  type,
  icon,
  name,
  identifier,
  walletFlow = false,
  setNewWallet,
  disabled,
}) => {
  const { t } = useTranslation(["common", "enumerations"]);
  const { connect, wallets } = useWallet();

  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const connectWallet = async (type: any, identifier: any) => {
    connect(type, identifier);
    setNewWallet && setNewWallet(wallets[0].terraAddress);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <Fragment key={"connection" + type + identifier}>
      <Button
        noFlexMobile={true}
        type={
          name === "XDEFI Wallet"
            ? ButtonType.highlighted
            : ButtonType.secondary
        }
        size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
        fullWidth={width < 1024 ? true : false}
        onClick={() => connectWallet(type, identifier)}
        className={`md:mx-0 ${walletFlow ? "my-3" : "my-6"}`}
        disabled={disabled}
      >
        <img
          src={
            icon ===
            "https://assets.terra.money/icon/station-extension/icon.png"
              ? "https://assets.terra.money/icon/wallet-provider/station.svg"
              : icon
          }
          className={classNames("inline w-6")}
          alt={`${name} Wallet Connect`}
        />
        <span className="pl-2">
          {t(`wallet_types.${name}`, { ns: "common" })}
        </span>
      </Button>
    </Fragment>
  );
};
