import "../i18n.js";
import "./../style.css";

import { AlertHistory, HistoryLine } from "models/Interfaces";
import { BlockchainType, NotificationType } from "models/Enums";
import Button, { ButtonSize, ButtonType } from "components/Button";
import PopUp, { PopupSize } from "components/Popup";
import React, { MouseEventHandler, useState } from "react";
import { Tooltip, TooltipStyle } from "components/Tooltip";
import { faEye, faPlus, faShare } from "@fortawesome/free-solid-svg-icons";

import BlockchainImage from "components/BlockchainImage";
import BottomMenu from "../components/BottomMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Navbar } from "components/Navbar";
import RightSection from "../components/RightSection";
import { api } from "shared";
import classNames from "classnames";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { showShortURL } from "components/notifications/utils";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useContextUser } from "contexts/user";
import { useTranslation } from "react-i18next";

export const enum TooltipScope {
  notification = "notification",
  hash = "hash",
}

export const HistoryPage: React.FC<{}> = () => {
  const { t } = useTranslation(["common", "enumerations"]);
  const { userData } = useContextUser();

  const [isCondensed, setIsCondensed] = useState(false);
  const [isLoadingMoreEntries, setIsLoadingMoreEntries] = useState(false);
  const [olderEntries, setOlderEntries] = useState<Array<HistoryLine> | null>(
    null
  );
  const [isTooltipShown, setIsTooltipShown] = useState<string | null>(null);
  const [historyLine, setHistoryLine] = useState<HistoryLine | null>(null);

  const { historyStatus, historyReasons } = useContextBlockchainData();

  const handleClickMoreInfo = (historyLineAux: HistoryLine) => {
    setHistoryLine(historyLineAux);
  };

  const handleMouseEnter = (tooltipScope: TooltipScope) => {
    setIsTooltipShown(tooltipScope);
  };

  const handleMouseLeave = () => {
    setIsTooltipShown(null);
  };

  const getNotificationTypeDesc = (type: NotificationType) => {
    const name = Object.entries(NotificationType).find(
      ([key, value]): string | undefined => {
        if (value === type) {
          return value;
        }
      }
    )?.[0];
    if (name) {
      return t(`notification_types.${name}`, {
        ns: "enumerations",
      });
    } else {
      return "";
    }
  };

  const getHistoryLineFromHistoryData = (
    historyEntry: AlertHistory
  ): HistoryLine => {
    return {
      notificationId: historyEntry.notification_id,
      blockchain: `zb1_${
        historyEntry.notification_type_id.substring(0, 3) as NotificationType
      }`,
      alertType: getNotificationTypeDesc(
        historyEntry.notification_type_id.substring(4) as NotificationType
      ),
      social: historyEntry.channel,
      date: new Date(historyEntry.alert_date).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }),
      dateWithTime: new Date(historyEntry.alert_date).toLocaleDateString(
        "en-GB",
        {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      ),
      status: historyStatus?.find(
        (status) => status.status_id === historyEntry.status
      )?.status_desc,
      reason: historyReasons?.find(
        (reason) => reason.reason_id === historyEntry.reason
      )?.reason_desc,
      hash: historyEntry.blockchain_info.txHash,
    };
  };

  const loadMoreHistoryEntries = async () => {
    if (!userData?.userId) return;

    setIsLoadingMoreEntries(true);

    const response = await api.history.get();

    if (response) {
      const entries = (response as Array<AlertHistory>)
        .sort((a, b) => b.alert_date - a.alert_date)
        .map((entry) => getHistoryLineFromHistoryData(entry));

      setOlderEntries((e) => e?.concat(entries) ?? entries);
    }

    setIsLoadingMoreEntries(false);
  };

  return (
    <>
      <Navbar setIsCondensed={setIsCondensed} isCondensed={isCondensed} />
      <RightSection isCondensed={isCondensed}>
        <div className="relative md:w-full flex items-center md:px-2 lg:px-6 pb-8 max-w-6xl">
          <div className="px-2 md:px-0 center flex-col w-full">
            <section className="flex flex-col w-full">
              <h1 className="mt-2 w-full justify-between mb-3 ml-4">
                {t("menu.history", { ns: "common" })}
              </h1>
              {userData?.alertHistory && (
                <>
                  <table className="table-auto text-left border-collapse border-8 border-gray-extralight15 text-gray-light50 mt-8 mb-10">
                    <thead className="border-8 border-gray-extralight15">
                      <tr className="border-8 border-gray-extralight15">
                        <th className="bg-white px-2 lg:px-6"></th>
                        <th className="bg-white py-3 pr-6 text-left hidden lg:table-cell">
                          {t("history.blockchain", { ns: "common" })}
                        </th>
                        <th className="bg-white py-3 px-2 lg:px-6 hidden lg:table-cell">
                          {t("history.alert_date", { ns: "common" })}
                        </th>
                        <th className="bg-white py-3 px-2 lg:px-6">
                          <span className="hidden lg:inline">
                            {t("history.type_of", { ns: "common" })}{" "}
                          </span>
                          {t("history.alert", { ns: "common" })}
                        </th>
                        <th className="bg-white py-3 px-2 lg:px-6 text-center">
                          {t("history.social", { ns: "common" })}
                        </th>
                        <th className="bg-white py-3 px-2 lg:px-6 text-center">
                          {t("history.status", { ns: "common" })}
                        </th>
                        <th className="bg-white py-3 px-2 lg:px-6 text-center">
                          {t("history.info", { ns: "common" })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!olderEntries &&
                        userData?.alertHistory?.map((historyEntry) => {
                          const historyLineAux =
                            getHistoryLineFromHistoryData(historyEntry);

                          return (
                            <HistoryTableRow
                              rowData={historyLineAux}
                              onMoreInfoClick={() =>
                                handleClickMoreInfo(historyLineAux)
                              }
                            />
                          );
                        })}
                      {olderEntries?.map((entry) => (
                        <HistoryTableRow
                          rowData={entry}
                          onMoreInfoClick={() => handleClickMoreInfo(entry)}
                        />
                      ))}
                    </tbody>
                  </table>
                  {!olderEntries && (
                    <div className="flex justify-center">
                      <Button
                        type={ButtonType.primary}
                        size={ButtonSize.smallWide}
                        loading={isLoadingMoreEntries}
                        disabled={isLoadingMoreEntries}
                        onClick={loadMoreHistoryEntries}
                        className="px-8"
                      >
                        {t("history.load_more", { ns: "common" })}
                      </Button>
                    </div>
                  )}
                </>
              )}
              {!userData?.alertHistory && (
                <div className="mt-2 w-full mb-3 ml-4">
                  {t("history.no_history", { ns: "common" })}
                  <Link to={"/notifications"} className="ml-1 underline">
                    {t("history.notifications", { ns: "common" })}
                  </Link>
                  .
                </div>
              )}
            </section>
          </div>
        </div>
      </RightSection>
      <BottomMenu />
      {historyLine && (
        <PopUp size={PopupSize.small} closeModal={() => setHistoryLine(null)}>
          <div className="px-6 w-full">
            <div className="flex justify-between items-center pb-4 border-b border-black-line">
              <div className="flex justify-start items-center">
                <span className="h-7 w-7 mr-4">
                  <BlockchainImage
                    blockchainId={historyLine.blockchain as BlockchainType}
                    showName={false}
                  />
                </span>
                <p className="text-lg font-bold">{historyLine.alertType}</p>
              </div>
              <div className="relative">
                <Link
                  to={`/notifications/?edit=${historyLine.notificationId}`}
                  onMouseEnter={(e) =>
                    handleMouseEnter(TooltipScope.notification)
                  }
                  onMouseLeave={(e) => handleMouseLeave()}
                  className="bg-gray-extralight30 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer ml-4"
                >
                  <FontAwesomeIcon
                    size="sm"
                    icon={faEye}
                    className={"bg-transparent text-gray-medium80"}
                  />
                </Link>
                <div className="absolute mt-2 left-1/2 translate-x-50p">
                  <div
                    className="rounded bg-gray-extralight90 text-white text-xs text-center px-1 py-0.5"
                    style={{
                      display:
                        isTooltipShown === TooltipScope.notification
                          ? "block"
                          : "none",
                    }}
                  >
                    {t("history.notification_configuration", { ns: "common" })}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div>
                <span className="font-medium mr-2">
                  {t("history.alert_date", { ns: "common" })}:
                </span>
                <span className="text-sm">{historyLine.dateWithTime}</span>
              </div>
              <div>
                <span className="font-medium mr-2">
                  {t("history.status", { ns: "common" })}:
                </span>
                <span
                  className={classNames("text-sm", {
                    "text-red": historyLine.status === "failure",
                  })}
                >
                  {t(`alert_status.${historyLine.status}`, {
                    ns: "enumerations",
                  })}
                </span>
              </div>
              {historyLine.reason && (
                <div>
                  <span className="font-medium mr-2">
                    {t("history.fail_reason", { ns: "common" })}:
                  </span>
                  <span className="text-sm">{historyLine.reason}</span>
                </div>
              )}
              {historyLine.hash && (
                <div className="flex items-center">
                  <span className="font-medium mr-2">
                    {t("history.hash", { ns: "common" })}:
                  </span>
                  <span className="text-sm">
                    {showShortURL(historyLine.hash)}
                  </span>
                  <div className="relative">
                    <a
                      onMouseEnter={(e) => handleMouseEnter(TooltipScope.hash)}
                      onMouseLeave={(e) => handleMouseLeave()}
                      href={`https://terrasco.pe/mainnet/tx/${historyLine.hash}`}
                      target="_blank"
                      className="bg-gray-extralight30 rounded-full w-6 h-6 flex items-center justify-center cursor-pointer ml-4 mr-4"
                    >
                      <FontAwesomeIcon
                        size="xs"
                        icon={faShare}
                        className={"bg-transparent text-gray-medium80"}
                      />
                    </a>
                    <div className="absolute mt-2 left-1/2 translate-x-50p">
                      <div
                        className="rounded bg-gray-extralight90 text-white text-xs text-center px-1"
                        style={{
                          display:
                            isTooltipShown === TooltipScope.hash
                              ? "block"
                              : "none",
                        }}
                      >
                        {t("history.open_transaction", { ns: "common" })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="text-sm font-semibold py-1 pt-6">
                <div>{t("social_channel", { ns: "common" })}</div>
                <div className="mt-1">
                  {historyLine?.social === "ze_0001" && (
                    <FontAwesomeIcon
                      className="text-discord"
                      icon={faDiscord}
                      size="lg"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </PopUp>
      )}
    </>
  );
};

const HistoryTableRow: React.FC<{
  rowData: HistoryLine;
  onMoreInfoClick: MouseEventHandler;
}> = ({ rowData, onMoreInfoClick }) => {
  const { t } = useTranslation(["common", "enumerations"]);

  return (
    <tr className="border-2 border-gray-extralight15">
      <td className="bg-white pl-2 pr-2 lg:pl-6 lg:pr-3">
        <div className="flex">
          <span className="h-6 w-6">
            <BlockchainImage
              blockchainId={rowData.blockchain as BlockchainType}
              showName={false}
            />
          </span>
        </div>
      </td>
      <td className="bg-white py-2 pr-2 lg:pr-6 text-left hidden lg:table-cell">
        {t(`blockchains_types.${rowData.blockchain}`, { ns: "enumerations" })}
      </td>
      <td className="bg-white py-2 px-2 lg:px-6 hidden lg:table-cell">
        <Tooltip
          content={rowData.dateWithTime}
          tooltipStyle={TooltipStyle.dark}
        >
          <span className="cursor-pointer">{rowData.date}</span>
        </Tooltip>
      </td>
      <td className="bg-white py-2 px-2 lg:px-6">{rowData.alertType}</td>
      <td className="bg-white py-2 px-2 lg:px-6 text-center">
        {rowData?.social === "ze_0001" && ( //discord
          <FontAwesomeIcon className="text-discord" icon={faDiscord} />
        )}
      </td>
      <td className="table-cell bg-white py-2 px-2 lg:px-6 text-center flex justify-center">
        {!rowData.status && <div className="loading-ring gray-dark"></div>}
        {rowData.status && (
          <span
            className={classNames({ "text-red": rowData.status === "failure" })}
          >
            {t(`alert_status.${rowData.status}`, { ns: "enumerations" })}
          </span>
        )}
      </td>
      <td className="bg-white py-2 px-2 lg:px-6 text-center">
        <span
          onClick={onMoreInfoClick}
          className="border-2 rounded-full w-6 h-6 flex items-center justify-center block cursor-pointer hover:bg-gray-extralight30 mx-auto transition"
        >
          <FontAwesomeIcon size="xs" icon={faPlus} />
        </span>
      </td>
    </tr>
  );
};
