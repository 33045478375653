import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserNotification } from "models/Interfaces";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { CategoryType } from "models/Enums";
import { isValidTerraWallet } from "models/utils/terra";
import { useContextBlockchainData } from "contexts/blockchain-data";

const GovernancePoll = ({
  setValues,
  disabledBtn,
  notification,
}: {
  setValues: Dispatch<SetStateAction<any>>;
  disabledBtn?: Dispatch<SetStateAction<any>>;
  notification?: UserNotification;
}) => {
  const { t } = useTranslation(["enumerations"]);
  const { selectedCategoryType, selectedPlatformType } =
    useNewNotificationFlow();
  const { projects } = useContextBlockchainData();

  const [walletInput, setWalletInput] = useState(
    notification ? notification.walletAddress : ""
  );

  selectedCategoryType !== CategoryType.DAO &&
    disabledBtn &&
    disabledBtn(false);

  /**
   * Verify is the address given is a valid wallet address
   * @param text wallet address
   * @returns boolean true or false
   */
  const checkWallet = (text: string) => {
    if (!isValidTerraWallet(text)) {
      text !== "" &&
        document.querySelector(".inputError")?.classList.remove("hidden");
      disabledBtn && disabledBtn(true);
      return false;
    } else {
      setValues({ walletAddress: walletInput, mention: "" });
      disabledBtn && disabledBtn(false);
      return true;
    }
  };

  /**
   * Handle Wallet address input changed
   * @param e onChange event
   */
  const handleWalletChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWalletInput(e?.target.value);
    document.querySelector(".inputError")?.classList.add("hidden"); // reset input field on writing
    checkWallet(e?.target.value);
  };

  useEffect(() => {
    const categoryId = selectedCategoryType ? selectedCategoryType : notification?.categoryId;
    const platformId = selectedPlatformType ? selectedPlatformType : notification?.projectId;
    if (categoryId !== CategoryType.DAO) {
      let govAddress = projects?.filter(
        (p) => p.project_market_id === platformId
      )[0].gov_address;
      setValues({ walletAddress: govAddress, mention: "" });
    }
  }, []);

  return (
    <div className="w-full">
      {!notification && (
        <div className="pb-4 border-b border-black-line w-full ">
          <h3 className="mt-6 font-semibold">
            {t("notifications.governance_poll", { ns: "common" })}
          </h3>
        </div>
      )}
      {selectedCategoryType === CategoryType.DAO && (
        <div className="w-full mt-8">
          <p className="px-1">
            {t(`notifications.dao_address`, { ns: "common" })}
          </p>
          <input
            value={walletInput}
            onBlur={() => checkWallet(walletInput)}
            name="wallet"
            onChange={handleWalletChanged}
            type="text"
            className={`focus:ring-0 focus:border-gray-extralight90medium focus:outline-none rounded w-full py-2 text-base 2xl:h-10 h-9 px-3 border-gray-extralight90medium text-black-transparent50`}
          />
          <small className="px-1 inputError hidden text-red">
            {t("notifications.invalid_wallet", { ns: "common" })}
          </small>
        </div>
      )}
    </div>
  );
};

export default GovernancePoll;
