import {
  SignBytesFailed,
  SignBytesResult,
  Timeout,
  useConnectedWallet,
  UserDenied,
} from "@terra-money/wallet-provider";
import classNames from "classnames";
import { ConnectWallet } from "components/auth/ConnectWallet";
import Button, { ButtonSize, ButtonType } from "components/Button";
import { useContextUser } from "contexts/user";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { setTimeout } from "timers";
import { instanceOfUserData } from "utils/instanceOf";
import { ModalState } from "views/SignIn";
import { Warning } from "./Warning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faWallet } from "@fortawesome/free-solid-svg-icons";
import { useZ3usWallet } from "utils/hooks/use-z3us-wallet";
import { useXidarWallet } from "utils/hooks/use-xidar-wallet";
import { BlockchainType } from "models/Enums";

export enum WalletSettingsState {
  ongoing = "ongoing",
  connectWallet = "connectWallet",
  walletVerified = "walletVerified",
  hasWallet = "hasWallet",
  verifyFailure = "verifyFailure",
}

export const WalletsFlow: React.FC<{
  closeModalMain?: Dispatch<SetStateAction<any>>;
}> = ({ closeModalMain }) => {
  const { t } = useTranslation(["common"]);
  const connectedWallet = useConnectedWallet();
  const { userData, setUser } = useContextUser();
  const { signZ3us } = useZ3usWallet();
  const { signXidar } = useXidarWallet();

  const [width, setWidth] = useState(window.innerWidth);
  const [modalContent, setModalContent] = useState<
    ModalState | WalletSettingsState
  >(WalletSettingsState.ongoing);
  const [newWallet, setNewWallet] = useState<null | string>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [randomBytes, setRandomBytes] = useState("");
  const [txError, setTxError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [z3usWallet, setZ3usWallet] = useState<string | null>(null);
  const [xidarWallet, setXidarWallet] = useState<string | null>(null);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const connectWallet = async () => {
    setModalContent(WalletSettingsState.connectWallet);
    setIsOpenModal(true);
  };

  const handleModal = (content: any) => {
    setIsOpenModal && setIsOpenModal(true);
    setModalContent(content);
  };

  const hasWallet = (newWallet: string): boolean => {
    const res = userData?.wallets.filter((wallet) => {
      return wallet.walletAddress === newWallet;
    });
    return (res && res.length > 0) || false;
  };

  const handleVerification = async () => {
    let wallet;
    if (connectedWallet && !hasWallet(connectedWallet?.terraAddress)) {
      wallet = connectedWallet?.terraAddress;
    } else if (z3usWallet && !hasWallet(z3usWallet)) {
      wallet = z3usWallet;
    }
    if (wallet) {
      /* Upon intention to verify wallet, ask the API for bytes for that wallet */
      try {
        const connection = await fetch(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/auth/bytes`,
          {
            method: "POST",
            body: JSON.stringify({
              walletAddress: wallet,
            }),
            credentials: "include",
          }
        );
        const dataFromApi = await connection.json();
        setRandomBytes(dataFromApi.data.bytes);
        if (wallet === connectedWallet?.terraAddress) {
          await signBytes(dataFromApi.data.bytes);
        } else if (z3usWallet && wallet === z3usWallet) {
          try {
            const originalBytes = dataFromApi.data.bytes;
            const signedMessage = await signZ3us(originalBytes);
            wallet &&
              handleSignUp(
                wallet,
                signedMessage,
                originalBytes,
                BlockchainType.Radix
              );
          } catch (error) {
            setTxError("Sign failed: " + error);
            handleModal(ModalState.failure);
          }
        }
      } catch (error) {
        setTxError("Error verifying wallet");
        handleModal(ModalState.failure);
      }
    } else {
      setTxError("Error connecting wallet");
      handleModal(ModalState.failure);
    }
  };

  const handleSignUp = async (
    walletAddress: string,
    signBytesResult: any,
    originalBytes: any,
    chainId: BlockchainType
  ) => {
    setIsLoading(true);
    if (!hasWallet(walletAddress)) {
      try {
        const connection = await fetch(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/wallets/webapp`,
          {
            method: "POST",
            body: JSON.stringify({
              userId: userData?.userId,
              walletAddress,
              walletAlias: "",
              chainId,
              signBytesResult,
              originalBytes,
            }),
            credentials: "include",
          }
        );
        const dataFromApi = await connection.json();
        if (
          typeof dataFromApi.data === "object" &&
          instanceOfUserData(dataFromApi.data)
        ) {
          setUser(dataFromApi.data);
          setModalContent(WalletSettingsState.walletVerified);
        } else if (dataFromApi.data === "elementExists") {
          setModalContent(WalletSettingsState.hasWallet);
        } else if (dataFromApi.data.error) {
          setTxError(dataFromApi.data.error);
          handleModal(WalletSettingsState.verifyFailure);
        } else {
          setModalContent(ModalState.failure);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      handleModal(WalletSettingsState.hasWallet);
    }
    setIsLoading(false);
  };

  const signBytes = useCallback(
    async (originalBytes) => {
      if (!connectedWallet) {
        return;
      }
      try {
        const bufferedBytes = Buffer.from(originalBytes);
        /* Sign bytes with bytes fetched from API to this specific wallet */
        const signedBytes: SignBytesResult = await connectedWallet.signBytes(
          bufferedBytes
        );
        /* Send information from signing to API to verify the transaction */
        handleSignUp(
          connectedWallet.terraAddress,
          signedBytes.result,
          originalBytes,
          BlockchainType.Terrav2
        );
      } catch (error) {
        if (error instanceof UserDenied) {
          setTxError("User Denied");
        } else if (error instanceof Timeout) {
          setTxError("Timeout");
        } else if (error instanceof SignBytesFailed) {
          setTxError("Sign Bytes Failed");
        } else {
          setTxError(
            "Unknown Error: " +
              (error instanceof Error ? error.message : String(error))
          );
        }
        handleModal(ModalState.failure);
      }
    },
    [connectedWallet]
  );

  const closeModal = () => {
    setTimeout(() => {
      closeModalMain && closeModalMain(false);
    }, 2000);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <div className="py-2">
      {modalContent === WalletSettingsState.ongoing && (
        <>
          <a
            href={`${process.env.REACT_APP_OAUTH_URL}/?origin=webapp&action=addWallet&userId=${userData?.userId}`}
            className="mt-4"
          >
             <Button
                type={ButtonType.secondary}
                size={
                  width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium
                }
                fullWidth
                className="py-1.5"
              >
                <div className="text-gray-light60">
                  <img src="images/logo_icon.png" alt="Hermes Protocol" className="w-9"/>
                </div>
                <span className="pl-6">
                  {t(`hermesLogin`, { ns: "common" })}
                </span>
              </Button>
          </a>
        </>
      )}
      {modalContent === WalletSettingsState.connectWallet && (
        <ConnectWallet
          setIsOpenModal={setIsOpenModal}
          setModalContent={setModalContent}
          modalContent={modalContent}
          walletFlow={true}
          setNewWallet={setNewWallet}
          newWallet={newWallet}
          setZ3usWallet={setZ3usWallet}
          setXidarWallet={setXidarWallet}
        />
      )}
      {modalContent === ModalState.failure && (
        <>
          <Warning
            title={"Warning"}
            text={t("modal_content.failure.verify_wallet", {
              ns: "enumerations",
            })}
            iconWarning={true}
          />
          <div className="text-right cursor-pointer">
            <p
              className="text-base underline text-gold"
              onClick={() => {
                setIsOpenModal(false);
                connectWallet();
              }}
            >
              {t("modal_content.warning.try_again", {
                ns: "enumerations",
              })}
            </p>
          </div>
        </>
      )}
      {modalContent === WalletSettingsState.hasWallet && (
        <>
          <Warning
            title={"Warning"}
            text={t("modal_content.has_wallet.modal_title", {
              ns: "enumerations",
            })}
            iconWarning={true}
          />

          {closeModal()}
        </>
      )}
      {modalContent === WalletSettingsState.walletVerified && (
        <>
          <Warning
            title={"Congratulations"}
            text={t("modal_content.new_wallet_added", {
              ns: "enumerations",
            })}
            colorIcon={"bg-gold"}
            iconSuccess={true}
          />
          {closeModal()}
        </>
      )}
    </div>
  );
};
