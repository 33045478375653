import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "dotenv/config";
import { ModalState } from "views/SignIn";
import { useContextUser } from "contexts/user";
import Loader, { LoaderType } from "components/Loader";
import { instanceOfUserData } from "./instanceOf";

export const HandleSocials: React.FC<{
  setError: Dispatch<SetStateAction<any>>;
}> = ({ setError }) => {
  const params = useParams();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userData, setUser } = useContextUser();
  const [isLoading, setIsLoading] = useState(false);

  /* Login with socials is being managed in a server folder*/
  /*Function used to read the page, every time it reloads it gets the code and social that we are doing the login  */
  useEffect(() => {
    setIsLoading(true);
    const social = params.social;
    const bot_token = process.env.REACT_APP_BOT_TOKEN;

    /*function that validates the hash received */
    const validateTelegram = async (bot_token: string) => {
      const { createHash, createHmac } = require("crypto");
      const telegramUser = JSON.parse(
        localStorage.getItem("telegramOAuth") || "{}"
      );
      const secret = createHash("sha256").update(bot_token).digest();
      function checkSignature({ hash, ...data }: any) {
        const checkString = Object.keys(data)
          .sort()
          .filter((k) => data[k])
          .map((k) => `${k}=${data[k]}`)
          .join("\n");
        const hmac = createHmac("sha256", secret)
          .update(checkString)
          .digest("hex");
        return hmac === hash;
      }
      return checkSignature(telegramUser);
    };

    /*Telegram receives the info from the bot and it's saving in local storage while validating the hash*/
    if (social) {
      if (social === "telegram") {
        let telegramUser: any = {};

        for (const entry of searchParams.entries()) {
          telegramUser[entry[0]] = entry[1];
        }

        localStorage.setItem("telegramOAuth", JSON.stringify(telegramUser));

        if (bot_token) {
          const origin = localStorage.getItem("origin");
          const telegramData = JSON.parse(
            localStorage.getItem("telegramOAuth") || "{}"
          );

          validateTelegram(bot_token).then(async (data) => {
            if (data) {
              switch (origin) {
                case "off":
                  try {
                    const connection = await fetch(
                      `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/auth/login/webapp`,
                      {
                        method: "POST",
                        body: JSON.stringify({
                          social,
                          socialId: telegramData?.id,
                        }),
                        credentials: "include",
                      }
                    );
                    const dataFromApi = await connection.json();
                    if (
                      typeof dataFromApi.data === "object" &&
                      instanceOfUserData(dataFromApi.data)
                    ) {
                      setUser(dataFromApi.data);
                      navigate("/", {
                        state: {
                          isOpenModal: true,
                          modalContent: ModalState.success,
                        },
                      });
                    } else {
                      navigate("/", {
                        state: {
                          isOpenModal: true,
                          modalContent: ModalState.noSocial,
                        },
                      });
                    }
                  } catch (error) {
                    navigate("/", {
                      state: {
                        isOpenModal: true,
                        modalContent: ModalState.noSocial,
                      },
                    });
                  }
                  break;
                case "on":
                  try {
                    const connection = await fetch(
                      `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/social/${social}/webapp`,
                      {
                        method: "POST",
                        body: JSON.stringify({
                          userId: userData !== null && userData.userId,
                          username:
                            telegramData?.username ?? telegramData?.first_name,
                          socialId: telegramData?.id,
                          hash: telegramData?.hash,
                          url: telegramData?.photo_url,
                        }),
                        credentials: "include",
                      }
                    );
                    const dataFromApi = await connection.json();
                    if (
                      typeof dataFromApi.data === "object" &&
                      instanceOfUserData(dataFromApi.data)
                    ) {
                      setUser(dataFromApi.data);
                      navigate("/settings");
                    } else {
                      setError(
                        `An error occured. Please try logging in again. Error Code: ${dataFromApi.status}`
                      );
                    }
                  } catch (error) {}
              }
            } else {
              localStorage.removeItem("telegramOAuth");
              setError("Something went wrong, please try to login again!");
              localStorage.removeItem("origin");
              navigate(origin === "on" ? "/settings" : "/");
            }
          });
        }
      } else {
        const state = searchParams.get("state");
        const state_check = JSON.parse(
          sessionStorage.getItem("state_check") || "{}"
        );

        const origin = localStorage.getItem("origin");

        if (state !== state_check) {
          setError(
            `Something went wrong with the login on ${social}, please try again!`
          );
          localStorage.removeItem("origin");
          navigate(origin === "on" ? "/settings" : "/");
        } else {
          /*Using a post request to gather the data on server, while saving it in local storage depending on the social, navigation to the dashboard when it finishes*/
          const code = searchParams.get("code");

          let body = {
            code: code,
            codeVerifier: "",
          };

          if (social === "twitter" && state) {
            body.codeVerifier = JSON.parse(
              sessionStorage.getItem("twitter_code_verifier") || "{}"
            );
          }

          const connectToSocial = async () => {
            try {
              const connection = await fetch(
                `${process.env.REACT_APP_MIDDLEWARE_URL}/oauth/${social}`,
                {
                  method: "POST",
                  body: JSON.stringify(body),
                }
              );

              const dataFromApi = await connection.json();

              if (connection.status === 200 && dataFromApi.data.user.id) {
                const data = dataFromApi.data;
                switch (origin) {
                  case "off":
                    try {
                      const connection = await fetch(
                        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/auth/login/webapp`,
                        {
                          method: "POST",
                          body: JSON.stringify({
                            social,
                            socialId: data?.user.id,
                          }),
                          credentials: "include",
                        }
                      );
                      const dataFromApi = await connection.json();
                      if (
                        typeof dataFromApi.data === "object" &&
                        instanceOfUserData(dataFromApi.data)
                      ) {
                        setUser(dataFromApi.data);
                        navigate("/", {
                          state: {
                            isOpenModal: true,
                            modalContent: ModalState.success,
                          },
                        });
                      } else if (dataFromApi.data.error === "User is guest") {
                        navigate("/", {
                          state: {
                            isOpenModal: true,
                            modalContent: ModalState.guestUser,
                            username: data?.user.username,
                          },
                        });
                      } else if (dataFromApi.data.mfaEnabled) {
                        navigate("/", {
                          state: {
                            isOpenModal: true,
                            modalContent: ModalState.mfaValidate,
                            userId: dataFromApi.data.userId,
                          },
                        });
                      } else {
                        navigate("/", {
                          state: {
                            isOpenModal: true,
                            modalContent: ModalState.noSocial,
                          },
                        });
                      }
                    } catch (error) {
                      navigate("/", {
                        state: {
                          isOpenModal: true,
                          modalContent: ModalState.failure,
                        },
                      });
                    }
                    break;
                  case "on":
                    try {
                      const connection = await fetch(
                        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/social/${social}/webapp`,
                        {
                          method: "POST",
                          body: JSON.stringify({
                            userId: userData !== null && userData.userId,
                            socialId: data?.user.id,
                            username: data?.user.username,
                            token: data?.oAuth.access_token,
                            expiration: data?.oAuth.expires_in,
                            refreshToken: data?.oAuth.refresh_token,
                          }),
                          credentials: "include",
                        }
                      );
                      const dataFromApi = await connection.json();
                      if (
                        typeof dataFromApi.data === "object" &&
                        instanceOfUserData(dataFromApi.data)
                      ) {
                        setUser(dataFromApi.data);
                        navigate("/settings");
                      } else if (dataFromApi.data === "elementExists") {
                        setError("elementExists");
                        navigate("/settings");
                      } else {
                        setError(
                          `An error occured. Please try connecting your social account again. Error Code: ${dataFromApi.status}`
                        );
                        navigate("/settings");
                      }
                    } catch (error) {}
                    break;
                }
                localStorage.removeItem("origin");
              } else {
                setError(
                  `An error occured. Please try logging in again. Error Code: ${dataFromApi.status}`
                );
                const origin = localStorage.getItem("origin");
                localStorage.removeItem("origin");
                navigate(origin === "on" ? "/settings" : "/");
              }
            } catch (error) {
              console.error(error);
            }
          };

          connectToSocial();
        }
      }
    }
  }, []);

  useEffect(() => setIsLoading(false), [userData]);

  return <>{isLoading && <Loader type={LoaderType.fullScreen} />}</>;
};
