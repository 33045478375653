import { Balloon, BalloonType } from "components/Balloon";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { LiquidityPoolAlertConfigs, PlatformType } from "models/Enums";
import {
  LiquidityPoolConfigs,
  TokensCW20,
  UserNotification,
} from "models/Interfaces";
import { ConfigurationParams } from "models/Notification";
import {
  formatPresentation,
  returnContractAvailables,
} from "models/utils/terra";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LiquidityPool = ({
  setValues,
  disabledBtn,
  notification,
}: {
  setValues: Dispatch<SetStateAction<any>>;
  disabledBtn?: Dispatch<SetStateAction<any>>;
  notification?: UserNotification;
}) => {
  const { t } = useTranslation(["common"]);
  const { selectedNotificationBlockchain, selectedPlatformType } =
    useNewNotificationFlow();
  const { priceTypes } = useContextBlockchainData();
  const [dataTokens, setDataTokens] = useState<Object[]>([]);
  const [type, setType] = useState<string>(notification!?.type || ">=");
  const [configs, setConfigs] = useState<LiquidityPoolConfigs>({
    ConfigsParameters: {},
    crypto1: "",
    crypto2: "",
  });

  /**
   * Start initial configurations.
   */
  const startConfigs = () => {
    let contractOne: any;
    let key;

    if (notification) {
      const contract = dataTokens.find((contract) => {
        return Object.keys(contract!)?.[0] === notification?.pair!.toString();
      });

      key = Object.keys(contract!)[0];
      contractOne = { ...Object.values(contract!) };
    } else {
      key = Object.keys(dataTokens[0]!);
      contractOne = Object.values(dataTokens[0]!);
    }

    if (contractOne) {
      const {
        token,
        token2,
        contract,
      }: { token: TokensCW20; token2: TokensCW20; contract: TokensCW20 } = {
        token: contractOne?.[0]?.[0],
        token2: contractOne?.[0]?.[1],
        contract: contractOne?.[0]?.[2],
      };
      const numberToken = token?.["denom"] || token?.["token"];
      setConfigs({
        ...configs,
        ConfigsParameters: {
          type: notification! ? notification.type : ">=",
          token: numberToken,
          pair: key,
          relative: notification ? notification?.relative : false,
          contract: Object.values(contract)?.[0],
          withdrawals: notification ? notification.withdrawals : false,
          deposits: notification ? notification.deposits : false,
          value: notification ? notification.value : 0,
        },
        crypto1: token?.["symbol"],
        crypto2: token2?.["symbol"],
      });
    }
  };

  /**
   * Load selectbox contract pair options
   */
  const showOptions = () => {
    dataTokens && configs.crypto1 === "" && startConfigs();
    const options = dataTokens.map((contract: any) => {
      const key = Object.keys(contract)[0];
      const tokens = Object.values(contract);

      return tokens.map((el: any, index: number) => {
        return (
          <option
            key={Math.random() * 0.5 + index}
            value={key}
            data-crypto1-name={el?.[0]?.symbol}
            data-crypto2-name={el?.[1]?.symbol}
            data-crypto1-contract={el?.[0]?.denom || el?.[0]?.token}
            data-crypto2-contract={el?.[1]?.denom || el?.[1]?.token}
          >{`${el?.[0]?.symbol} / ${el?.[1]?.symbol}`}</option>
        );
      });
    });
    return options;
  };
  /**
   * This code repeat in multiples places
   */
  const setConfigsFunction = (valueToAddInConfigs: {}) => {
    setConfigs({
      ...configs,
      ConfigsParameters: {
        ...configs.ConfigsParameters,
        ...valueToAddInConfigs,
      },
    });
  };

  /**
   * Checks the data is in order to API
   * @param configurations
   */
  const checkData = (configurations: ConfigurationParams) => {
    const pair =
      (typeof configurations?.pair! === "string" && [configurations?.pair]) ||
      configurations?.pair;

    if (!configurations?.deposits && !configurations?.withdrawals) {
      disabledBtn && disabledBtn(true);
      return false;
    } else {
      const formatObjeNotification = Object.assign(
        {},
        { ...configs.ConfigsParameters },
        {
          value: configurations?.value,
          relative: configurations?.relative,
          pair: pair,
          type: configurations?.type,
          withdrawals: configurations?.withdrawals || false,
          deposits: configurations?.deposits || false,
          token: !configurations?.relative ? configurations?.token : "",
        }
      );

      setValues({ ...formatObjeNotification });
      disabledBtn && disabledBtn(false);
    }
  };

  /**
   * Handle Contract Pair Selected/Changed
   * @param e onChange event
   */
  const handleContractPairSelected = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = e.target as HTMLSelectElement;
    const optionSelectedData = target.options[e.target.selectedIndex].dataset;

    setConfigs({
      ...configs,
      ConfigsParameters: {
        ...configs.ConfigsParameters,
        pair: [target?.value],
      },
      crypto1: optionSelectedData?.crypto1Name || "",
      crypto2: optionSelectedData?.crypto2Name || "",
    });
    checkData({
      ...configs.ConfigsParameters,
      pair: [target?.value],
    });
  };
  /**
   * Handle Alert Type Changed (>=)
   * @param e onChange event
   */
  const handleAlertTypeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e?.target?.value !== "") {
      setConfigsFunction({ type: e?.target.value });
      setType(e?.target.value);
    }
    checkData({ ...configs.ConfigsParameters, type: e?.target.value });
  };
  /**
   * Handle Alert Configurations checked
   * @param event onChange event
   */
  const handleLiquidityPoolAlertConfigs = (event: React.ChangeEvent<any>) => {
    const { name, checked } = {
      name: event?.target.name,
      checked: event?.target.checked,
    };

    setConfigsFunction({ [name]: checked });
    checkData({ ...configs.ConfigsParameters, [name]: checked });
  };

  /**
   * Handle Price inserted by user
   * @param event
   */
  const handleLiquidityPriceChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfigsFunction({ value: Number(event?.target.value) });
    checkData({
      ...configs.ConfigsParameters,
      value: Number(event?.target.value),
    });
  };
  /**
   * Handle if the token is absolute or relative price
   * @param e
   */
  const handlePositionValueChanged = (e: React.ChangeEvent<any>) => {
    setConfigsFunction({ relative: e?.target.checked });
    checkData({ ...configs.ConfigsParameters, relative: e.target.checked });
  };
  /**
   * Handle the token choosed in case of absolute price
   * @param event
   */
  const handleTokenChoosed = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setConfigsFunction({ token: event.target.value });
    checkData({ ...configs.ConfigsParameters, token: event.target.value });
  };

  useEffect(() => {
    const dex = Object.entries(PlatformType).filter(
      ([nameProject, idProject]) =>
        selectedPlatformType === idProject ||
        notification?.projectId === idProject
    )[0];

    /* Get contract pairs from assets on github*/
    (async () =>
      (selectedNotificationBlockchain || notification) &&
      (await formatPresentation(
        selectedNotificationBlockchain || notification?.chainId || "",
        dex[0] || notification?.projectId
      )))().then((data) => {
      data && setDataTokens(returnContractAvailables(data));
    });
  }, []);

  return (
    <div>
      {!notification && (
        <div className="pb-4 border-b border-black-line w-full ">
          <h3>{t("notifications.liquidity_pool", { ns: "common" })}</h3>
        </div>
      )}
      <div className="mt-6">
        <div className="flex align-center w-full">
          <div className="w-full">
            <select
              multiple={false}
              value={configs.ConfigsParameters.pair}
              name="pair"
              onChange={handleContractPairSelected}
              className="focus:ring-0 focus:outline-none text-gray-darksm customScrollbar"
            >
              {dataTokens && dataTokens.length > 0 && showOptions()}
            </select>
          </div>
        </div>
      </div>
      <div className="my-5 alertConfigsContainer">
        <p className="font-medium">
          {t(`alert_configuration`, { ns: "common" })}
        </p>
        <div className="text-sm my-7 flex justify-center space-x-14 sm:space-x-28">
          {Object.values(LiquidityPoolAlertConfigs).map(
            (type: string, index: number) => (
              <label
                key={Math.random() * 0.5 + index}
                htmlFor={type}
                className="cursor-pointer"
              >
                <input
                  className="mx-2 focus:outline-none focus:ring-0 text-gold border-gold checked:border-0 focus:ring-transparent cursor-pointer transition-colors "
                  type="checkbox"
                  name={type}
                  id={type}
                  checked={
                    type === LiquidityPoolAlertConfigs.deposits
                      ? configs.ConfigsParameters.deposits
                      : configs.ConfigsParameters.withdrawals
                  }
                  onChange={handleLiquidityPoolAlertConfigs}
                />
                {t(`notifications.${type}`)}
              </label>
            )
          )}
        </div>
      </div>
      <div className="border border-gray-extralight80 rounded-8px">
        <div className="sm:p-2 py-2 text-sm text-gray-dark bg-gray-extralight20 text-center rounded-t-8px border-b border-gray-extralight80">
          <div className="flex items-center justify-evenly ">
            <div className="flex items-center justify-center w-2/6">
              <span className="ml-2 mr-2">{t("notifications.absolute")}</span>
            </div>
            <div className="w-2/6">
              <label className="switch">
                <input
                  checked={configs.ConfigsParameters.relative}
                  type="checkbox"
                  onChange={handlePositionValueChanged}
                />
                <span className={`slider alwaysActive round `}></span>
              </label>
            </div>
            <div className="flex items-center justify-center w-2/6">
              <span className="ml-2 mr-2">
                <span className="ml-2 mr-2">{t("notifications.relative")}</span>
              </span>
            </div>
          </div>
        </div>
        {/* Alert type and token */}
        <div className="flex space-x-3 sm:p-4 p-2">
          <div className="text-center m-auto text-sm sm:w-8/12 w-1/2">
            <div className="mb-2">
              {t(`alert_configuration_settings.alert_type`, {
                ns: "common",
              })}
            </div>
            <select
              multiple={false}
              className="focus:ring-0 py-0 selectTypes text-gray-darksm focus:outline-none customScrollbar"
              value={type}
              name="type"
              onChange={handleAlertTypeChanged}
            >
              {priceTypes?.map((tp, index: number) => {
                return (
                  <option key={Math.random() * 0.5 + index} value={tp.type}>
                    {t(`alert_configuration_settings.${tp.type}`, {
                      ns: "common",
                    })}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="justify-end flex items-center self-end sm:w-3/12">
            <input
              value={configs.ConfigsParameters.value || 0}
              min={0.0}
              onChange={handleLiquidityPriceChanged}
              className="small rounded text-center w-14 sm:w-24 py-0 height-34 font-medium text-gray-darksm m-auto border-gray-light40"
              type="number"
              step={0.1}
            />
            {configs.ConfigsParameters.relative && (
              <div className="sm:px-2">
                <span className="text-xl text-gray-dark relative -right-1.5 ">
                  %
                </span>
              </div>
            )}
          </div>
          <div className="text-center sm:w-4/12 w-2/2">
            <div
              className={`text-sm mb-2 ${
                configs.ConfigsParameters.relative && "opacity-50"
              }`}
            >
              {t(`notifications.token`, {
                ns: "common",
              })}
            </div>
            <select
              multiple={false}
              className="focus:ring-0 selectTypes text-gray-darksm py-0 focus:outline-none customScrollbar"
              value={configs.ConfigsParameters.token}
              onChange={handleTokenChoosed}
              disabled={configs.ConfigsParameters.relative}
            >
              {!configs.ConfigsParameters.relative ? (
                <>
                  <option>{configs?.crypto1 || ""}</option>
                  <option>{configs?.crypto2 || ""}</option>
                </>
              ) : (
                <option> ALL </option>
              )}
            </select>
          </div>
        </div>
      </div>
      <div className="my-3 w-full">
        <div className="mt-6 py-4 px-2 shadow-sm bg-gold10 rounded flex ">
          <div className="flex grow-0 text-white relative items-start justify-start -ml-2 mr-2">
            <Balloon
              disabled={true}
              type={BalloonType.info}
              id="connect-info"
              noFlex={true}
              noEffectHover={true}
            >
              <span>!</span>
            </Balloon>
          </div>
          <div className="text-sm">
            <div className="mt-1 mb-2">
              {t("notifications.relative_and_absolute_info.title", {
                ns: "common",
              })}
            </div>
            <div className="ml-1">
              <p>
                {t("notifications.relative_and_absolute_info.absolute", {
                  ns: "common",
                })}
              </p>
              <p>
                {t("notifications.relative_and_absolute_info.relative", {
                  ns: "common",
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiquidityPool;
