import React, { useEffect, useState } from "react";
import Button, { ButtonType, ButtonSize } from "components/Button";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import twitter from "../../assets/images/twitter.svg";
import discord from "../../assets/images/discord_white.svg";
import telegram from "../../assets/images/telegram.svg";
import { getSocialAuthorizeUrl } from "utils/handleLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faWallet } from "@fortawesome/free-solid-svg-icons";
import TelegramLoginButton, { TelegramUser } from "./TelegramLoginButton";
import { useNavigate } from "react-router-dom";

export enum TooltipContent {
  connect = "connect-info",
  verify = "verify-info",
}

const LoginSectionButtons: React.FC<{
  onConnectWalletClick: React.MouseEventHandler;
  walletStatus: string;
}> = ({ onConnectWalletClick, walletStatus }) => {
  const { t } = useTranslation(["common", "enumerations"]);
  const navigate = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    localStorage.setItem("origin", "off");
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <div className="flex flex-row flex-nowrap	justify-center md:mt-3">
      <div className="flex flex-col relative">
        <>
          <div className="my-2 w-full mb-6 md:mb-8 mt-3">
            <a href={`${process.env.REACT_APP_OAUTH_URL}/?origin=webapp`}>
              <Button
                type={ButtonType.secondary}
                size={
                  width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium
                }
                fullWidth
                className="py-1.5"
              >
                <div className="text-gray-light60">
                  <img src="images/logo_icon.png" alt="Hermes Protocol" className="w-9" />
                </div>
                <span className="pl-6">
                  {t(`hermesLogin`, { ns: "common" })}
                </span>
              </Button>
            </a>
          </div>
        </>
      </div>
    </div>
  );
};
export default LoginSectionButtons;
