import "dotenv/config";

import { APIService } from "./api.service";

export class ContactListService extends APIService {
  constructor() {
    super();
  }

  public async get(id: string): Promise<any> {
    return await this._get(`/fauna/business/contact-list/${id}`);
  }
}
