import { useEffect, useState } from "react";

type XIDAR = {
  v1: {
    hasWallet: () => Promise<boolean>;
    isConnected: () => Promise<boolean>;
    connect: () => Promise<string>;
    disconnect: () => Promise<void>;
    sign: (challenge: string) => Promise<string>;
    submitTransaction: (payload: { transaction: any }) => Promise<any>;
    encrypt: (
      message: string,
      fromAddress: string,
      toAddress: string
    ) => Promise<string>;
    decrypt: (message: string, fromAddress: string) => Promise<string>;
    accounts: () => Promise<string[]>;
    balances: () => Promise<unknown>;
    stakes: () => Promise<unknown>;
    unstakes: () => Promise<unknown>;
  };
};

declare global {
  interface Window {
    xidar: XIDAR;
  }
}

export const useXidarWallet = () => {
  const [xidar, setXidar] = useState<XIDAR | null>(null);
  const [isLoadedXidar, setIsLoadedXidar] = useState<boolean>(!!xidar);
  const [isConnectedXidar, setIsConnectedXidar] = useState<boolean>(!!xidar);

  const init = (z: XIDAR) => {
    if (xidar) return;
    setXidar(z);
    setIsLoadedXidar(true);
  };

  const connectXidar = async () => {
    const hasWallet = await xidar?.v1.hasWallet();
    if (!hasWallet) {
      return;
    }
    await xidar?.v1.connect();
    setIsConnectedXidar(true);
  };

  const signXidar = async (message: string) => {
    const hasWallet = await xidar?.v1.hasWallet();
    if (!hasWallet) {
      return;
    } else {
      const isConnected = await xidar?.v1.isConnected();
      if (isConnected) {
        const signedMessage = await window.xidar.v1.sign(message);
        return signedMessage;
      }
    }
  };

  const disconnectXidar = async () => {
    if (!xidar) return;
    await xidar?.v1.disconnect();
    setIsConnectedXidar(false);
  };

  const handleKeystoreChange = async () => {
    if (await xidar?.v1.isConnected()) {
      await connectXidar();
    }
  };

  const hasWalletXidar = async () => {
    if (!window.xidar) return;
    const hasWallet = await window.xidar.v1.hasWallet();
    return hasWallet;
  };

  const getAccountsXidar = async () => {
    const hasWallet = await xidar?.v1.hasWallet();
    if (hasWallet) {
      const isConnected = await xidar?.v1.isConnected();
      if (isConnected) {
        const addresses = await xidar?.v1.accounts();
        return addresses;
      }
    }
  };

  /*
   * When our component first mounts, let's check to see if we have a connected
   */
  useEffect(() => {
    if (window.xidar) init(window.xidar);
    window.addEventListener(
      "xidar.init",
      (event: any) => {
        init(event.detail);
      },
      {
        once: true,
      }
    );
    window.addEventListener(
      "xidar.keystore.change",
      handleKeystoreChange,
      false
    );
  }, []);

  /*
   * When our component first mounts, let's check to see if we have a connected
   */
  useEffect(() => {
    if (!isLoadedXidar) return;
    const onLoad = async () => {
      try {
        if (await xidar?.v1.isConnected()) {
          await connectXidar();
        }
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    onLoad();
  }, [isLoadedXidar]);

  useEffect(() => {
    const onLoad = async () => {
      try {
        if (await xidar?.v1.isConnected()) {
          setIsLoadedXidar(false);
        }
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    onLoad();
  }, [isLoadedXidar]);

  return {
    ...xidar?.v1,
    connectXidar,
    signXidar,
    disconnectXidar,
    isLoadedXidar,
    isConnectedXidar,
    getAccountsXidar,
    hasWalletXidar,
  };
};
