import "dotenv/config";

import { TokenType } from "models/Enums";
import { removeCookies } from "utils";
import { APIService } from "./api.service";

export class AuthService extends APIService {
  constructor() {
    super();
  }

  public async isLoggedIn(): Promise<any> {
    return await this._get(`/auth/isLoggedIn`);
  }

  public async logout(): Promise<boolean> {
    const response = await this._post<any>(
      `/auth/logout`,
      undefined,
      true,
      TokenType.REFRESH
    );

    if (response.status === 200) removeCookies();

    return response.status === 200 ? true : false;
  }

  public async enable2FA(): Promise<any> {
    return await this._post(`/auth/mfa-setup`);
  }

  public async disable2FA(): Promise<any> {
    return await this._post(`/auth/mfa-disable`);
  }
}
