import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button, {
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from "components/Button";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { useContextUser } from "contexts/user";
import { BlockchainType, Status } from "models/Enums";
import classNames from "classnames";
import BlockchainImage from "components/BlockchainImage";
import { BlockchainInfo } from "models/Interfaces";
import { getElementsFromCollections } from "models/utils/blockchainTranslations";

const BlockchainStep: React.FC<{
  handleCancel(): void;
  handleGoToNext(blockchainType: BlockchainType): void;
}> = ({ handleCancel, handleGoToNext }) => {
  const newNotificationFlow = useNewNotificationFlow();
  const { types, blockchains } = useContextBlockchainData();
  const { t } = useTranslation(["common"]);
  const { userData } = useContextUser();

  const [isLoading, setIsLoading] = useState(true);
  const [allBlockchains, setAllBlockchains] = useState<BlockchainInfo[] | null>(
    null
  );
  const [activeBlockchains, setActiveBlockchains] = useState<
    BlockchainInfo[] | null
  >(null);
  const [blockchainSelected, setBlockchainSelected] = useState<string | null>(
    newNotificationFlow.selectedNotificationBlockchain || null
  );

  const showBlockchains = () => {
    return allBlockchains!.map((blockchain: any) => {
      const isActive = activeBlockchains!.some(
        (activeBlockchain) => activeBlockchain["key"] === blockchain.key
      );
      return (
        <div
          className={classNames(
            "rounded-2xl shadow-white shadow-lg overflow-hidden p-6 m-2 w-52",
            {
              "bg-white cursor-pointer transform hover:scale-105": isActive,
            },
            { "bg-gray-extralight30": !isActive }
          )}
          onClick={() => handleBlockchainSelection(blockchain.id)}
          key={blockchain.key}
        >
          <BlockchainImage
            blockchainId={blockchain.id}
            showName={true}
            imageWidth={40}
          />
        </div>
      );
    });
  };

  const isValidBlockchain = (chain: string) => {
    const objectChain = activeBlockchains?.filter(
      (c: BlockchainInfo) => c.id === chain
    );
    if (!objectChain?.length) {
      return false;
    } else {
      return true;
    }
  };

  const handleBlockchainSelection = (id: BlockchainType) => {
    setBlockchainSelected(id);
    if (id && isValidBlockchain(id)) {
      handleGoToNext(id as BlockchainType);
    } else {
      return;
    }
  };

  useEffect(() => {
    /**
     * Set newNotificationFlow props.
     * Depending on user having socials, define from the start the status of
     */
    newNotificationFlow.setStatusNotification(
      !userData?.socials || userData?.socials === null
        ? Status.inactive
        : Status.active
    );

    /* Reducing active blockchains from notification types, to get all active blockchains on DB */
    const blockchainsFromTypes = getElementsFromCollections(types, "chain");

    /* Reducing existing blockchains from blockchains collection, to get all existing blockchains on DB (active and ones to launch soon) */
    const blockchainsFromBlockchains = getElementsFromCollections(
      blockchains,
      "chain"
    );

    setAllBlockchains(blockchainsFromBlockchains);
    setActiveBlockchains(blockchainsFromTypes);
    setIsLoading(false);
  }, []);

  return (
    <>
      <div className="w-full">
        <div className="my-12 flex justify-evenly flex-wrap">
          {isLoading && <div className="loading-ring gray-dark"></div>}
          {!isLoading && showBlockchains()}
        </div>
        <div className="flex justify-end ">
          <Button
            onClick={handleCancel}
            type={ButtonType.cancel}
            size={ButtonSize.smallWide}
            justify={ButtonJustify.center}
          >
            {t("action_cancel", { ns: "common" })}
          </Button>
        </div>
      </div>
    </>
  );
};

export default BlockchainStep;
