export const MessagesSvg = () => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4595 5.05131C18.4595 3.71369 19.594 2.6275 20.9911 2.6275C22.3882 2.6275 23.5227 3.71369 23.5227 5.05131C23.5227 6.38894 22.3882 7.47512 20.9911 7.47512C19.594 7.47512 18.4595 6.38894 18.4595 5.05131ZM14.4151 15.4599L17.451 11.7096L17.4089 11.7297C17.577 11.5084 17.6085 11.2268 17.493 10.9754C17.3785 10.7239 17.1253 10.553 16.8532 10.5328C16.5686 10.5027 16.286 10.6234 16.1169 10.8446L13.5758 13.9926L10.665 11.8001C10.4864 11.6693 10.2763 11.618 10.0662 11.6392C9.85718 11.6693 9.6681 11.7789 9.541 11.9399L6.43269 15.8129L6.36861 15.9034C6.19003 16.2243 6.27407 16.6366 6.58921 16.8589C6.73627 16.9494 6.89384 17.0098 7.07242 17.0098C7.31507 17.0198 7.54513 16.8981 7.69219 16.708L10.3288 13.4585L13.3226 15.6118L13.4172 15.6711C13.7533 15.8421 14.1735 15.7626 14.4151 15.4599ZM16.6421 4.418C16.6001 4.66944 16.5791 4.92087 16.5791 5.1723C16.5791 7.4352 18.4909 9.26462 20.8439 9.26462C21.1065 9.26462 21.3587 9.23545 21.6213 9.19522V17.3105C21.6213 20.7209 19.5204 22.7424 15.9488 22.7424H8.18696C4.61435 22.7424 2.51343 20.7209 2.51343 17.3105V9.86906C2.51343 6.44958 4.61435 4.418 8.18696 4.418H16.6421Z"
      />
    </svg>
  );
}