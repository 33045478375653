import { useEffect, useState } from "react";

type Z3US = {
  v1: {
    hasWallet: () => Promise<boolean>;
    isConnected: () => Promise<boolean>;
    connect: () => Promise<string>;
    disconnect: () => Promise<void>;
    sign: (challenge: string) => Promise<string>;
    submitTransaction: (payload: { transaction: any }) => Promise<any>;
    encrypt: (
      message: string,
      fromAddress: string,
      toAddress: string
    ) => Promise<string>;
    decrypt: (message: string, fromAddress: string) => Promise<string>;
    addresses: () => Promise<string[]>;
    accounts: () => Promise<string[]>;
    balances: () => Promise<unknown>;
    stakes: () => Promise<unknown>;
    unstakes: () => Promise<unknown>;
  };
};

declare global {
  interface Window {
    z3us: Z3US;
  }
}

export const useZ3usWallet = () => {
  const [z3us, setZ3US] = useState<Z3US | null>(null);
  const [isLoadedZ3us, setIsLoadedZ3us] = useState<boolean>(!!z3us);
  const [isConnectedZ3us, setIsConnectedZ3us] = useState<boolean>(!!z3us);

  const init = (z: Z3US) => {
    if (z3us) return;
    setZ3US(z);
    setIsLoadedZ3us(true);
  };

  const connectZ3us = async () => {
    const hasWallet = await z3us?.v1.hasWallet();
    if (!hasWallet) {
      return;
    }
    return z3us?.v1.connect().then((wallet) => {
      setIsConnectedZ3us(true);
      console.log("is connected", wallet);
      return wallet;
    });
  };

  const signZ3us = async (message: string) => {
    const hasWallet = await z3us?.v1.hasWallet();
    if (!hasWallet) {
      return;
    } else {
      const isConnected = await z3us?.v1.isConnected();
      if (isConnected) {
        const signedMessage = await z3us?.v1.sign(message);
        return signedMessage;
      }
    }
  };

  const disconnectZ3us = async () => {
    if (!z3us) return;
    await z3us?.v1.disconnect();
    setIsConnectedZ3us(false);
  };

  const handleKeystoreChange = async () => {
    if (await z3us?.v1.isConnected()) {
      await connectZ3us();
    }
  };

  const getAccountsZ3us = async () => {
    const hasWallet = await z3us?.v1.hasWallet();
    if (hasWallet) {
      const isConnected = await z3us?.v1.isConnected();
      if (isConnected) {
        let accounts;
        try {
          accounts = await z3us?.v1.accounts();
        } catch (error) {
          console.log(
            "🚀 ~ file: use-z3us-wallet.tsx:91 ~ getAccountsZ3us ~ accountsArr:",
            error
          );
        }
        return accounts;
      }
    }
  };

  const hasWalletZ3us = async () => {
    if (!window.z3us) return;
    const hasWallet = await window.z3us.v1.hasWallet();
    return hasWallet;
  };

  /*
   * When our component first mounts, let's check to see if we have a connected
   */
  useEffect(() => {
    if (window.z3us) init(window.z3us);
    window.addEventListener(
      "z3us.init",
      (event: any) => {
        init(event.detail);
      },
      {
        once: true,
      }
    );
    window.addEventListener(
      "z3us.keystore.change",
      handleKeystoreChange,
      false
    );
  }, []);

  /*
   * When our component first mounts, let's check to see if we have a connected
   */
  useEffect(() => {
    if (!isLoadedZ3us) return;
    const onLoad = async () => {
      try {
        if (await z3us?.v1.isConnected()) {
          await connectZ3us();
        }
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    onLoad();
  }, [isLoadedZ3us]);

  return {
    ...z3us?.v1,
    connectZ3us,
    signZ3us,
    disconnectZ3us,
    isLoadedZ3us,
    getAccountsZ3us,
    isConnectedZ3us,
    hasWalletZ3us,
  };
};
