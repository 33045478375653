export const loginUrl = "/login";
export const dashboardUrl = "/dashboard";
export const notificationsUrl = "/notifications";
export const subscriptionsUrl = "/subscriptions";
export const historyUrl = "/history";
export const messagesUrl = "/messages";
export const p2pTradeUrl = "/p2p-trade";
export const settingsUrl = "/settings";
export const userSettingsUrl = "/settings/user";

export const socialsRedirectUrl = "/oauth/:social/redirect";


