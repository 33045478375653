import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { PlatformType } from "models/Enums";
import {
  PriceAlertConfigs,
  TokensCW20,
  UserNotification,
} from "models/Interfaces";
import { ConfigurationParams } from "models/Notification";
import { formatPresentation } from "models/utils/terra";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PriceAlert = ({
  setValues,
  disabledBtn,
  notification,
}: {
  setValues: Dispatch<SetStateAction<any>>;
  disabledBtn?: Dispatch<SetStateAction<any>>;
  notification?: UserNotification;
}) => {
  const { t } = useTranslation(["common"]);
  const [configs, setConfigs] = useState<PriceAlertConfigs>({
    ConfigsParameters: {
      pair: [],
      type: "",
      value: 0,
      inverted: false,
    },
    crypto1: "",
    crypto2: "",
    imageToken1: "",
    imageToken2: "",
  });
  const [dataTokens, setDataTokens] = useState<Object[]>([]);
  const [pair, setPair] = useState<string[]>([]);
  const [type, setType] = useState<string>("");
  const [value, setValue] = useState<number>(0);
  const [inverted, setInverted] = useState<boolean>(false);
  const { priceTypes } = useContextBlockchainData();
  const { selectedNotificationBlockchain, selectedPlatformType } =
    useNewNotificationFlow();
  /**
   * Handle Contract Pair Selected/Changed
   * @param e onChange event
   */
  const handleContractPairSelected = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = e.target as HTMLSelectElement;
    const optionSelectedData = target.options[e.target.selectedIndex].dataset;
    const newConfigurations = Object.assign(
      {},
      { ...configs.ConfigsParameters },
      { pair: [target?.value] }
    );
    setPair([target?.value]);
    setConfigs({
      ...configs,
      ConfigsParameters: newConfigurations,
      imageToken1: optionSelectedData?.imagetoken1 || "",
      imageToken2: optionSelectedData?.imagetoken2 || "",
      crypto1: optionSelectedData?.crypto1 || "",
      crypto2: optionSelectedData?.crypto2 || "",
    });
    checkData(newConfigurations);
  };

  /**
   * Handle Alert Type Changed
   * @param e onChange event
   */
  const handleAlertTypeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newConfigurations = Object.assign(
      {},
      { ...configs.ConfigsParameters },
      { type: e?.target.value }
    );

    if (e?.target?.value !== "") {
      setConfigs({
        ...configs,
        ConfigsParameters: newConfigurations,
      });
      setType(e?.target.value);
    }
    checkData(newConfigurations);
  };

  /**
   * Handle Alert Price Value Changed
   * @param e onChange event
   */
  const handleAlertPriceValueChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const price = e.target as HTMLInputElement;
    const newConfigurations = Object.assign(
      {},
      { ...configs.ConfigsParameters },
      { value: Number(price?.value) }
    );
    setValue(Number(price?.value));
    setConfigs({
      ...configs,
      ConfigsParameters: newConfigurations,
    });
    checkData(newConfigurations);
  };

  /**
   * Start initial configurations to show at the modal.
   * Prepare to show the contract image.
   */
  const startConfigs = () => {
    const newArr: any = [];
    dataTokens.forEach((data) => data !== false && newArr.push(data));
    if (newArr.length) {
      const key = Object.keys(newArr[0])[0];
      const contractOne: any = Object.values(newArr[0]);

      const { token, token2 }: { token: TokensCW20; token2: TokensCW20 } = {
        token: contractOne && contractOne[0][0],
        token2: contractOne && contractOne[0][1],
      };

      Object.values(contractOne).map(
        (el: any) =>
          (el[0]?.icon || el[1]?.icon) &&
          setConfigs({
            ...configs,
            ConfigsParameters: { pair: [key], inverted: false, type: ">=" },
            imageToken1: el[0]?.icon,
            imageToken2: el[1]?.icon,
            crypto1: token["symbol"],
            crypto2: token2["symbol"],
          })
      );
    }
  };

  /**
   * Load selectbox contract pair options
   */
  const showOptions = () => {
    if (!configs.imageToken1 || !configs.imageToken2) startConfigs();
    const options = dataTokens.map((contract: any) => {
      const key = Object.keys(contract)[0];
      const tokens = Object.values(contract);

      return tokens.map((el: any) => {
        return (
          <option
            value={key}
            data-crypto1={el?.[0]?.symbol}
            data-crypto2={el?.[1]?.symbol}
            data-imagetoken1={el?.[0]?.icon}
            data-imagetoken2={el?.[1]?.icon}
          >{`${el?.[0]?.symbol} / ${el?.[1]?.symbol}`}</option>
        );
      });
    });
    return options;
  };

  const setInvertedToken = () => {
    const newConfigurations = Object.assign(
      {},
      { ...configs.ConfigsParameters },
      { inverted: !inverted }
    );
    setInverted(!inverted);
    setConfigs({
      ...configs,
      ConfigsParameters: newConfigurations,
    });
    checkData(newConfigurations);
  };

  const checkData = (configurations: ConfigurationParams) => {
    if (
      configurations?.value !== undefined &&
      Number(configurations?.value) > 0
    ) {
      const formatObjeNotification = Object.assign(
        {},
        { ...configs.ConfigsParameters },
        {
          value: configurations?.value,
          inverted: configurations?.inverted,
          pair: configurations?.pair,
          type: configurations?.type,
        }
      );
      setValues({ ...formatObjeNotification });
      disabledBtn && disabledBtn(false);
    }
  };

  useEffect(() => {
    const dex = Object.entries(PlatformType).filter(
      ([nameProject, idProject]) =>
        selectedPlatformType === idProject ||
        notification?.projectId === idProject
    )[0];

    /* Get contract pairs from assets on github*/
    (async () =>
      (selectedNotificationBlockchain || notification) &&
      (await formatPresentation(
        selectedNotificationBlockchain || notification?.chainId || "",
        dex[0] || notification?.projectId
      )))().then((data) => {
      setDataTokens(data);
      if (notification) {
        let crypto1 = "",
          crypto2 = "",
          imagetoken1 = "",
          imagetoken2 = "";
        data
          .filter(
            (contract: any) => Object.keys(contract)[0] === notification.pair[0]
          )
          .map((contract: Object[]) => {
            const tokens = Object.values(contract);
            tokens.map((el: any) => {
              crypto1 = el?.[0]?.symbol;
              crypto2 = el?.[1]?.symbol;
              imagetoken1 = el?.[0]?.icon;
              imagetoken2 = el?.[1]?.icon;
            });
          });

        setConfigs({
          ...configs,
          ConfigsParameters: {
            pair: notification.pair,
            type: notification.type,
            value: notification.value,
            inverted: notification.inverted,
          },
          imageToken1: imagetoken1,
          imageToken2: imagetoken2,
          crypto1: crypto1,
          crypto2: crypto2,
        });
      }
    });
    if (notification) {
      setPair(notification.pair);
      setInverted(notification.inverted);
      setType(notification.type);
      setValue(notification.value);
    }
  }, []);

  return (
    <div>
      {!notification && (
        <div className="pb-4 border-b border-black-line w-full ">
          <h3>{t("notifications.price_alert", { ns: "common" })}</h3>
        </div>
      )}
      <div className="mt-8">
        <div className="flex align-center w-full">
          <div className="w-full">
            <select
              multiple={false}
              value={pair}
              name="pair"
              onChange={handleContractPairSelected}
              className=" focus:ring-0 selectTypes focus:outline-none customScrollbar"
            >
              {dataTokens && dataTokens.length > 0 && showOptions()}
            </select>
          </div>
        </div>
      </div>
      <p className="mt-7 font-medium">
        {t(`alert_configuration`, { ns: "common" })}
      </p>
      <div className="mt-3 border border-gray-extralight80 rounded-8px">
        <div className="px-3 py-2 text-sm text-gray-dark font-semibold bg-gray-extralight20 text-center rounded-t-8px border-b border-gray-extralight80">
          {(configs.imageToken1 !== "" || configs.imageToken2 !== "") && (
            <div className="flex items-center justify-evenly">
              <div className="flex items-center justify-center w-2/6">
                <img
                  src={inverted ? configs.imageToken2 : configs.imageToken1}
                  alt={inverted ? configs.crypto2 : configs.crypto1}
                  className="w-8 h-8"
                />
                <span className="ml-2 mr-2">
                  {`${inverted ? configs.crypto2 : configs.crypto1}`}
                </span>
              </div>
              <div className="w-2/6">
                <FontAwesomeIcon
                  icon={faArrowsAltH}
                  className="text-3xl ml-2 mr-2 text-gold cursor-pointer"
                  onClick={setInvertedToken}
                />
              </div>
              <div className="flex items-center justify-center w-2/6">
                <span className="ml-2 mr-2">
                  {inverted ? configs.crypto1 : configs.crypto2}
                </span>
                <img
                  src={inverted ? configs.imageToken1 : configs.imageToken2}
                  alt={inverted ? configs.crypto1 : configs.crypto2}
                  className="w-7 h-7"
                />
              </div>
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 px-3 py-5 pt-6">
          <div className="text-center m-auto">
            <div className="small">
              <div className="text-sm mb-1">
                {t(`alert_configuration_settings.alert_type`, {
                  ns: "common",
                })}
              </div>

              <div className="inline-block relative w-full mb-4">
                <select
                  multiple={false}
                  className="focus:ring-0 selectTypes py-1 focus:outline-none customScrollbar"
                  value={type}
                  name="type"
                  onChange={(e) => handleAlertTypeChanged(e)}
                >
                  {priceTypes?.map((tp) => {
                    return (
                      <option value={tp.type}>
                        {t(`alert_configuration_settings.${tp.type}`, {
                          ns: "common",
                        })}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="text-center">
            <div className="text-sm mb-1">
              {t(`alert_configuration_settings.alert_price`, {
                ns: "common",
              })}
            </div>
            <div>
              <input
                value={value}
                name="value"
                onChange={(e) => handleAlertPriceValueChanged(e)}
                className="small text-center w-24 font-medium text-gray-dark m-auto border-gray-light40"
                type="number"
                step=".05"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceAlert;
