import React from "react";
import classNames from "classnames";
import { Link, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Badge } from "./Badge";

const SideMenuItem: React.FC<{
  icon: React.ReactElement;
  title: string;
  url: string;
  condensed: boolean;
  disabled?: true;
  beta?: true;
}> = ({ icon, title, url, condensed, disabled, beta }) => {
  const { t } = useTranslation(["common", "enumerations", ""]);
  const isActive = useMatch(url) != null;

  return (
    <div
      className={classNames(
        "side-menu-item py-5",
        { "px-8": condensed },
        { "px-7": !condensed },
        { active: isActive },
        { "cursor-not-allowed": disabled }
      )}
    >
      {!disabled && (
        <Link to={url} className="z-10 flex">
          <div className="z-10 flex w-full">
            <div
              className={classNames(
                "icon",
                { "text-gold": isActive },
                { "text-gray-light40": !isActive }
              )}
            >
              {icon}
            </div>
            {!condensed && (
              <div className="w-full flex justify-between items-center">
                <div
                  className={classNames(
                    "side-menu-title text-gray-light50 font-semibold ml-5",
                    { "text-gold": isActive }
                  )}
                >
                  {title}
                </div>
                {beta &&(
                  <Badge text={t("menu.beta", { ns: "common" })} />
                )}
              </div>
            )}
          </div>
        </Link>
      )}
      {disabled && (
        <div className={classNames(
          "icon z-10 flex bg-gray"
        )}>
          <div
            className={classNames(
              "icon text-gray-300"
            )}
          >
            {icon}
          </div>
          {!condensed && (
            <div
              className={classNames(
                "side-menu-title font-medium ml-5 text-sxm text-gray-300"
              )}
            >
              {title}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SideMenuItem;
