import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContextUser } from "contexts/user";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { AvailableTlds, BlockchainType, ScopeNotification } from "models/Enums";
import { getDomain, getDomainExpiration } from "../../../models/utils/terra";
import { Domain, UserNotification } from "models/Interfaces";
import { Tooltip, TooltipStyle, TooltipStyleFont } from "components/Tooltip";
/*  */
const DomainChanges = ({
  disabledBtn,
  setValues,
  notification,
}: {
  disabledBtn?: Dispatch<SetStateAction<any>>;
  setValues: Dispatch<SetStateAction<any>>;
  notification?: UserNotification;
}) => {
  const { t } = useTranslation("common, enumerations");
  const { userData } = useContextUser();
  const { selectedNotificationBlockchain } = useNewNotificationFlow();
  const [regex, setRegex] = useState<boolean>(notification!?.regex || false); // in the webapp regex is always false
  const [regexInput, setRegexInput] = useState<string>("([A-Z])\\w+");
  const [regexWidth, setRegexWidth] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [scope, setScope] = useState<string | "">(
    notification ? notification.scope : ""
  );
  const [showOptions, setShowOptions] = useState<boolean>(
    notification ? true : false
  );
  const [userDomains, setUserDomains] = useState<Domain[] | []>([]);
  const [domain, setDomain] = useState<string | "">(
    notification ? notification.domain.name : ""
  );
  const [options, setOptions] = useState({
    metadataChanges: notification ? notification.metadataChanges : false,
    transfer: notification ? notification.transfer : false,
  });

  /**
   * Set initial fields values.
   * Called when component is loading and on every validator select change to reset the other fields.
   */
  const resetFields = () => {
    disabledBtn && disabledBtn(true);
    setScope("");
    setRegexInput("");
    setShowOptions(false);
  };

  /**
   * Handle Scope Changed
   * @param e onChange event
   */
  const handleScopeChanged = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    resetFields();
    setScope(e.target?.value);
    setShowOptions(true);
    if (e.target?.value === "") setShowOptions(false);
    else {
      e.target?.value === ScopeNotification.myDomain
        ? userDomains.length === 1 && setDomain(userDomains![0].name)
        : setDomain("");
    }
  };

  const handleDomainChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e?.target.value);

    if (e?.target.value !== "") {
      (async () => {
        const domainExists = await getDomainExpiration(
          e?.target.value,
          selectedNotificationBlockchain!
        );

        domainExists === undefined ? setError(true) : setError(false);
      })();
    } else setError(false);

  };

  /**
   * Goes through user's wallets and searches for domains associated
   */
  const getDomainFromWallet = () => {
    let wallets = userData?.wallets;
    let myDomains: Domain[] = [];
    wallets
      ?.filter((w) =>
        selectedNotificationBlockchain === BlockchainType.Terrav2 ||
        selectedNotificationBlockchain === BlockchainType.TerraClassic
          ? w.chainId === BlockchainType.Terrav2 ||
            w.chainId === BlockchainType.TerraClassic
          : w.chainId === selectedNotificationBlockchain
      )
      .forEach(async (w) => {
        const domainExists = await getDomain(
          w.walletAddress,
          selectedNotificationBlockchain!
        );
        if (domainExists) {
          myDomains.push(domainExists);
        }
      });
    setUserDomains(myDomains);
  };

  const handleChangeOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptions({ ...options, [e.target.name]: e.target.checked });
  };

  const saveValues = () => {
    setValues({
      //! contract must be changed once assets have projects
      contract:
        "terra16a6qkmxpqzeyez8gh3w7qhrk7x3xe3arlv9nwfg944y8vzg9smrqntark3",
      scope,
      metadataChanges: options.metadataChanges,
      transfer: options.transfer,
      regex: regex,
      domain: {
        name: domain,
        tld: AvailableTlds.luna,
      },
    });
  };

  const hasPublicId = (): number =>
    Object.values(notification!.publicId).filter((id: string) => id !== "")
      .length;

  useEffect(() => {
    if (!notification) {
      getDomainFromWallet();
    }
  }, []);

  useEffect(() => {
    if (userDomains.length === 1) {
      setDomain(userDomains[0].name);
    }
  }, [userDomains]);

  useEffect(() => {
    if (
      (!options.metadataChanges && !options.transfer) ||
      scope === "" ||
      domain === "" ||
      error
    ) {
      disabledBtn && disabledBtn(true);
    } else {
      disabledBtn && disabledBtn(false);
    }
    saveValues();
  }, [options, domain, scope, error]);

  return (
    <>
      <div className="w-full">
        {!notification && (
          <div className="pb-4 border-b border-black-line w-full ">
            <h3>{t("notifications.domain_changes", { ns: "common" })}</h3>
          </div>
        )}
        {/* Scope selectbox */}
        <div className="mt-6">
          <select
            multiple={false}
            value={scope}
            onChange={handleScopeChanged}
            className="focus:ring-0 focus:outline-none "
            disabled={notification?.notificationId !== undefined}
          >
            <option value="">
              {t(`notifications.select_scope`, { ns: "common" })}
            </option>
            {Object.values(ScopeNotification)
              .filter(
                (value) =>
                  value === ScopeNotification.myDomain ||
                  value === ScopeNotification.otherDomain
              )
              .map((el) => {
                return (
                  <option value={el} key={Math.random() * 0.5}>
                    {t(`scope_notifications.domain.${el}`, {
                      ns: "enumerations",
                    })}
                  </option>
                );
              })}
          </select>
        </div>
        {/* Domain selectbox for my domains */}
        {((notification?.notificationId !== undefined &&
          notification?.scope === ScopeNotification.myDomain) ||
          scope === ScopeNotification.myDomain) && (
          <div className="mt-6">
            <>
              <p className="text-sm mb-1">
                {t("notifications.domain", {
                  ns: "common",
                })}
              </p>
              <select
                multiple={false}
                onChange={(e) => setDomain(e.target.value)}
                value={domain}
                className={`focus:border-0 myWallet focus:ring-0 focus:outline-none`}
                disabled={
                  userDomains.length <= 1 ||
                  notification?.notificationId !== undefined
                }
              >
                {!notification && userDomains.length === 0 && (
                  <option value="">
                    {t(`notifications.no_domain`, { ns: "common" })}
                  </option>
                )}

                {notification?.domain.name && (
                  <option value="">{`${notification!.domain.name}.${
                    notification!.domain.tld
                  }`}</option>
                )}

                {userDomains.length > 1 && (
                  <option value="">
                    {t(`notifications.select_domain`, { ns: "common" })}
                  </option>
                )}
                {userDomains?.map((d) => {
                  return (
                    <option value={d?.name}>
                      {d.name + "." + t(`notifications.luna`, { ns: "common" })}
                    </option>
                  );
                })}
              </select>
            </>
          </div>
        )}
        {/* Other domain selectbox */}
        {((notification?.notificationId !== undefined &&
          notification?.scope === ScopeNotification.otherDomain) ||
          scope === ScopeNotification.otherDomain) && (
          <div className="mt-6">
            <>
              <p className="text-sm mb-1">
                {t("notifications.domain", {
                  ns: "common",
                })}
              </p>

              <div className="flex space-x-4  items-center">
                <div
                  className={`flex flex-grow ${
                    regex ? "border rounded" : "border-none"
                  } `}
                >
                  {regex ? (
                    <div className="text-sm flex focus:border-none focus:outline-none items-center rounded h-10 px-2  text-gray-medium ">
                      <span className="text-gray-extralight50">/</span>
                      <input
                        type={`text`}
                        value={notification!?.domain.name}
                        style={{
                          width: `${
                            regexInput.indexOf("([A-Z])\\w+") === -1 ||
                            regexInput === ""
                              ? 8 + regexWidth * 8.4 + "px"
                              : 75 + regexWidth * 8.4 + "px"
                          }`,
                        }}
                        className={`${
                          regexInput === "([A-Z])\\w+" ||
                          (notification!?.domain.name && " width80 ")
                        } focus:opacity-100 focus:ring-0 text-center  text-border-gray-extralight90medium transition-words max-w-sm text-sm placeholder-small-fontSize h-9 focus:border-gray-extralight90medium focus:outline-none text-black-transparent50 rounded border-none noSidePadding`}
                        required={true}
                      />
                      <span className="text-gray-extralight50">/gi</span>
                    </div>
                  ) : (
                    <>
                      <input
                        type={`text`}
                        value={domain}
                        onChange={handleDomainChanged}
                        placeholder={t("notifications.domain_name_wo_regex", {
                          ns: "common",
                        })}
                        className={`focus:ring-0 mr-2 focus:opacity-100 w-full text-sm focus:border-gray-extralight90medium focus:outline-none rounded p-0 px-3 border-gray-extralight90medium text-gray-medium`}
                        required={true}
                        disabled={notification?.notificationId !== undefined}
                      />

                      <select
                        multiple={false}
                        className={`focus:ring-0 focus:opacity-100 focus:border-gray-extralight90medium sm:w-4/12  focus:outline-none px-3 rounded h-10 p-0 border-gray-extralight90medium text-gray-medium  `}
                        disabled={true}
                      >
                        <option>
                          .{t(`notifications.luna`, { ns: "common" })}
                        </option>
                      </select>
                    </>
                  )}
                </div>

                {(notification && !hasPublicId()) ||
                (notification?.notificationId === undefined && !regex) ? (
                  <Tooltip
                    content={t("notifications.regex_info", { ns: "common" })}
                    topPosition="240px"
                    tooltipStyle={TooltipStyle.dark}
                    fontStyle={TooltipStyleFont.base}
                  >
                    <div className="sm:w-2/12 text-gray-medium relative">
                      <label
                        htmlFor="regex"
                        className="text-sm sm:flex-none items-center flex"
                      >
                        <input
                          onChange={() => {
                            setRegex(!regex);
                            setRegexInput("([A-Z])\\w+");
                            setRegexWidth(0);
                            setDomain("");
                          }}
                          type="checkbox"
                          id="regex"
                          disabled={true}
                          className="border-gray-extralight90medium text-gold  checked:border-0 focus:ring-transparent "
                        />

                        <span className="ml-1">
                          {t(`notifications.regex`, { ns: "common" })}
                        </span>
                      </label>
                    </div>
                  </Tooltip>
                ) : (
                  <div className="sm:w-2/12 text-gray-medium relative">
                    <label
                      htmlFor="regex"
                      className="text-sm sm:flex-none items-center flex"
                    >
                      <input
                        type="checkbox"
                        id="regex"
                        checked={notification && notification?.regex}
                        className="border-gray-extralight90medium text-gold  checked:border-0 focus:ring-transparent "
                      />

                      <span className="ml-1">
                        {t(`notifications.regex`, { ns: "common" })}
                      </span>
                    </label>
                  </div>
                )}
              </div>
              {notification?.notificationId === undefined && error && (
                <small className="px-1 text-red">
                  {t("notifications.invalid_domain", { ns: "common" })}
                </small>
              )}
            </>
          </div>
        )}
        {showOptions && (
          <>
            <h6 className="text-medium mt-8 mb-2">
              {t("notifications.domain_options", { ns: "common" })}
            </h6>
            <div className="text-sm my-7 grid lg:grid-cols-2 sm:mx-6 mx-2">
              {Object.entries(options).map(([v, k]) => (
                <label
                  htmlFor={v}
                  className="cursor-pointer text-sm font-normal mb-3"
                >
                  <input
                    className="mx-2 focus:outline-none focus:ring-0 text-gold border-gold checked:border-0 focus:ring-transparent cursor-pointer transition-colors "
                    type="checkbox"
                    name={v}
                    id={v}
                    onChange={handleChangeOptions}
                    checked={k}
                  />
                  {t(`domain_changes_options.${v}`, {
                    ns: "enumerations",
                  })}
                </label>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DomainChanges;
