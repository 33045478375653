import "dotenv/config";

import { APIService } from "./api.service";

export class SubscriptionService extends APIService {
  constructor() {
    super();
  }

  public async update(data: any): Promise<any> {
    return await this._patch(`/fauna/business/subscription`, data);
  }
}
