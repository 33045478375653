import { BlockchainType } from "models/Enums";
import { UserData } from "models/Interfaces";

export const hasChainWallets = (
  userData: UserData,
  selectedNotificationBlockchain: BlockchainType
) => {
  return userData?.wallets.filter((w) =>
    selectedNotificationBlockchain === BlockchainType.Terrav2 ||
    selectedNotificationBlockchain === BlockchainType.TerraClassic
      ? w.chainId === BlockchainType.Terrav2 ||
        w.chainId === BlockchainType.TerraClassic
      : w.chainId === selectedNotificationBlockchain
  );
};