import Loader, { LoaderType } from "components/Loader";

import classNames from "classnames";
import { useContextUser } from "contexts/user";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "shared";
import { instanceOfUserData } from "utils/instanceOf";
import { IconSettings } from "../settingsCards/IconSettings";

export const UserSettingsEdit: React.FC<{}> = () => {
  const { t } = useTranslation(["common"]);
  const { userData, setUser } = useContextUser();
  const [newDataUser, setNewDataUser] = useState<string>(
    userData?.clientAlias ?? ""
  );
  const [showSave, setShowSave] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const changeAlias = async () => {
    setNewDataUser(newDataUser.trim());
    if (!userData) return;

    const response = await api.user.update({ alias: newDataUser.trim() });
    if (typeof response === "object" && instanceOfUserData(response)) {
      setUser(response);
    }
    setShowSave(false);
    setIsLoading(false);
  };

  const handleChange = (e: any) => {
    setNewDataUser(e.target.value);
    setShowSave(true);
  };

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <div className="relative userSettings md:w-full flex items-center md:px-6 pb-8 py-5 max-w-6xl">
        <section className="px-2 md:px-0 flex flex-col w-full">
          <div>
            <h5>{t("settings.inputLabel", { ns: "common" })}</h5>
            <div className="relative cursor-pointer mt-3">
              <button
                className={classNames("absolute right-0 p-2", {
                  hidden: !showSave,
                })}
                onClick={changeAlias}
              >
                <IconSettings
                  icon="save"
                  bgContainerTransparent={true}
                  iconStyle="fas"
                  textColor="text-gold"
                  fontSize="text-xl"
                  className="float-right"
                />
              </button>
              <input
                id="aliasField"
                type="text"
                className="w-full block bg-gray-extralight15 h-8 border-b-2 opacity-1 h-5 border-transparent focus:border-transparent appearance-none outline-none focus:outline-none focus:ring-transparent"
                defaultValue={userData?.clientAlias ?? ""}
                onChange={(e) => handleChange(e)}
                maxLength={10}
                value={newDataUser}
              />
              <hr className="text-gray-lightmedium" />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
