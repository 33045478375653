import React, { useEffect, useState } from "react";
import { NotificationType } from "models/Enums";
import Notification from "models/Notification";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faShare } from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faTelegram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import PlatformIcon from "./PlatformIcon";
import { UserNotification } from "models/Interfaces";
import ProfileChangesCard from "./notifications/notificationCards/ProfileChangesCard";
import GovernancePollCard from "./notifications/notificationCards/GovernancePollCard";
import WalletWatcherCard from "./notifications/notificationCards/WalletWatcherCard";
import PriceAlertCard from "./notifications/notificationCards/PriceAlertCard";
import NftMonitorCard from "./notifications/notificationCards/NFTMonitorCard";
import NewSaleCard from "./notifications/notificationCards/NewSaleCard";
import classNames from "classnames";
import BlockchainImage from "./BlockchainImage";
import LiquidityPoolCard from "./notifications/notificationCards/LiquidityPoolCard";
import DomainChangesCard from "./notifications/notificationCards/DomainChangesCard";
import DomainRegistrationCard from "./notifications/notificationCards/DomainRegistrationCard";
import LiquidStakingCard from "./notifications/notificationCards/LiquidStakingCard";
import NewDomainCard from "./notifications/notificationCards/NewDomainCard";
import StakingCard from "./notifications/notificationCards/StakingCard";

export interface INotificationProps {
  notification: Notification;
}

const NotificationCard: React.FC<{
  setOpacity: boolean;
  notification: UserNotification;
  handleOnClick(): void;
}> = ({ setOpacity, notification, handleOnClick }) => {
  const { t } = useTranslation(["common", "enumerations"]);
  const notificationType = notification?.notificationTypeId?.slice(-4);
  const [copy, setCopy] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const liquidStakingTypeAlerts = [
    { name: "stakes", ...notification.stakes },
    { name: "queueUnbond", ...notification.queueUnbond },
    { name: "submitBatch", ...notification.submitBatch },
    { name: "finishUnbond", ...notification.finishUnbond },
  ];
  const StakingTypeAlerts = [
    { name: "withdrawRewards", ...notification.withdrawRewards },
    { name: "delegate", ...notification.delegate },
    { name: "undelegate", ...notification.undelegate },
    { name: "redelegate", ...notification.redelegate },
  ];

  const getTypeDesc = (type: NotificationType) => {
    const name = Object.entries(NotificationType).find(
      ([key, value]): string | undefined => {
        if (value === type) {
          return value;
        }
      }
    )?.[0];
    if (name) {
      return t(`notification_types.${name}`, {
        ns: "enumerations",
      });
    } else {
      return "";
    }
  };

  const copyToClipboard = (word?: string) => {
    navigator.clipboard.writeText(word || "");
    setCopy(true);
  };

  const hasSocials: boolean =
    notification?.discordId.length !== 0 ||
    notification?.telegramId.length !== 0 ||
    notification?.twitterId.length !== 0;


  const handleTurnCard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setIsFlipped(!isFlipped);
  };

  const hasPublicId = (): number =>
    Object.values(notification.publicId).filter((id: string) => id !== "")
      .length;

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 2000);
    }

  }, [copy]);

  return (
    <div className="w-full p-4 flex sm:w-1/2 xl:w-1/3 min-h-xs">
      <div
        className={classNames(
          "w-full relative rounded-2xl bg-white shadow-white shadow-lg overflow-hidden p-4 flex flex-col flex-grow",
          { "bg-gray-extralight30": setOpacity },
          {
            "shadow-discord": hasPublicId(),
          }
        )}
      >
        <div className={`flex flex-col h-full`}>
          {/* Project logo and settings global to all types */}
          <header className="flex justify-between z-10 h-1/8 mb-4">
            <div className="flex">
              <span className="h-8 w-8">
                <BlockchainImage blockchainId={notification.chainId} showName={false} />
              </span>

              <PlatformIcon
                className="h-8 w-8 -ml-2.5 rounded-full bg-white"
                platform={notification?.projectId}
              />
            </div>
            <button
              onClick={handleOnClick}
              className={` h-9 w-9 ${!setOpacity && "shadow-inner-2xl"
                } bg-white text-gold rounded-full hover:shadow-inner-2xl cursor-pointer transition duration-200`}
              aria-label="Edit notification"
            >
              <FontAwesomeIcon icon={faCog} className="bg-transparent" />
            </button>
          </header>

          {/* Specific cards */}
          {NotificationType?.ProfileChanges === notificationType && (
            <ProfileChangesCard
              scope={notification.scope}
              copyToClipboard={copyToClipboard}
              notification={notification}
              getTypeDesc={getTypeDesc}
              copy={copy}
            />
          )}
          {NotificationType?.GovernancePoll === notificationType && (
            <GovernancePollCard
              copyToClipboard={copyToClipboard}
              notification={notification}
              getTypeDesc={getTypeDesc}
              copy={copy}
            />
          )}
          {NotificationType?.WalletWatcher === notificationType && (
            <WalletWatcherCard
              copyToClipboard={copyToClipboard}
              notification={notification}
              getTypeDesc={getTypeDesc}
              copy={copy}
            />
          )}
          {NotificationType?.PriceAlert === notificationType && (
            <PriceAlertCard
              copyToClipboard={copyToClipboard}
              copy={copy}
              notification={notification}
              getTypeDesc={getTypeDesc}
            />
          )}
          {NotificationType?.NftMonitor === notificationType && (
            <NftMonitorCard
              scope={notification.scope}
              copyToClipboard={copyToClipboard}
              notification={notification}
              getTypeDesc={getTypeDesc}
              copy={copy}
              isFlipped={isFlipped}
            />
          )}
          {NotificationType?.NewSale === notificationType && (
            <NewSaleCard
              copyToClipboard={copyToClipboard}
              notification={notification}
              getTypeDesc={getTypeDesc}
              copy={copy}
            />
          )}
          {NotificationType?.LiquidityPool === notificationType && (
            <LiquidityPoolCard
              copyToClipboard={copyToClipboard}
              notification={notification}
              getTypeDesc={getTypeDesc}
              isFlipped={isFlipped}
              copy={copy}
            />
          )}
          {NotificationType?.DomainRegistration === notificationType && (
            <DomainRegistrationCard
              copyToClipboard={copyToClipboard}
              notification={notification}
              getTypeDesc={getTypeDesc}
              copy={copy}
            />
          )}
          {NotificationType?.DomainChanges === notificationType && (
            <DomainChangesCard
              copyToClipboard={copyToClipboard}
              notification={notification}
              getTypeDesc={getTypeDesc}
              copy={copy}
            />
          )}
          {NotificationType?.LiquidStaking === notificationType && (
            <LiquidStakingCard
              liquidStakingTypeAlerts={liquidStakingTypeAlerts}
              notification={notification}
              copyToClipboard={copyToClipboard}
              getTypeDesc={getTypeDesc}
              isFlipped={isFlipped}
              copy={copy}
            />
          )}
          {NotificationType?.Staking === notificationType && (
            <StakingCard
              liquidStakingTypeAlerts={StakingTypeAlerts}
              notification={notification}
              copyToClipboard={copyToClipboard}
              getTypeDesc={getTypeDesc}
              isFlipped={isFlipped}
              copy={copy}
            />
          )}
          {NotificationType?.NewDomain === notificationType && (
            <NewDomainCard
              copyToClipboard={copyToClipboard}
              copy={copy}
              notification={notification}
              getTypeDesc={getTypeDesc}
            />
          )}

          {/* Social channels global to all types */}
          <div className="z-10 h-2/8">
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold py-1 pb-3">
                {t("social_channels", { ns: "common" })}
              </span>
              <div className="flex justify-between w-full">
                <div className="text-xl flex flex-row gap-3">
                  {!hasSocials && (
                    <p className="text-xsm">
                      {t("no_social_alerts_set", "common")}{" "}
                    </p>
                  )}
                  {hasSocials && (
                    <>
                      {notification?.discordId.length > 0 && (
                        <FontAwesomeIcon
                          className="text-discord"
                          icon={faDiscord}
                        />
                      )}
                      {notification?.telegramId.length > 0 && (
                        <FontAwesomeIcon
                          className="text-telegram"
                          icon={faTelegram}
                        />
                      )}
                      {notification?.twitterId.length > 0 && (
                        <FontAwesomeIcon
                          className="text-twitter"
                          icon={faTwitter}
                        />
                      )}
                    </>
                  )}
                </div>
                {(NotificationType.LiquidityPool === notificationType ||
                  NotificationType?.NftMonitor === notificationType ||
                  NotificationType.LiquidStaking === notificationType ||
                  NotificationType.Staking === notificationType) && (
                    <div
                      onClick={(e) => handleTurnCard(e)}
                      className={classNames(
                        `cursor-pointer`,
                        {
                          "transform rotate-180 transition duration-500 ease-in-out":
                            isFlipped,
                        },
                        {
                          "transform rotate-360 transition duration-500 ease-in-out":
                            !isFlipped,
                        }
                      )}
                    >
                      <FontAwesomeIcon icon={faShare} className="text-gold" />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
