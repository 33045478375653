export const DashboardSvg = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.35067 1.83331H7.58984C8.94109 1.83331 10.024 2.88748 10.024 4.1809V7.30581C10.024 8.60748 8.94109 9.65248 7.58984 9.65248H4.35067C3.009 9.65248 1.9165 8.60748 1.9165 7.30581V4.1809C1.9165 2.88748 3.009 1.83331 4.35067 1.83331ZM4.35067 12.3472H7.58984C8.94109 12.3472 10.024 13.3931 10.024 14.6948V17.8197C10.024 19.1122 8.94109 20.1664 7.58984 20.1664H4.35067C3.009 20.1664 1.9165 19.1122 1.9165 17.8197V14.6948C1.9165 13.3931 3.009 12.3472 4.35067 12.3472ZM18.6491 1.83331H15.4099C14.0587 1.83331 12.9758 2.88748 12.9758 4.1809V7.30581C12.9758 8.60748 14.0587 9.65248 15.4099 9.65248H18.6491C19.9908 9.65248 21.0833 8.60748 21.0833 7.30581V4.1809C21.0833 2.88748 19.9908 1.83331 18.6491 1.83331ZM15.4099 12.3472H18.6491C19.9908 12.3472 21.0833 13.3931 21.0833 14.6948V17.8197C21.0833 19.1122 19.9908 20.1664 18.6491 20.1664H15.4099C14.0587 20.1664 12.9758 19.1122 12.9758 17.8197V14.6948C12.9758 13.3931 14.0587 12.3472 15.4099 12.3472Z"
      />
    </svg>
  );
}