import { useTranslation } from "react-i18next";
import { getSocialAuthorizeUrl } from "../../utils/handleLogin";
import twitter from "../../assets/images/twitter.svg";
import discord from "../../assets/images/discord_white.svg";
import telegram from "../../assets/images/telegram.svg";
import classNames from "classnames";
import { SocialChannel } from "models/Enums";
import { useContextUser } from "contexts/user";
import TelegramLoginButton, {
  TelegramUser,
} from "components/auth/TelegramLoginButton";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Button, { ButtonSize, ButtonType } from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

type Props = {
  closeModal?: Function;
};

export const SocialAccounts = ({ closeModal }: Props) => {
  const { t } = useTranslation(["common"]);
  const { userData } = useContextUser();
  const navigate = useNavigate();

  interface EachSocial {
    type: String;
    status: Number;
    username: String;
  }

  const hasSocial = (social: string) => {
    if (userData?.socials === null) {
      return false;
    } else {
      const socialExists = userData?.socials.filter(
        (eachSocial: EachSocial) => {
          return eachSocial.type === social && eachSocial.status === 1;
        }
      );
      return socialExists && socialExists.length > 0;
    }
  };

  const socialIcon = (social: string) => {
    if (social === SocialChannel.Discord.toLowerCase()) {
      return discord;
    } /* else if (social === SocialChannel.Twitter.toLowerCase()) {
      return twitter;
    }*/ else if (social === SocialChannel.Telegram.toLowerCase()) {
      return telegram;
    }
  };

  useEffect(() => {
    localStorage.setItem("origin", "on");
  }, []);

  return (
    <>
      <div className="md:px-2 sm:px-0 md:px-4 py-3">
        <a
          href={`${process.env.REACT_APP_OAUTH_URL}/?origin=webapp&action=addSocial&userId=${userData?.userId}`}
        >
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.medium}
            fullWidth
          >
            <div className="text-gray-light60">
              <FontAwesomeIcon icon={faUser} size="2x" />
            </div>
            <span className="pl-6">{t(`addSocials`, { ns: "common" })}</span>
          </Button>
        </a>
        {/* {Object.values(SocialChannel).map((eachSocial) => {
          return (
            <div className="h-16 flex items-center justify-center">
              <a
                href={
                  hasSocial(eachSocial.toLowerCase())
                    ? "javascript:void(0)"
                    : eachSocial !== SocialChannel.Telegram
                    ? getSocialAuthorizeUrl(eachSocial.toLowerCase())
                    : "#"
                }
                className="w-full block"
              >
                {eachSocial !== SocialChannel.Telegram && (
                  <button
                    className={classNames(
                      "py-2 px-4 rounded-12px bg-discord text-white w-full",
                      {
                        "cursor-not-allowed opacity-60": hasSocial(
                          eachSocial.toLowerCase()
                        ),
                      }
                    )}
                  >
                    <img
                      src={socialIcon(eachSocial.toLowerCase())}
                      className={classNames("inline w-6")}
                      alt={`${eachSocial.toLowerCase()} icon`}
                    />
                    <span className="pl-3 md:pl-4">
                      Log in with{" "}
                      {eachSocial.slice(0, 1) +
                        eachSocial.slice(1).toLowerCase()}
                    </span>
                  </button>
                )}
                {eachSocial === SocialChannel.Telegram && (
                  <button>
                    <TelegramLoginButton
                      botName={process.env.REACT_APP_TELEGRAM_BOT_NAME!}
                      buttonSize="large"
                      cornerRadius={12}
                      requestAccess={true}
                      usePic={true}
                      lang="en"
                      dataOnAuth={(user: TelegramUser) => {
                        const url = new URL(
                          "/oauth/telegram/redirect",
                          window.location.origin
                        );
                        Object.entries(user).map((v) => {
                          url.searchParams.append(v[0], v[1]);
                        });
                        navigate((url.pathname + url.search).toString());
                      }}
                    />
                  </button>
                )}
              </a>
            </div>
          );
        })} */}
      </div>
    </>
  );
};
