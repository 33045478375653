import { UserData } from "models/Interfaces";

/**
 * Capitalize String
 * @param name
 * @returns
 */
export const capitalizeName = (name: string) => {
  return name
    ?.split(" ")
    .map((opName) =>
      opName
        ?.charAt(0)
        .toUpperCase()
        .concat(opName?.substring(1, opName.length))
    )
    .join(" ");
};

/**
 * Show shorter wallet address
 * @param url
 * @returns
 */
export const showShortURL = (url: string) => {
  return url
    ?.substring(0, 6)
    ?.concat("...")
    .concat(url.substring(url.length, url.length - 6));
};

/**
 * Count the number of user's wallets
 * @param user
 * @returns
 */
export const countUsersWallets = (user: UserData | null): number =>
  user?.wallets.length ?? 0;

/**
 * Get NFT url
 * @param url
 * @returns
 */
export const getNftUrl = (url: string | undefined) => {
  if (url?.toLowerCase().startsWith("https://")) {
    return url.replace(" ", "%20");
  } else if (url?.toLowerCase().startsWith("ipfs://")) {
    return `https://cloudflare-ipfs.com/ipfs/${url?.slice(7)}`;
  } else {
    return url;
  }
};

/**
 * Regex function for terra wallet validation
 */
export const validateTerraWallet = new RegExp(
  /^terra(valoper)?1([a-z0-9]{38}|[a-z0-9]{58})\b/
);

/**
 * Regex function for radix wallet validation
 */
export const validateRadixWallet = new RegExp(/rdx[a-zA-Z0-9]{62}/);
