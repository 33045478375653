import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { CategoryType, NotificationType, PlatformType } from "models/Enums";
import { BlockchainInfo, NotificationTypes, Project } from "models/Interfaces";
import { getElementsFromCollections } from "models/utils/blockchainTranslations";

export const SelectAlertTypes = ({
  setNotificationSetup,
}: {
  setNotificationSetup: Dispatch<SetStateAction<any>>;
}) => {
  const newNotificationFlow = useNewNotificationFlow();
  const { t } = useTranslation(["common", "enumerations"]);
  const { categories, projects, types, projectsInfo } =
    useContextBlockchainData();

  const [isLoading, setIsLoading] = useState(true);
  const [allCategories, setAllCategories] = useState<BlockchainInfo[] | null>(
    null
  );
  const [activeProjects, setActiveProjects] = useState<BlockchainInfo[] | null>(
    null
  );
  const [decisionTree, setDecisionTree] = useState<any>(null);
  const [configsAlert, setConfigsAlert] = useState<
    [CategoryType, PlatformType, NotificationType] | []
  >([]);

  /* Distinguish lable between active and non active categories */
  const handleCategoryLabel = (category: string) => {
    const activeCategories = decisionTree
      ?.map((path: any) => {
        const test1 = Object.entries(path)
          .filter(([k, v]: any) => {
            return Object.keys(v).length > 0;
          })
          .map(([k, v]: any) => k);
        return test1;
      })
      .flat();

    if (activeCategories?.includes(category)) {
      //! Integrate assets
      return category;
    } else {
      return `${category} - Not available for this chain`;
    }
  };

  /* Set selected alert by its category, plaform and type */
  const handleSelectElements = (dataArray: string) => {
    const data = dataArray?.split(",");
    setConfigsAlert([
      data[0] as CategoryType,
      data[1] as PlatformType,
      data[2] as NotificationType,
    ]);
  };

  useEffect(() => {
    /* Reducing existing categories from categories collection, to get all existing categories on DB (active and ones to launch soon) */
    const categoriesFromCategories = getElementsFromCollections(
      categories,
      "category"
    );
    /* Reducing active projects from notification types, to get all active projects on DB, that are compatible with selected blockchain */
    const projectsFromTypesFilteredByChain = getElementsFromCollections(
      types,
      "project_market",
      newNotificationFlow?.selectedNotificationBlockchain
    );
    let decisionTreeProv: any = [];
    /* Get all types that belong in the selected blockchain scope and are not only BE*/
    const notificationTypesFilteredByChain:
      | NotificationTypes[]
      | []
      | undefined = types?.filter(
      (type: NotificationTypes) =>
        type.chain_id === newNotificationFlow?.selectedNotificationBlockchain &&
        type.only_BE === 0
    );
    categoriesFromCategories.map((category: BlockchainInfo) => {
      /* Get all projects that belong in the selected blockchain scope and in the category at scope and are active (present in types) */
      let projectsTree: any = {};
      projects
        ?.filter(
          (project: Project) =>
            project.chain_id ===
              newNotificationFlow?.selectedNotificationBlockchain &&
            project.category.includes(category.id) &&
            projectsFromTypesFilteredByChain
              ?.map((project) => project.key)
              .includes(project.project_desc)
        )
        .forEach((project: Project) => {
          projectsTree[project.project_desc] =
            notificationTypesFilteredByChain?.filter(
              (type: NotificationTypes) =>
                type.project_market_desc === project.project_desc
            );
        });
      decisionTreeProv.push({
        [category.key]: projectsTree,
      });
    });
    setDecisionTree(decisionTreeProv);
    setAllCategories(categoriesFromCategories);
    setActiveProjects(projectsFromTypesFilteredByChain);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (newNotificationFlow.selectedNotificationType)
      setConfigsAlert([
        newNotificationFlow?.selectedCategoryType as CategoryType,
        newNotificationFlow.selectedPlatformType as PlatformType,
        newNotificationFlow?.selectedNotificationType as NotificationType,
      ]);
  }, [newNotificationFlow]);

  useEffect(() => {
    configsAlert.length && setNotificationSetup(configsAlert);
  }, [configsAlert]);

  return (
    <div className="my-12">
      {isLoading && <div className="loading-ring gray-dark"></div>}
      {!isLoading && (
        <>
          <select
            multiple={false}
            onChange={(e) => handleSelectElements(e?.target?.value)}
            className="focus:ring-0 selectTypes  focus:outline-none customScrollbar"
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled className="font-extrabold">
              {t("notifications.select_type", { ns: "common" })}
            </option>
            {decisionTree?.map((path: any) => {
              let category: any;
              let projects: any;
              Object.entries(path).map(([k, v]: any) => {
                category = k;
                projects = v;
              });
              return (
                <optgroup
                  className="relative"
                  label={handleCategoryLabel(category)} //! Integrate assets
                  key={category} //! Integrate assets
                  defaultValue={"sd"}
                >
                  {Object.entries(projects).map(
                    ([project, notificationTypes]: any) => {
                      //! Integrate assets
                      const projectName = project;
                      return (
                        <React.Fragment key={projectName}>
                          <option className="forceLeft font-bold " disabled>
                            {projectName}
                          </option>
                          {notificationTypes.map((type: NotificationTypes) => {
                            return (
                              !type.only_BE && (
                                <React.Fragment
                                  key={type?.notification_type_id}
                                >
                                  <option
                                    value={[
                                      type?.category_id as CategoryType,
                                      type?.project_market_id as PlatformType,
                                      type?.notification_type_id as NotificationType,
                                    ]}
                                    className="pl-4"
                                    selected={
                                      newNotificationFlow?.selectedCategoryType ===
                                        type.category_id &&
                                      newNotificationFlow?.selectedPlatformType ===
                                        type.project_market_id &&
                                      newNotificationFlow?.selectedNotificationType ===
                                        type.notification_type_id
                                    }
                                  >
                                    {/* Get name from assets - notification type */}
                                    &nbsp;&nbsp;&nbsp;
                                    {type.notification_type_desc}
                                  </option>
                                </React.Fragment>
                              )
                            );
                          })}
                        </React.Fragment>
                      );
                    }
                  )}
                </optgroup>
              );
            })}
          </select>
          {configsAlert[2] && (
            <div className="pt-2 px-2 flex text-sm">
              <span>
                {configsAlert[0] && (
                  <>
                    <span className="block sm:inline ">
                      <b className="text-gold">Category: </b>
                      {allCategories &&
                        //! Integrate assets
                        allCategories.find(
                          (category: BlockchainInfo) =>
                            category.id === (configsAlert[0] as CategoryType)
                        )!.key}
                    </span>
                  </>
                )}
                {configsAlert[1] && (
                  <>
                    <span className="block sm:inline">
                      <b className="text-gold"> Platform: </b>
                      {activeProjects &&
                        //! Integrate assets
                        activeProjects.find(
                          (project: BlockchainInfo) =>
                            project.id === (configsAlert[1] as PlatformType)
                        )!.key}
                    </span>
                  </>
                )}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
