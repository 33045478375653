import Loader, { LoaderType } from "components/Loader";
import {
  Blockchain,
  Category,
  Collection,
  HistoryReasons,
  HistoryStatus,
  MonitorOptions,
  NotificationTypes,
  PriceType,
  Project,
  Scope,
} from "models/Interfaces";
import React, { createContext, useContext, useEffect, useState } from "react";

import { getElementsFromCollections } from "models/utils/blockchainTranslations";
import { api } from "shared";

/* Interface creation */
export interface BlockchainDataContext {
  blockchains: Blockchain[] | null;
  projects: Project[] | null;
  categories: Category[] | null;
  collections: Collection[] | null;
  types: NotificationTypes[] | null;
  scopes: Scope[] | null;
  monitorOptions: MonitorOptions[] | null;
  priceTypes: PriceType[] | null;
  historyStatus: HistoryStatus[] | null;
  historyReasons: HistoryReasons[] | null;
  blockchainsInfo: any | null;
  projectsInfo: any[] | null;
}

/* Context */
const BlockchainDataContext = createContext<BlockchainDataContext>({
  blockchains: null,
  projects: null,
  categories: null,
  collections: null,
  types: null,
  scopes: null,
  monitorOptions: null,
  priceTypes: null,
  historyStatus: null,
  historyReasons: null,
  blockchainsInfo: null,
  projectsInfo: null,
});

/* Using Context*/
export const useContextBlockchainData = () => useContext(BlockchainDataContext);

/* Provider fn */
export const BlockchainDataProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [blockchains, setBlockchains] = useState<Blockchain[] | null>(null);
  const [blockchainsInfo, setBlockchainsInfo] = useState<any | null>(
    null
  ); /* Set as any[] because properties on assets are always changing (adding new) */
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [projectsInfo, setProjectsInfo] = useState<any[] | null>(
    null
  ); /* Set as any[] because properties on assets are always changing (adding new) */
  const [categories, setCategories] = useState<Category[] | null>(null);
  const [collections, setCollections] = useState<Collection[] | null>(null);
  const [types, setTypes] = useState<NotificationTypes[] | null>(null);
  const [scopes, setScopes] = useState<Scope[] | null>(null);
  const [monitorOptions, setMonitorOptions] = useState<MonitorOptions[] | null>(
    null
  );
  const [priceTypes, setPriceTypes] = useState<PriceType[] | null>(null);
  const [historyStatus, setHistoryStatus] = useState<HistoryStatus[] | null>(
    null
  );
  const [historyReasons, setHistoryReasons] = useState<HistoryReasons[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const getBlockchainDataFromDB = async () => {
    /* Get all information from DB, regarding chains, projects, categories and notification types */

    const response = await api.notifications.getInfo();

    setBlockchains(response.chains);
    setProjects(response.projects);
    setCollections(response.collections);
    setCategories(response.categories);
    setTypes(response.types);
    setScopes(response.scopes);
    setMonitorOptions(response.monitorOptions);
    setPriceTypes(response.priceTypes);
    setHistoryStatus(response.historyStatus);
    setHistoryReasons(response.historyReasons);
    /* Get blockchains information from assets */
    let blockchainInfo: any;
    try {
      const url = `${process.env.REACT_APP_ASSETS_REPO}${process.env.REACT_APP_ASSETS_CHAINS}${process.env.REACT_APP_ASSETS_CHAINS_LIST}`;
      const connection = await fetch(url, {
        method: "GET",
      });
      blockchainInfo = await connection.json();
      setBlockchainsInfo(blockchainInfo);
    } catch (error) {
      console.error(error);
    }
    /* Get projects information from assets */
    const blockchainsFromTypes = getElementsFromCollections(
      response.types,
      "chain"
    ).map((blockchain) => blockchain.key);
    let projectsInfoByBlockchain: any = {};
    await Promise.all(
      blockchainsFromTypes.map(async (blockchain: any) => {
        let bc = blockchain
          .split(" ")
          .map((b: string, i: number) =>
            i === 0 ? b[0].toLowerCase() + b.slice(1) : b
          )
          .join("");

        try {
          const url = `${process.env.REACT_APP_ASSETS_REPO}${process.env.REACT_APP_ASSETS_CHAINS}${bc}/${process.env.REACT_APP_ASSETS_PROJECTS_LIST}`;
          const connection = await fetch(url, {
            method: "GET",
          });
          const projectsInfo = await connection.json();
          projectsInfoByBlockchain[blockchain] = projectsInfo;
        } catch (error) {
          return;
        }
      })
    );
    setProjectsInfo(projectsInfoByBlockchain);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      await getBlockchainDataFromDB();
    };
    fetchData();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [blockchains]);

  const blockchainData = {
    blockchains,
    projects,
    categories,
    collections,
    types,
    scopes,
    monitorOptions,
    priceTypes,
    historyStatus,
    historyReasons,
    blockchainsInfo,
    projectsInfo,
  };

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}

      <BlockchainDataContext.Provider value={blockchainData}>
        {children}
      </BlockchainDataContext.Provider>
    </>
  );
};
