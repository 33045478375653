import React, { useState, useEffect, useCallback } from "react";
import Logo from "../components/Logo";
import PopUp, { PopupSize } from "../components/Popup";
import RightSectionLogin from "../components/RightSectionLogin";
import { NavLink, useNavigate } from "react-router-dom";
import SideMenu, { ModeType } from "../components/SideMenu";
import {
  useWallet,
  WalletStatus,
  SignBytesFailed,
  SignBytesResult,
  Timeout,
  useConnectedWallet,
  UserDenied,
} from "@terra-money/wallet-provider";
import ModalAuth from "../components/auth/ModalAuth";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Button, { ButtonSize, ButtonType } from "components/Button";
import {
  AuthFlow,
  ConnectWallet,
  WalletTypes,
} from "../components/auth/ConnectWallet";
import { TermsValidation } from "../components/auth/TermsValidation";
import { useContextUser } from "contexts/user";
import Loader, { LoaderType } from "components/Loader";
import { instanceOfUserData } from "utils/instanceOf";
import { Warning } from "../components/modals";
import QRCode from "qrcode";
import { MfaEnabling } from "../components/auth/MfaEnabling";
import SignupSectionButtons from "../components/auth/SignupSectionButtons";
import Z3us from "../assets/images/z3us.png";
import { useZ3usWallet } from "utils/hooks/use-z3us-wallet";
import { useXidarWallet } from "utils/hooks/use-xidar-wallet";
import { BlockchainType } from "models/Enums";

export enum SignUpStep {
  connectWallet = "connectWallet",
  verifyWallet = "verifyWallet",
  signUpCompleted = "signUpCompleted",
}

export enum ModalState {
  verify = "verify",
  success = "success",
  failure = "failure",
  hasUser = "hasUser",
  noUser = "noUser",
  noSocial = "noSocial",
  mfa = "mfa",
  mfaFailure = "mfaFailure",
  mfaValidate = "mfaValidate",
  guestUser = "guestUser",
}

export enum Network {
  columbus5 = "columbus-5",
  phoenix1 = "phoenix-1",
  pisco1 = "pisco-1",
}

/* Sign in component receives as props from the App the global variable that holds user data, updates it and sends back */
export const SignInPage: React.FC = () => {
  const { t } = useTranslation(["common", "enumerations"]);
  const { status, disconnect } = useWallet();
  const navigate = useNavigate();
  const connectedWallet = useConnectedWallet();
  const { setUser } = useContextUser();
  const { disconnectZ3us, signZ3us, isConnectedZ3us } = useZ3usWallet();
  const { disconnectXidar, signXidar, isConnectedXidar } = useXidarWallet();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(
    status === WalletStatus.WALLET_CONNECTED
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [currentStep, setCurrentStep] = useState(
    status === WalletStatus.WALLET_CONNECTED ||
      isConnectedZ3us ||
      isConnectedXidar
      ? SignUpStep.verifyWallet
      : SignUpStep.connectWallet
  );
  const [modalContent, setModalContent] = useState<null | ModalState>(null);
  const [randomBytes, setRandomBytes] = useState("");
  const [txError, setTxError] = useState<string | null>(null);
  const [tempUserData, setTempUserData] = useState<any>(null);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [showMfaEnabling, setShowMfaEnabling] = useState(false);
  const [z3usWallet, setZ3usWallet] = useState<string | null>(null);
  const [xidarWallet, setXidarWallet] = useState<string | null>(null);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const handleModal = (content: any) => {
    setIsOpenModal && setIsOpenModal(true);
    setModalContent(content);
  };

  const handleStep = (step: any) => {
    setCurrentStep && setCurrentStep(step);
  };

  const handleSetUser = () => {
    setTimeout(() => {
      setIsOpenModal(false);
      setModalContent(null);
      setUser(tempUserData);
    }, 3000);
  };

  const handleConnectWallet = async () => {
    setCurrentStep(SignUpStep.connectWallet);
    setIsOpenModal(true);
  };

  const handleVerifyWallet = async () => {
    let wallet;
    if (connectedWallet) {
      wallet = connectedWallet?.terraAddress;
    } else if (xidarWallet) {
      wallet = xidarWallet;
    } else if (z3usWallet) {
      wallet = z3usWallet;
    }

    if (wallet) {
      try {
        const connection = await fetch(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/auth/bytes`,
          {
            method: "POST",
            body: JSON.stringify({
              walletAddress: wallet,
            }),
            credentials: "include",
          }
        );
        const dataFromApi = await connection.json();
        setRandomBytes(dataFromApi.data.bytes);
        if (connectedWallet?.connection.identifier === WalletTypes.station) {
          await signBytes(dataFromApi.data.bytes);
        } else if (z3usWallet) {
          try {
            const originalBytes = dataFromApi.data.bytes;
            const signedMessage = await signZ3us(originalBytes);
            wallet &&
              handleSignUp(
                wallet,
                signedMessage,
                originalBytes,
                BlockchainType.Radix
              );
          } catch (error) {
            setTxError("Sign failed: " + error);
            handleModal(ModalState.failure);
          }
        }
      } catch (error) {
        setTxError("Error verifying wallet");
        handleModal(ModalState.failure);
      }
    } else {
      setTxError("No wallet");
      handleModal(ModalState.failure);
    }
  };

  const handleSignUp = async (
    walletAddress: string,
    signBytesResult: any,
    originalBytes: string,
    chainId: BlockchainType
  ) => {
    setIsLoading(true);
    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/auth/signup/webapp`,
        {
          method: "POST",
          body: JSON.stringify({
            walletAddress,
            chainId,
            signBytesResult,
            originalBytes,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      if (typeof dataFromApi.data === "string") {
        handleModal(ModalState.hasUser);
      } else if (dataFromApi.data.error) {
        setTxError(dataFromApi.data.error);
        handleModal(ModalState.failure);
      } else {
        setTempUserData(dataFromApi.data);
        QRCode.toDataURL(
          dataFromApi.data.temp_secret.otpauth_url,
          function (err, data_url) {
            setQrCode(data_url);
            handleModal(ModalState.mfa);
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleLoggedInUser = async () => {
    setIsOpenModal(false);
    setIsLoading(true);
    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/auth/mfa-verify`,
        {
          method: "POST",
          body: JSON.stringify({
            userId: tempUserData.userId,
            token,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      if (connection.status === 400) {
        setTxError(dataFromApi.data.error);
        handleModal(ModalState.mfaFailure);
      } else if (
        typeof dataFromApi.data === "object" &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setTempUserData(dataFromApi.data);
        handleModal(ModalState.success);
        handleStep(SignUpStep.signUpCompleted);
      } else {
        handleModal(ModalState.failure);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const signBytes = useCallback(
    async (originalBytes) => {
      if (!connectedWallet) {
        return;
      }
      try {
        const bufferedBytes = Buffer.from(originalBytes);
        /* Sign bytes with bytes fetched from API to this specific wallet */
        const signedBytes: SignBytesResult = await connectedWallet.signBytes(
          bufferedBytes
        );

        /* Send information from signing to API to verify the transaction */
        handleSignUp(
          connectedWallet.terraAddress,
          signedBytes.result,
          originalBytes,
          BlockchainType.Terrav2
        );
      } catch (error) {
        if (error instanceof UserDenied) {
          setTxError("User Denied");
        } else if (error instanceof Timeout) {
          setTxError("Timeout");
        } else if (error instanceof SignBytesFailed) {
          setTxError("Sign Bytes Failed");
        } else {
          setTxError(
            "Unknown Error: " +
              (error instanceof Error ? error.message : String(error))
          );
        }
        handleModal(ModalState.failure);
      }
    },
    [connectedWallet]
  );

  const handleDisconnect = () => {
    disconnect();
    disconnectZ3us();
    disconnectXidar();
    setIsOpenModal(false);
    setModalContent(null);
  };

  const handleLogin = () => {
    setIsOpenModal(false);
    setModalContent(null);
    navigate("/");
  };

  const handleResetModal = () => {
    setTimeout(() => {
      setIsOpenModal(false);
      setModalContent(null);
    }, 2000);
  };

  const handleWarningText = () => {
    if (txError) {
      return txError;
    } else {
      return t("modal_content.failure.modal_title", {
        ns: "enumerations",
      });
    }
  };

  const handleTryAgain = () => {
    setTimeout(() => {
      disconnect();
      disconnectZ3us();
      disconnectXidar();
      setCurrentStep(SignUpStep.connectWallet);
      setIsOpenModal(false);
      setModalContent(null);
    }, 2000);
  };

  const handleTryMfaAgain = () => {
    setTimeout(() => {
      handleModal(ModalState.mfa);
    }, 2000);
  };

  const handleStatus = () => {
    if (
      isConnectedXidar ||
      isConnectedZ3us ||
      status === WalletStatus.WALLET_CONNECTED
    ) {
      return WalletStatus.WALLET_CONNECTED;
    } else {
      return WalletStatus.WALLET_NOT_CONNECTED;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <div className="flex flex-grow">
        <SideMenu authPage={true} mode={ModeType.wide}>
          <Logo />
          <h2 className="self-center text-md md:text-lg">
            {t("signup", {
              ns: "common",
            })}
          </h2>
          <SignupSectionButtons
            walletStatus={handleStatus()}
            onConnectWalletClick={handleConnectWallet}
            onVerifyWalletClick={handleVerifyWallet}
            currentStep={currentStep}
          />
          <p className="self-center static mt-10 text-sm">
            {t("modal_content.has_account", { ns: "enumerations" })}
            <NavLink to="/" className={"text-muddywaters-500"}>
              {t("login", {
                ns: "common",
              })}
            </NavLink>
          </p>
        </SideMenu>

        <RightSectionLogin />

        {isOpenModal && (
          <>
            {currentStep === SignUpStep.connectWallet && (
              <PopUp
                size={PopupSize.large}
                closeModal={() => setIsOpenModal(false)}
              >
                {width < 1024 && (
                  <>
                    {!isTermsChecked && (
                      <ModalAuth
                        modalTitle={t(
                          "modal_content.connect_hermes.modal_title",
                          { ns: "enumerations" }
                        )}
                        additionalTitle={t(
                          "modal_content.connect_hermes.additional_title",
                          { ns: "enumerations" }
                        )}
                        logo={true}
                      >
                        <TermsValidation
                          setIsTermsChecked={setIsTermsChecked}
                          isTermsChecked={isTermsChecked}
                        />
                      </ModalAuth>
                    )}
                    {isTermsChecked && (
                      <div className="flex flex-col">
                        <ConnectWallet
                          setCurrentStep={setCurrentStep}
                          setIsOpenModal={setIsOpenModal}
                          setModalContent={setModalContent}
                          modalContent={modalContent}
                          authFlow={AuthFlow.signup}
                          setZ3usWallet={setZ3usWallet}
                          setXidarWallet={setXidarWallet}
                        />

                        <div id="back-button">
                          <Button
                            type={ButtonType.cancel}
                            size={ButtonSize.small}
                            onClick={() => setIsTermsChecked(false)}
                          >
                            <div>
                              <FontAwesomeIcon icon={faChevronLeft} />
                            </div>
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {width >= 1024 && (
                  <ModalAuth
                    modalTitle={t("modal_content.connect_hermes.modal_title", {
                      ns: "enumerations",
                    })}
                    additionalTitle={t(
                      "modal_content.connect_hermes.additional_title",
                      { ns: "enumerations" }
                    )}
                    logo={true}
                  >
                    <TermsValidation
                      setIsTermsChecked={setIsTermsChecked}
                      isTermsChecked={isTermsChecked}
                    />
                    {isTermsChecked && (
                      <ConnectWallet
                        setCurrentStep={setCurrentStep}
                        setIsOpenModal={setIsOpenModal}
                        setModalContent={setModalContent}
                        modalContent={modalContent}
                        authFlow={AuthFlow.signup}
                        setZ3usWallet={setZ3usWallet}
                        setXidarWallet={setXidarWallet}
                      />
                    )}
                  </ModalAuth>
                )}
              </PopUp>
            )}
            {modalContent === ModalState.verify && (
              <PopUp size={PopupSize.large}>
                <ModalAuth
                  modalTitle={t("modal_content.verify_wallet.modal_title", {
                    ns: "enumerations",
                  })}
                  additionalTitle={t(
                    "modal_content.verify_wallet.additional_title",
                    {
                      ns: "enumerations",
                    }
                  )}
                  logo={true}
                >
                  {handleResetModal()}
                </ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.success && (
              <PopUp size={PopupSize.large}>
                <ModalAuth
                  modalTitle={t("modal_content.verified_wallet", {
                    ns: "enumerations",
                  })}
                  logo={true}
                >
                  {handleSetUser()}
                </ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.failure && (
              <PopUp size={PopupSize.large}>
                <Warning
                  title={"Warning"}
                  text={handleWarningText()}
                  iconWarning={true}
                />
                {handleTryAgain()}
              </PopUp>
            )}
            {modalContent === ModalState.hasUser && (
              <PopUp size={PopupSize.large}>
                <ModalAuth
                  modalTitle={t(
                    "modal_content.user_already_exists.modal_title",
                    {
                      ns: "enumerations",
                    }
                  )}
                  additionalTitle={t(
                    "modal_content.user_already_exists.additional_title",
                    { ns: "enumerations" }
                  )}
                  logo={true}
                  primaryButton={t(
                    "modal_content.user_already_exists.primary_button",
                    { ns: "enumerations" }
                  )}
                  secondaryButton={t(
                    "modal_content.user_already_exists.secondary_button",
                    { ns: "enumerations" }
                  )}
                  primaryButtonOnClick={handleDisconnect}
                  secondaryButtonOnClick={handleLogin}
                ></ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.mfa && qrCode && (
              <PopUp size={PopupSize.largeXL}>
                <ModalAuth
                  modalTitle={t("modal_content.mfa.modal_title", {
                    ns: "enumerations",
                  })}
                  logo={true}
                >
                  <h3 className="font-normal w-11/12 md:w-8/12 text-center mb-5">
                    {t("modal_content.mfa.additional_title", {
                      ns: "enumerations",
                    })}
                    .{" "}
                    <a
                      href="https://docs.hermesprotocol.io/docs/webapp/2FA/"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      {t("modal_content.mfa.learn_more", {
                        ns: "enumerations",
                      })}
                    </a>
                  </h3>
                  <label className="switch mb-5">
                    <input
                      type="checkbox"
                      onChange={() => setShowMfaEnabling(!showMfaEnabling)}
                    />
                    <span className={"slider round checked:bg-black"}></span>
                  </label>
                  {showMfaEnabling && (
                    <MfaEnabling
                      qrCode={qrCode}
                      tempUserData={tempUserData}
                      setToken={setToken}
                    />
                  )}
                  <div className="text-right pt-5">
                    <Button
                      onClick={() => handleLoggedInUser()}
                      disabled={
                        showMfaEnabling && (!token || token?.length !== 6)
                      }
                      className="text-white bg-gold active:bg-yellow-700 text-base px-6 py-2 rounded shadow lg:hover:shadow-lg outline-none focus:outline-none "
                    >
                      {t("signup", {
                        ns: "common",
                      })}
                    </Button>
                  </div>
                </ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.mfaFailure && (
              <PopUp size={PopupSize.large}>
                <Warning
                  title={"Warning"}
                  text={handleWarningText()}
                  iconWarning={true}
                />
                {handleTryMfaAgain()}
              </PopUp>
            )}
          </>
        )}
      </div>
    </>
  );
};
