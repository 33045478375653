import React, { useEffect } from "react";
import Notification from "models/Notification";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faShare, faTags } from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faTelegramPlane,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { ContactListData, UserSubscription } from "models/Interfaces";
import classNames from "classnames";



const SubscriptionCard: React.FC<{
  subscription: UserSubscription;
  contactListData: ContactListData;
  handleEditButton(): void;
}> = ({ subscription, contactListData, handleEditButton }) => {
  const { t } = useTranslation(["common", "enumerations"]);

  const hasSocials =
    !!subscription.channels;
  return (
    <div className="w-full p-4 flex sm:w-1/2 xl:w-1/3 min-h-xs">
      <div
        className={classNames(
          "w-full relative rounded-2xl bg-white shadow-white shadow-lg overflow-hidden p-4 flex flex-col flex-grow",
          { "bg-gray-extralight30": subscription.status === 0 }
        )}
      >
        <div className={`flex flex-col h-full`}>
          {/* Project logo and settings global to all types */}
          <header className="flex justify-between items-start z-10 mb-2">
            <div className="flex flex-col">
              {contactListData.client.imageUrl && (
                <span className="h-10 w-10 rounded-full overflow-hidden block">
                  <img
                    src={contactListData.client.imageUrl}
                    alt={contactListData.client.brandName}
                  />
                </span>
              )}
              <h4 className="mt-4 h-1/6">
                {contactListData.client.brandName}
              </h4>
            </div>
            <button
              onClick={handleEditButton}
              className={classNames(`h-9 w-9 bg-white text-gold rounded-full hover:shadow-inner-2xl cursor-pointer transition duration-200`,
                { "shadow-inner-2xl": subscription.status !== 0 })}
              aria-label="Edit notification"
            >
              <FontAwesomeIcon icon={faCog} className="bg-transparent" />
            </button>
          </header>
          <div className="flex items-center">
            <span className="text-gray-semibold">
              <FontAwesomeIcon
                icon={faTags}
                className="bg-transparent"
                size="sm"
                style={{ marginRight: "10px" }}
              />
            </span>
            <h6 className="font-medium text-xsm">
              {contactListData.list.namePublic}
            </h6>
          </div>
          <div className="flex items-center transition h-full">
            <div className="flex items-center justify-between">
              <div></div>
              {contactListData.list.imageUrl && (
                <img
                  src={contactListData.list.imageUrl}
                  alt=""
                  width={70}
                  className="w-2/8 rounded"
                />
              )}
            </div>
          </div>
          {/* Social channels global to all types */}
          <div className="z-10 h-2/8 mt-2">
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold py-1 pb-3">
                {t("social_channels", { ns: "common" })}
              </span>
              <div className="flex justify-between w-full">
                <div className="text-xl flex flex-row gap-3">
                  {!hasSocials && (
                    <p className="text-xsm">
                      {t("no_social_alerts_set", "common")}{" "}
                    </p>
                  )}
                  {hasSocials && (
                    <>
                      {subscription.channels?.some((social) => social.channel === "discord")
                        && (
                          <FontAwesomeIcon
                            className="text-discord"
                            icon={faDiscord}
                          />
                        )}
                      {subscription.channels?.some((social) => social.channel === "telegram")
                        && (
                          <FontAwesomeIcon
                            className="text-telegram"
                            icon={faTelegramPlane}
                          />
                        )}
                      {subscription.channels?.some((social) => social.channel === "twitter")
                        && (
                          <FontAwesomeIcon
                            className="text-twitter"
                            icon={faTwitter}
                          />
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
