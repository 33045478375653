import Loader, { LoaderType } from "components/Loader";
import { useEffect, useState } from "react";

import { useContextUser } from "contexts/user";
import { api } from "shared";
import { instanceOfUserData } from "utils/instanceOf";
import { SettingsTypes } from "views/Settings";

interface Props {
  id?: string;
  updateView?: () => void;
  title?: string;
  type?: string;
  textbtn?: string;
  inputLabel?: string;
}

export const EditModal = ({
  id,
  updateView,
  title,
  type,
  textbtn,
  inputLabel,
}: Props) => {
  const [newData, setNewData] = useState<string>("");
  const { userData, setUser } = useContextUser();
  const [isLoading, setIsLoading] = useState(false);

  const returnAliasByWalletAddress = (id: string | undefined) => {
    if (!id) return;
    return (
      userData?.wallets.filter((wallet) => wallet.walletAddress === id)[0]
        ?.walletAlias ?? ""
    );
  };

  const editWallet = async () => {
    setIsLoading(true);

    const response = await api.wallet.edit(
      {
        walletAlias: newData.trim(),
      },
      id!
    );

    if (response?.error) {
      console.error(response.error);
    } else {
      if (typeof response === "object" && instanceOfUserData(response)) {
        setUser(response);
      }
    }
    setNewData("");
    updateView && updateView();
  };

  useEffect(() => {
    setIsLoading(false);
  }, [userData]);

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}

      <div className="flex items-start justify-between border-solid border-gray-300 rounded-t ">
        <h5 className="text-xl font-bold px-3">{title}</h5>
      </div>
      <div className="relative px-1 flex-auto">
        <form className="rounded py-5 w-full ">
          <label className="block px-2  text-black text-base font-medium mb-1">
            {inputLabel}
          </label>
          <input
            onChange={(e) => setNewData(e.target.value)}
            className="appearance-none outline-none focus:outline-none border text-base rounded w-full py-2 2xl:h-10 h-9 px-3 border-gray-extralight90medium text-black-transparent50"
            defaultValue={returnAliasByWalletAddress(id)}
            maxLength={10}
          />
        </form>
        <div className="text-right pt-5">
          <button
            onClick={() => type === SettingsTypes.wallets && editWallet()}
            className="text-white bg-gold active:bg-yellow-700 text-base px-6 py-2 rounded shadow lg:hover:shadow-lg outline-none focus:outline-none "
            type="button"
          >
            {textbtn}
          </button>
        </div>
      </div>
    </>
  );
};
