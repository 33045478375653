export const TradeSvg = () => {
    return (
      <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.37858 3.49841C4.14652 3.49841 3.92396 3.5906 3.75986 3.75469C3.59577 3.91879 3.50358 4.14135 3.50358 4.37341L3.50358 14.5147L1.49983 12.5022C1.33506 12.3374 1.11159 12.2448 0.87858 12.2448C0.645566 12.2448 0.422096 12.3374 0.25733 12.5022C0.0925645 12.6669 1.73609e-09 12.8904 0 13.1234C-1.73609e-09 13.3564 0.0925645 13.5799 0.25733 13.7447L3.75733 17.2447C3.88038 17.3661 4.03663 17.4483 4.20637 17.481C4.37611 17.5137 4.55173 17.4954 4.71108 17.4284C4.87087 17.3628 5.00766 17.2513 5.1042 17.108C5.20074 16.9648 5.25272 16.7962 5.25358 16.6234L5.25358 4.37341C5.25358 4.14135 5.16139 3.91879 4.9973 3.75469C4.8332 3.5906 4.61064 3.49841 4.37858 3.49841ZM7.54608 0.0684128C7.38629 0.134056 7.2495 0.245529 7.15296 0.388784C7.05642 0.53204 7.00444 0.700666 7.00358 0.873413L7.00358 13.1234C7.00358 13.3555 7.09577 13.578 7.25986 13.7421C7.42396 13.9062 7.64652 13.9984 7.87858 13.9984C8.11064 13.9984 8.3332 13.9062 8.4973 13.7421C8.66139 13.578 8.75358 13.3555 8.75358 13.1234L8.75358 2.98216L10.7573 4.99466C10.8387 5.07667 10.9354 5.14177 11.0421 5.18619C11.1487 5.23062 11.2631 5.25349 11.3786 5.25349C11.4941 5.25349 11.6085 5.23062 11.7151 5.18619C11.8217 5.14177 11.9185 5.07667 11.9998 4.99466C12.0818 4.91332 12.1469 4.81654 12.1914 4.70992C12.2358 4.60329 12.2587 4.48892 12.2587 4.37341C12.2587 4.2579 12.2358 4.14354 12.1914 4.03691C12.1469 3.93028 12.0818 3.83351 11.9998 3.75216L8.49983 0.252165C8.37679 0.130751 8.22053 0.0485039 8.05079 0.0158024C7.88105 -0.016901 7.70543 0.00140572 7.54608 0.0684128Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
    );
}