import { ConnectType, useWallet, WalletStatus } from "@terra-money/use-wallet";
import { ModalState, SignUpStep } from "../../views/SignIn";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { WalletSettingsState } from "../modals/WalletsFlow";
import { LoginStep } from "views/Login";
import Loader, { LoaderType } from "components/Loader";
import { ConnectReadonlyButton } from "components/auth/ConnectReadonlyButton";
import { ConnectExtensionButton } from "components/auth/ConnectExtensionButton";
import Button, { ButtonSize, ButtonType } from "components/Button";
import Z3us from "../../assets/images/z3us.png";
import xidar from "../../assets/images/xidar.png";
import { useZ3usWallet } from "utils/hooks/use-z3us-wallet";
import { useXidarWallet } from "utils/hooks/use-xidar-wallet";

export enum AuthFlow {
  signup = "signup",
  login = "login",
}

/* Available wallet types. Update whenever new types of wallet connections are allowed */
export enum WalletTypes {
  station = "station",
  /* xdefiWallet = "xdefi-wallet",
  walletConnect = "WALLETCONNECT",
  leapWallet = "leap-wallet",
  falconWallet = "falcon-wallet",
  bitkeepWallet = "bitkeep-wallet" */
}

export const ConnectWallet: React.FC<{
  setModalContent: Dispatch<SetStateAction<any>>;
  modalContent?: string | null;
  setIsOpenModal?: Dispatch<SetStateAction<any>>;
  setCurrentStep?: Dispatch<SetStateAction<any>>;
  walletFlow?: boolean;
  authFlow?: AuthFlow;
  setNewWallet?: Dispatch<SetStateAction<any>>;
  newWallet?: string | null;
  setZ3usWallet?: Dispatch<SetStateAction<any>>;
  setXidarWallet?: Dispatch<SetStateAction<any>>;
}> = ({
  setCurrentStep,
  setIsOpenModal,
  setModalContent,
  modalContent,
  walletFlow = false,
  authFlow,
  setNewWallet,
  newWallet,
  setZ3usWallet,
  setXidarWallet,
}) => {
  const { availableConnections, availableInstallations, status, wallets } =
    useWallet();
  const { isConnectedZ3us, connectZ3us, hasWalletZ3us } = useZ3usWallet();
  const { connectXidar, isConnectedXidar, hasWalletXidar } = useXidarWallet();

  const [isLoading, setIsLoading] = useState(false);
  const [hasUser, setHasUser] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [hasXidarInstalled, setHasXidarInstalled] = useState<boolean | null>(
    null
  );
  const [hasZ3usInstalled, setHasZ3usInstalled] = useState<boolean | null>(
    null
  );

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const checkUserExists = async () => {
    setIsLoading(true);
    let z3usWallet: string | undefined;
    if (isConnectedZ3us) {
      z3usWallet = await connectZ3us();
      setZ3usWallet && setZ3usWallet(z3usWallet);
    }
    let wallet;
    if (wallets[0]) {
      wallet = wallets[0].terraAddress;
    } else if (z3usWallet) {
      wallet = z3usWallet;
    }
    /* This request returns a boolean, which indicates if a user with the connected wallet already exists */
    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/user/check/webapp/${wallet}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      setHasUser(dataFromApi.data.user);
      setIsLoading(false);
      return dataFromApi.data.user;
    } catch (error) {
      setIsLoading(false);
      setModalContent(ModalState.failure);
      return;
    }
  };

  const handleModal = (content: any) => {
    setIsOpenModal && setIsOpenModal(true);
    setModalContent(content);
  };

  const handleStep = (step: any) => {
    setCurrentStep && setCurrentStep(step);
  };

  const handleConnectZ3us = async () => {
    const wallet = await connectZ3us();
    setNewWallet && setNewWallet(wallet);
    setZ3usWallet && setZ3usWallet(wallet);
  };

  useEffect(() => {
    const checkHasWallet = async () => {
      const hasZ3us = await hasWalletZ3us();
      const hasXidar = await hasWalletXidar();
      hasZ3us && setHasZ3usInstalled(true);
      hasXidar && setHasXidarInstalled(true);
    };
    checkHasWallet();
  }, [window.z3us, window.xidar]);

  useEffect(() => {
    if (window.location.pathname !== "/settings") {
      if (
        status === WalletStatus.WALLET_CONNECTED ||
        isConnectedXidar ||
        isConnectedZ3us
      ) {
        setIsLoading(true);
        checkUserExists().then((user) => {
          if (authFlow === AuthFlow.signup && user) {
            handleModal(ModalState.hasUser);
          } else if (authFlow === AuthFlow.signup && !user) {
            handleModal(ModalState.verify);
            handleStep(SignUpStep.verifyWallet);
          } else if (authFlow === AuthFlow.login && user) {
            handleStep(LoginStep.verifyWallet);
          } else {
            handleModal(ModalState.noUser);
          }
        });
      }
    } else if (newWallet !== null) {
      setModalContent(WalletSettingsState.ongoing);
    } else if (newWallet === null && isConnectedZ3us) {
      const getWallet = async () => {
        const wallet = await connectZ3us();
        if (setNewWallet && wallet) {
          setNewWallet(wallet);
          setZ3usWallet && setZ3usWallet(wallet);
          setModalContent(WalletSettingsState.ongoing);
        }
      };
      getWallet();
    }
  }, [status, isConnectedXidar, isConnectedZ3us]);

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <div
        className={` ${
          walletFlow ? "flex-col items-center " : "justify-evenly"
        } lg:flex flex-wrap w-full `}
      >
        {/* Searches through all available wallet connections of user and displays them */}
        {availableConnections
          .filter(
            ({ type, identifier }) =>
              type !== ConnectType.READONLY &&
              Object.values(WalletTypes).find((w) => w === identifier)
          )
          .map(({ type, icon, name, identifier }) => (
            <ConnectReadonlyButton
              type={type}
              key={Math.random() * 0.6}
              identifier={identifier}
              name={name}
              icon={icon}
              setNewWallet={setNewWallet}
              disabled={
                isLoading ||
                (window.location.pathname !== "/settings" &&
                  status === WalletStatus.WALLET_CONNECTED)
              }
            />
          ))}
        {/* {hasZ3usInstalled && (
          <Button
            noFlexMobile={true}
            type={ButtonType.secondary}
            size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
            fullWidth={width < 1024 ? true : false}
            onClick={handleConnectZ3us}
            className={`md:mx-0 ${walletFlow ? "my-3" : "my-6"}`}
            disabled={
              isLoading ||
              (window.location.pathname !== "/settings" &&
                status === WalletStatus.WALLET_CONNECTED)
            }
          >
            <img src={Z3us} className={"inline w-6"} alt={`Z3us Wallet`} />
            <span className="pl-2">Z3us</span>
          </Button>
        )} */}
        {/* {hasXidarInstalled && (
          <Button
            noFlexMobile={true}
            type={ButtonType.secondary}
            size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
            fullWidth={width < 1024 ? true : false}
            onClick={async () => connectXidar()}
            className={`md:mx-0 ${walletFlow ? "my-3" : "my-6"}`}
            disabled={
              isLoading ||
              (window.location.pathname !== "/settings" &&
                status === WalletStatus.WALLET_CONNECTED)
            }
          >
            <img src={xidar} className={"inline w-6"} alt={`Z3us Wallet`} />
            <span className="pl-2">Xidar</span>
          </Button>
        )} */}

        {/* Searches through all available wallet installations of user and displays them */}
        {availableInstallations
          .filter(
            ({ type, identifier }) =>
              type === ConnectType.EXTENSION &&
              Object.values(WalletTypes).find((w) => w === identifier)
          )
          .map(({ type, identifier, name, icon, url }) => (
            <ConnectExtensionButton
              type={type}
              identifier={identifier}
              name={name}
              icon={icon}
              url={url}
              key={Math.random() * 0.8}
              disabled={isLoading || status === WalletStatus.WALLET_CONNECTED}
            />
          ))}
        {/* {!hasZ3usInstalled && (
          <a
            href={
              "https://chrome.google.com/webstore/detail/z3us/icpikagpkkbldbfjlbefnmmmcohbjije"
            }
            target="_blank"
            rel="noreferrer"
          >
            <Button
              noFlexMobile={true}
              type={ButtonType.secondary}
              size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
              fullWidth={width < 1024 ? true : false}
              className={`md:mx-0 ${walletFlow ? "my-3" : "my-6"}`}
              disabled={
                isLoading ||
                (window.location.pathname !== "/settings" &&
                  status === WalletStatus.WALLET_CONNECTED)
              }
            >
              <img src={Z3us} className={"inline w-6"} alt={`Z3us Wallet`} />
              <span className="pl-2">Install Z3us</span>
            </Button>
          </a>
        )} */}
        {/* {!hasXidarInstalled && (
          <a
            href={
              "https://chrome.google.com/webstore/detail/xidar-wallet-for-radixdlt/hgnpaljkalilofmmbhgkkldbdbogddlo"
            }
            target="_blank"
            rel="noreferrer"
          >
            <Button
              noFlexMobile={true}
              type={ButtonType.secondary}
              size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
              fullWidth={width < 1024 ? true : false}
              className={`md:mx-0 ${walletFlow ? "my-3" : "my-6"}`}
              disabled={
                isLoading ||
                (window.location.pathname !== "/settings" &&
                  status === WalletStatus.WALLET_CONNECTED)
              }
            >
              <img src={xidar} className={"inline w-6"} alt={`Z3us Wallet`} />
              <span className="pl-2">Install Xidar</span>
            </Button>
          </a>
        )} */}
      </div>
    </>
  );
};
