import React, { useEffect, useState } from "react";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { Blockchain } from "models/Interfaces";

const BlockchainImage: React.FC<{
  blockchainId: string;
  showName: boolean;
  imageWidth?: number;
  imageHeight?: number;
}> = ({ blockchainId, showName, imageWidth = 50, imageHeight = 50 }) => {
  const { blockchainsInfo, blockchains } = useContextBlockchainData();

  const [isLoading, setIsLoading] = useState(false);
  const [blockchainIcon, setBlockchainIcon] = useState<string | null>(null);
  const [blockchainName, setBlockchainName] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);
    if (blockchains) {
      const blockchain = blockchains.find((blockchain: Blockchain) => blockchain.chain_id === blockchainId);
      if (blockchain) {
        setBlockchainIcon(blockchain.image_url);
        setBlockchainName(blockchain.chain_desc);
      } else {
        //! bellow is an easy workaround while waiting for assets revolution - backup way
        let blockchainDesc: any = blockchains?.filter(
          (chain) => chain.chain_id === blockchainId
        )[0].chain_desc;


        let blockchain = blockchainDesc
          .split(" ")
          .map((b: string, i: number) =>
            i === 0 ? b[0].toLowerCase() + b.slice(1) : b
          )
          .join("");

        if (blockchainsInfo) {
          setBlockchainIcon(blockchainsInfo![blockchain].icon);
          setBlockchainName(blockchainsInfo![blockchain].name);
        }
      }
      setIsLoading(false);

    }
  }, [blockchainsInfo]);

  return (
    <>
      {isLoading && <div className="loading-ring gray-dark"></div>}
      {!isLoading && (
        <div className="flex items-center">
          <img
            src={blockchainIcon!}
            width={imageWidth}
            height={imageHeight}
            className={"mx-1 rounded-full"}
            alt={blockchainName!}
          />
          {showName && (
            <p className="font-medium text-black text-base align-middle ml-2">
              {blockchainName}
            </p>
          )}
        </div>
      )}
    </>
  );
};
export default BlockchainImage;
