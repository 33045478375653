import React, { useEffect, useState } from "react";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserNotification } from "models/Interfaces";
import { showShortURL } from "../utils";
import { terra, terraClassic } from "shared";
import { BlockchainType, ScopeNotification } from "models/Enums";
import { useTranslation } from "react-i18next";
import { CopyButton } from "components/CopyButton";

const ProfileChangesCard: React.FC<{
  scope: string;
  copyToClipboard: Function;
  notification: UserNotification;
  getTypeDesc: Function;
  copy: boolean;
}> = ({ scope, copyToClipboard, notification, getTypeDesc, copy }) => {
  const { t } = useTranslation(["common", "enumerations", ""]);

  const [validatorName, setValidatorName] = useState(
    showShortURL(notification?.validatorAddress?.[0])
  );
  const [validatorLogo, setValidatorLogo] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);

  let blockchainIcon;

  //TODO we need to make this piece of code dynamic and blockchain agnostic
  switch (notification.chainId) {
    case BlockchainType.Terrav2:
      blockchainIcon =
        "https://assets.hermesprotocol.io/img/projects/Terra.png";
      break;
    case BlockchainType.TerraClassic:
      blockchainIcon =
        "https://assets.hermesprotocol.io/img/projects/TerraClassic.png";
      break;
  }

  useEffect(() => {
    setIsLoading(true);
    let blockchain = notification.chainId;
    let lcd;

    switch (blockchain) {
      case BlockchainType.Terrav2:
        lcd = terra;
        break;
      case BlockchainType.TerraClassic:
        lcd = terraClassic;
        break;
    }

    lcd?.staking
      .validator(notification?.validatorAddress?.[0])
      .then((res) => {
        setValidatorName(
          res.description.moniker.length > 35
            ? `${res.description.moniker.slice(0, 35)}...`
            : res.description.moniker
        );
        let validatorIdentity = res.description.identity;
        if (validatorIdentity.length > 0) {
          const getLogo = async () => {
            const keybase = await fetch(
              `https://keybase.io/_/api/1.0/user/lookup.json?key_suffix=${validatorIdentity}&fields=pictures`
            );
            const res = await keybase.json();
            setValidatorLogo(res.them[0].pictures.primary.url);
          };
          getLogo();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }, [notification]);

  return (
    <div className="flex flex-col justify-between z-10 h-5/8">
      <h4 className="mb-2 h-1/6">{getTypeDesc(notification.notificationTypeId.slice(-4))}</h4>
      <div className="flex items-center h-1/6">
        {isLoading && <div className="loading-ring gray-dark"></div>}
        {!isLoading && (
          <>
            <span className="text-gray-semibold">
              <FontAwesomeIcon
                icon={faTags}
                className="bg-transparent"
                size="sm"
                style={{ marginRight: "10px" }}
              />
            </span>
            <h6 className="font-semibold">
              {notification.scope === ScopeNotification.unique &&
                t("notifications.unique_validator", { ns: "common" })}
              {notification.scope === ScopeNotification.myWallet &&
                t("notifications.my_wallet_validator", { ns: "common" })}
            </h6>
          </>
        )}
      </div>
      <div
        className="flex items-center transition h-4/6"
      >
        <div className="flex items-center w-3/4">
          {isLoading && <div className="loading-ring gray-dark"></div>}
          {!isLoading && (
            <p className="text-sm">
              {notification.scope === ScopeNotification.unique && validatorName}
              {notification.scope === ScopeNotification.myWallet &&
                showShortURL(notification.walletAddress)}
            </p>
          )}

          <CopyButton
            hoverText={t("notifications.copy_address", "common")}
            clickText={t("notifications.copied", "common")}
            isCopying={copy}
            onCopy={copyToClipboard}
            copiedElement={notification.scope === ScopeNotification.myWallet
              ? notification?.walletAddress
              : notification?.validatorAddress?.[0]
            }
          />
        </div>
        {scope === ScopeNotification.unique && isLoading && (
          <div className="loading-ring gray-dark"></div>
        )}
        {scope === ScopeNotification.unique && !isLoading && (
          <img
            src={validatorLogo ? validatorLogo : blockchainIcon}
            alt=""
            width={70}
            className="w-2/8 rounded"
          />
        )}
      </div>
    </div>
  );
};
export default ProfileChangesCard;
