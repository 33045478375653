import Account from "components/Account";
import Logo from "components/Logo";
import SideMenu, { ModeType } from "components/SideMenu";
import SideMenuItem from "components/SideMenuItem";
import { Dispatch, SetStateAction } from "react";
import { Tooltip } from "./Tooltip";
import { useTranslation } from "react-i18next";
import { dashboardUrl, historyUrl, messagesUrl, notificationsUrl, p2pTradeUrl, settingsUrl, subscriptionsUrl } from "utils/menuUtils";
import { DashboardSvg } from "./svgs/NotificationsSvg";
import { NotificationsSvg } from "./svgs/DashboardSvg";
import { HistorySvg } from "./svgs/HistorySvg";
import { MessagesSvg } from "./svgs/MessagesSvg";
import { TradeSvg } from "./svgs/TradeSvg";
import { SettingsSvg } from "./svgs/SettingsSvg";
import { SubscriptionsSvg } from "./svgs/SubscriptionsSvg";

export const Navbar: React.FC<{
  setIsCondensed: Dispatch<SetStateAction<any>>;
  isCondensed: boolean;
}> = ({ setIsCondensed, isCondensed }) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="fixed">
      <SideMenu mode={isCondensed ? ModeType.condensed : ModeType.normal}>
        <Logo
          onClick={() => setIsCondensed(!isCondensed)}
          condensed={isCondensed}
        />
        <Tooltip content={t("menu.coming_soon", { ns: "common" })}>
          <SideMenuItem
            icon={<DashboardSvg />}
            title={t("menu.dashboard", { ns: "common" })}
            url={dashboardUrl}
            condensed={isCondensed}
            disabled={true}
          />
        </Tooltip>
        <SideMenuItem
          icon={<NotificationsSvg />}
          title={t("menu.notifications", { ns: "common" })}
          url={notificationsUrl}
          condensed={isCondensed}
          beta={true}
        />
        <SideMenuItem
          icon={<SubscriptionsSvg />}
          title={t("menu.subscriptions", { ns: "common" })}
          url={subscriptionsUrl}
          condensed={isCondensed}
          beta={true}
        />
        <SideMenuItem
          icon={<HistorySvg />}
          title={t("menu.history", { ns: "common" })}
          url={historyUrl}
          condensed={isCondensed}
          beta={true}
        />
        <Tooltip content={t("menu.coming_soon", { ns: "common" })}>
          <SideMenuItem
            icon={<MessagesSvg />}
            title={t("menu.messages", { ns: "common" })}
            url={messagesUrl}
            condensed={isCondensed}
            disabled={true}
          />
        </Tooltip>
        <Tooltip content={t("menu.coming_soon", { ns: "common" })}>
          <SideMenuItem
            icon={<TradeSvg />}
            title={t("menu.trade", { ns: "common" })}
            url={p2pTradeUrl}
            condensed={isCondensed}
            disabled={true}
          />
        </Tooltip>
        <SideMenuItem
          icon={<SettingsSvg />}
          title={t("menu.settings", { ns: "common" })}
          url={settingsUrl}
          condensed={isCondensed}
        />
        {/* <UpgradeButton condensed={isCondensed} /> */}
        <div className="flex-grow justify-self-end flex items-end">
          <Account condensed={isCondensed} />
        </div>
      </SideMenu>
    </div>
  );
};
